import { Transaction } from '@sargon/api-client';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

type Sort = 'asc' | 'desc';
export const useSortedTransactions = (
  transactions: Transaction[],
): [Transaction[], Sort, Dispatch<SetStateAction<Sort>>] => {
  const [sort, setSort] = useState<Sort>('asc');
  const sortedTransactions = useMemo(() => {
    return transactions.slice().sort((a, b) => {
      if (!a || !a.paidDate || !b || !b.paidDate) {
        return 0;
      }
      if (sort === 'asc') {
        return a.paidDate < b.paidDate ? 1 : -1;
      }
      return a.paidDate > b.paidDate ? 1 : -1;
    });
  }, [sort, transactions]);
  return [sortedTransactions, sort, setSort];
};
