import { css, Interpolation } from 'emotion';
import { track, TrackingEvent, TrackingProperties } from 'helpers/analytics';
import React, { ReactNode } from 'react';
import {
  BorderRadius,
  CommonColor,
  FontSize,
  hex2rgba,
  Margin,
  MEDIA_QUERY_MOBILE_MAX,
  Opacity,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

import { Spinner } from './spinner';

type ButtonType = 'button' | 'reset' | 'submit';
type ButtonVariant = 'primary' | 'secondary' | 'text';
type ButtonSize = 'small' | 'medium';

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  margin?: Margin;
  trackingProperties: TrackingProperties;
}

export const Button = ({
  children,
  onClick,
  disabled,
  loading,
  margin,
  trackingProperties,
  type = 'button',
  variant = 'primary',
  size = 'medium',
}: ButtonProps): JSX.Element => (
  <button
    className={css(baseStyle, sizeStyles[size], typeStyles[variant], {
      ...margin,
    })}
    type={type}
    disabled={loading || disabled}
    onClick={(): void => {
      track(TrackingEvent.CLICK, trackingProperties);
      onClick && onClick();
    }}
  >
    {loading ? <Spinner size={size} /> : children}
  </button>
);

const baseStyle: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  border: 0,
  cursor: 'pointer',

  ':disabled': {
    cursor: 'not-allowed',
  },
};

const sizeStyles: { [s in ButtonSize]: Interpolation } = {
  small: {
    height: 32,
    minWidth: 96,
    fontSize: FontSize.META,
    paddingLeft: Spacing.MEDIUM,
    paddingRight: Spacing.MEDIUM,
    borderRadius: BorderRadius.MEDIUM,
  },
  medium: {
    height: 56,
    minWidth: 220,
    fontSize: FontSize.BODY,
    paddingLeft: Spacing.LARGE,
    paddingRight: Spacing.LARGE,
    borderRadius: BorderRadius.LARGE,

    [MEDIA_QUERY_MOBILE_MAX]: {
      height: 50,
      minWidth: 150,
      paddingLeft: Spacing.MEDIUM,
      paddingRight: Spacing.MEDIUM,
    },
  },
};

const typeStyles: { [t in ButtonVariant]: Interpolation } = {
  primary: {
    color: CommonColor.WHITE,
    backgroundColor: PrimaryColor.PURPLE_1,

    ':hover': {
      backgroundColor: PrimaryColor.PURPLE_3,
    },
    ':disabled': {
      color: hex2rgba(CommonColor.WHITE, Opacity.MEDIUM),
      backgroundColor: hex2rgba(PrimaryColor.PURPLE_1, Opacity.MEDIUM),
    },
  },
  secondary: {
    color: PrimaryColor.PURPLE_1,
    backgroundColor: ShadeColor.SHADE_6,

    ':hover': {
      backgroundColor: ShadeColor.SHADE_3,
    },
    ':disabled': {
      color: hex2rgba(PrimaryColor.PURPLE_1, Opacity.MEDIUM),
      backgroundColor: hex2rgba(ShadeColor.SHADE_3, Opacity.MEDIUM),
    },
  },
  text: {
    background: 'none',
    ':hover': {
      color: CommonColor.WHITE,
    },
  },
};
