import { css, Interpolation } from 'emotion';
import React, { forwardRef, RefForwardingComponent, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { BorderRadius, Margin, ShadeColor, Spacing } from 'styles';

import { Input } from './input';

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string;
  errorMessage?: string;
  margin?: Margin;
}

const PasswordInput: RefForwardingComponent<HTMLInputElement, Props> = (
  props,
  ref,
): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const PasswordIcon = showPassword ? EyeOff : Eye;

  return (
    <div className={css(password)}>
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        ref={ref}
      />
      <PasswordIcon
        color={ShadeColor.SHADE_2}
        size={ICON_SIZE}
        onClick={(): void => setShowPassword(!showPassword)}
        className={css(decoration, icon)}
      />
    </div>
  );
};

const ICON_SIZE = Spacing.MEDIUM;
const HOVER_CIRCLE_SIZE = ICON_SIZE / 2;

const password: Interpolation = {
  position: 'relative',
};

const icon: Interpolation = {
  ':hover': {
    backgroundColor: ShadeColor.SHADE_4,
    padding: HOVER_CIRCLE_SIZE,
    marginRight: -HOVER_CIRCLE_SIZE,
    borderRadius: BorderRadius.CIRCLE,
  },
};

const decoration: Interpolation = {
  position: 'absolute',
  right: Spacing.SMALL,
  top: '50%',
  transform: 'translateY(-50%)',
};

const ForwardedPasswordInput = forwardRef<HTMLInputElement, Props>(
  PasswordInput,
);
export { ForwardedPasswordInput as PasswordInput };
