interface Config {
  segmentKey: string;
  datadogClientToken: string;
  intercomAppId: string;
  amplify: {
    userPoolId: string;
  };
  sargon: {
    basePath: string;
    clientId: string;
  };
  externalApiUrl: string;
  supermatchDisabled: boolean;
}

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

const stagingConfig: Config = {
  segmentKey: 'qloiIunmJEv1f4SqIcKXrHZOVsMkeYTM',
  datadogClientToken: 'pubd37c48f7fc1b2f449a9f01f938ccba4e',
  intercomAppId: 'rv619haf',
  amplify: {
    // userPoolId: 'ap-southeast-2_emF4yeZNo', // UAT
    userPoolId: 'ap-southeast-2_lswkNnhrj', // Production Sandbox
  },
  sargon: {
    // basePath: 'https://uat.api.sargon.com', // UAT
    // clientId: '3329amvh78spvnvdj6feffdqrk', // UAT
    basePath: 'https://api.sargon.com', // Production Sandbox
    clientId: 'khvaje4hkscqpmi98poemi2u1', // Production Sandbox
  },
  externalApiUrl: 'https://api.staging.spaceship.com.au/v0/external',
  supermatchDisabled: true,
};

const envToConfig: { [env: string]: Config } = {
  [Environment.PRODUCTION]: {
    segmentKey: 'DhAcZTTfBSTu1bHwDkpQojSLSHCPnsUY',
    datadogClientToken: 'pub8a1ecbd0125c42b0dc072c795a983fc7',
    intercomAppId: 'sxx9mgxp',
    amplify: {
      userPoolId: 'ap-southeast-2_XNV4yXp57',
    },
    sargon: {
      basePath: 'https://api.sargon.com',
      clientId: 'i5dlcalo8ddjpgptjsv3483cr',
    },
    externalApiUrl: 'https://api.spaceshipinvest.com.au/v0/external',
    supermatchDisabled: true,
  },
  [Environment.STAGING]: stagingConfig,
  [Environment.DEVELOPMENT]: stagingConfig,
};

export const config = ((): Config => {
  const env = process.env.ENV;

  if (!env) {
    throw Error('no environment provided');
  }

  if (!envToConfig[env]) {
    throw Error(`unsupported environment: ${env}`);
  }

  return envToConfig[env];
})();
