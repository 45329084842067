import { config } from 'helpers/config';

interface DatadogLogsClient {
  init: (options: {
    clientToken: string;
    forwardErrorsToLogs: boolean;
  }) => void;
  addLoggerGlobalContext: (key: string, value: string) => void;
}

declare global {
  interface Window {
    DD_LOGS: DatadogLogsClient;
  }
}

export const initMonitoring = (): void => {
  const client = window.DD_LOGS;
  try {
    client.init({
      clientToken: config.datadogClientToken,
      forwardErrorsToLogs: true,
    });

    client.addLoggerGlobalContext('service', 'web.super');
    if (process.env.ENV) {
      client.addLoggerGlobalContext('env', process.env.ENV);
    }
  } catch (e) {
    console.error(e);
  }
};
