import { css, Interpolation } from 'emotion';
import React, { ReactNode } from 'react';
import { columns, Gutter, MEDIA_QUERY_MOBILE_MAX } from 'styles';

interface Props {
  children: ReactNode | ReactNode[];
  size?: LayoutSize;
}

export const CenteredLayout = ({
  children,
  size = 'medium',
}: Props): JSX.Element => (
  <div className={css(container)}>
    <div className={css(content, layoutWidth[size])}>{children}</div>
  </div>
);

type LayoutSize = 'small' | 'medium' | 'large' | 'xlarge' | 'full';

const layoutWidth: { [s in LayoutSize]: Interpolation } = {
  small: {
    flexBasis: columns(3),
  },
  medium: {
    flexBasis: columns(4),
  },
  large: {
    flexBasis: columns(5),
  },
  xlarge: {
    flexBasis: columns(8),
  },
  full: {
    flexBasis: columns(12),
  },
};

const container: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  margin: `0 ${Gutter.DESKTOP}px`,

  [MEDIA_QUERY_MOBILE_MAX]: {
    margin: `0 ${Gutter.MOBILE}px`,
  },
};

const content: Interpolation = {
  display: 'flex',
  flexDirection: 'column',
};
