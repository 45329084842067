import { parsePhoneNumberFromString } from 'libphonenumber-js';

const DEFAULT_COUNTRY = 'AU';

// Taken from:
// https://en.wikipedia.org/wiki/Postcodes_in_Australia#Allocation
const STATE_POSTCODES: { [state: string]: Array<[number, number]> } = {
  NSW: [
    [1000, 1999],
    [2000, 2599],
    [2619, 2899],
    [2921, 2999],
  ],
  ACT: [
    [200, 299],
    [2600, 2618],
    [2900, 2920],
  ],
  VIC: [
    [3000, 3999],
    [8000, 8999],
  ],
  QLD: [
    [4000, 4999],
    [9000, 9999],
  ],
  SA: [[5000, 5999]],
  WA: [[6000, 6999]],
  TAS: [[7000, 7999]],
  NT: [
    [800, 899],
    [900, 999],
  ],
};

export const asciiStringValidate = (
  value: string,
  fieldName: string,
): boolean | string => {
  // eslint-disable-next-line no-control-regex
  if (/^[\x00-\x7F]*$/.test(value)) {
    return true;
  }
  return `${fieldName} contains invalid characters`;
};

export const commonValidationRules = {
  password: {
    required: 'Password is required',
    pattern: {
      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      message: 'Password must conform to the above password policy',
    },
  },
  date: {
    pattern: {
      value: /\d{2}-\d{2}-\d{4}/,
      message: 'Date must be in format DD-MM-YYYY',
    },
  },
  phoneNumber: {
    validate: (value: string): boolean | string => {
      if (!value.startsWith('+')) {
        return 'Phone number must begin with +';
      }

      const phoneNumber = parsePhoneNumberFromString(value, DEFAULT_COUNTRY);
      if (!(phoneNumber && phoneNumber.isValid())) {
        return 'Invalid phone number';
      }

      return true;
    },
  },
  postcode: {
    validate: (state: string, value: string): boolean | string => {
      const postcode = parseInt(value);

      if (STATE_POSTCODES[state]) {
        const postcodeRanges = STATE_POSTCODES[state];
        const valid = postcodeRanges.some(
          ([low, high]) => postcode >= low && postcode <= high,
        );

        return valid || 'Postcode not valid for state';
      }

      return 'Postcode not valid for state';
    },
  },
  taxFileNumber: {
    required: 'Tax file number is required',
    validate: (value: string): boolean | string => {
      const tfn = value.toString();
      const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];
      if (!tfn || tfn.length !== 9) {
        return 'Tax file number is invalid';
      }
      let total = 0;
      for (let i = 0; i < tfn.length; i += 1) {
        const digitAtPos = Number(tfn.charAt(i));
        total += weights[i] * digitAtPos;
      }
      if (total === 0 || total % 11 !== 0) {
        return 'Tax file number is invalid';
      }
      return true;
    },
  },
  smsOtp: {
    required: 'An SMS code is required to proceed',
    minLength: {
      value: 6,
      message: 'SMS code must be 6 characters',
    },
  },
};
