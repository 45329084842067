import spinnerSvg from 'assets/icons/spinner.svg';
import { css, Interpolation, keyframes } from 'emotion';
import React from 'react';

type SpinnerSize = 'small' | 'medium' | 'large';

interface Props {
  size?: SpinnerSize;
}

export const Spinner = ({ size = 'medium' }: Props): JSX.Element => (
  <img className={css(baseStyle, sizeStyles[size])} src={spinnerSvg} />
);

const spin = keyframes({ '100%': { transform: 'rotate(360deg)' } });

const baseStyle: Interpolation = {
  animation: `${spin} 0.6s linear infinite`,
};

const sizeStyles: { [s in SpinnerSize]: Interpolation } = {
  small: {
    height: 16,
    width: 16,
  },
  medium: {
    height: 32,
    width: 32,
  },
  large: {
    height: 64,
    width: 64,
  },
};
