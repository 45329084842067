import Cleave from 'cleave.js';
import { css, cx, Interpolation } from 'emotion';
import { track, TrackingEvent } from 'helpers/analytics';
import React, {
  forwardRef,
  RefForwardingComponent,
  useEffect,
  useRef,
} from 'react';
import { Lock } from 'react-feather';
import {
  BorderRadius,
  BorderWidth,
  CommonColor,
  FontSize,
  FontWeight,
  Margin,
  PrimaryColor,
  ShadeColor,
  Spacing,
  TRANSITION_DURATION,
} from 'styles';

interface FormatOptions {
  date: object;
  postcode: object;
  currency: object;
  otp: object;
}

// To add options: https://nosir.github.io/cleave.js/
const formatOptions: FormatOptions = {
  date: {
    date: true,
    delimiter: '-',
    datePattern: ['d', 'm', 'Y'],
  },
  postcode: {
    numericOnly: true,
    blocks: [4],
  },
  currency: {
    numericOnly: true,
    numberal: true,
    numeralThousandsGroupStyle: 'thousand',
  },
  otp: {
    numericOnly: true,
    blocks: [6],
  },
};

interface Props extends React.HTMLProps<HTMLInputElement> {
  type: 'text' | 'email' | 'password' | 'number' | 'tel';
  name: string;
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  format?: keyof FormatOptions;
  autofill?: 'off';
  margin?: Margin;
}

const Input: RefForwardingComponent<HTMLInputElement, Props> = (
  {
    margin,
    disabled,
    format,
    placeholder,
    name,
    onFocus,
    errorMessage,
    ...props
  },
  forwardedRef,
): JSX.Element => {
  const ref = useRef<null | HTMLInputElement>(null);
  const ua = window.navigator.userAgent;

  useEffect(() => {
    if (!(ref.current && format)) {
      return (): void => {};
    }
    const cleave = new Cleave(ref.current, formatOptions[format]);
    return (): void => {
      if (ref.current) {
        cleave.destroy();
      }
    };
  }, []);

  return (
    <div
      className={cx(css(input, { ...margin }), {
        error: !!errorMessage && !disabled,
        isIE:
          ua.indexOf('Edge') > 0 ||
          ua.indexOf('MSIE ') > 0 ||
          !!ua.match(/Trident.*rv:11\./),
      })}
    >
      <input
        {...{
          ...props,
          name,
          disabled,
          placeholder,
          onFocus: (e: React.FocusEvent<HTMLInputElement>): void => {
            track(TrackingEvent.FOCUS_INPUT, { name });
            onFocus && onFocus(e);
          },
          ref: (el): void => {
            ref.current = el;
            if (forwardedRef instanceof Function) {
              forwardedRef(el);
            }
          },
        }}
      />
      <label>{(!disabled && errorMessage) || placeholder}</label>
      {disabled && (
        <Lock
          color={ShadeColor.SHADE_2}
          size={Spacing.SMALL}
          className={css(decoration)}
        />
      )}
    </div>
  );
};

const borderOffset = BorderWidth.MEDIUM - BorderWidth.SMALL;
const unfocusedPadding = Spacing.SMALL;
const focusedPadding = unfocusedPadding - borderOffset;

const decoration: Interpolation = {
  position: 'absolute',
  right: Spacing.SMALL,
  top: '50%',
  transform: 'translateY(-50%)',
};

const labelUpPosition: Interpolation = {
  transform: 'translateY(0%)',
  fontSize: FontSize.META,
  top: Spacing.X_SMALL,
};

const input: Interpolation = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  fontWeight: FontWeight.SEMI_BOLD,

  label: {
    transitionDuration: TRANSITION_DURATION,
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: CommonColor.TEXT,
    paddingLeft: unfocusedPadding,
  },

  input: {
    outline: 0,
    fontFamily: 'inherit',
    fontWeight: FontWeight.SEMI_BOLD,
    boxSizing: 'border-box',
    fontSize: FontSize.BODY,
    height: Spacing.XX_LARGE,
    color: CommonColor.WHITE,
    background: 'transparent',
    borderRadius: BorderRadius.SMALL,
    borderWidth: BorderWidth.SMALL,
    borderStyle: 'solid',
    borderColor: ShadeColor.SHADE_2,
    paddingLeft: unfocusedPadding,
    paddingRight: unfocusedPadding,

    ':hover': {
      borderColor: PrimaryColor.GREEN,
      paddingLeft: focusedPadding,
      paddingRight: focusedPadding,
      borderWidth: BorderWidth.MEDIUM,
    },

    ':focus': {
      paddingLeft: focusedPadding,
      paddingRight: focusedPadding,
      borderColor: PrimaryColor.GREEN,
      borderWidth: BorderWidth.MEDIUM,
    },

    ':disabled': {
      borderColor: ShadeColor.SHADE_4,
    },
  },

  // IMPORTANT: Separate targeting definitions is required for Firefox
  'input:not(:placeholder-shown) + label': labelUpPosition,
  'input:-webkit-autofill + label': labelUpPosition,
  '&.isIE label': labelUpPosition,

  '&.error label': {
    ...labelUpPosition,
    color: CommonColor.RED,
  },

  '&.error input': {
    borderColor: CommonColor.RED,
    paddingLeft: focusedPadding,
    paddingRight: focusedPadding,
    borderWidth: BorderWidth.MEDIUM,
  },

  '&.error input, &.isIE input': {
    paddingTop: Spacing.MEDIUM,
  },

  'input:not(:placeholder-shown)': {
    paddingTop: Spacing.MEDIUM,
  },

  'input::placeholder': {
    opacity: 0,
  },
};

const ForwardedInput = forwardRef<HTMLInputElement, Props>(Input);
export { ForwardedInput as Input };
