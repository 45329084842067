import { css, Interpolation } from 'emotion';
import React from 'react';
import { Icon as IconComponent } from 'react-feather';
import {
  BorderRadius,
  FontSize,
  FontWeight,
  IconSize,
  ShadeColor,
  Spacing,
} from 'styles';

interface Props {
  type?: PillType;
  Icon?: IconComponent;
  text: string;
}

type PillType = 'success' | 'info' | 'warning' | 'error';

export const Pill = ({ type = 'info', Icon, text }: Props): JSX.Element => (
  <div className={css(pill, pillTypes[type])}>
    <span>{text}</span>
    {Icon && <Icon size={IconSize.SMALL} />}
  </div>
);

const PILL_HEIGHT = 24;

const pill: Interpolation = {
  height: PILL_HEIGHT,
  maxWidth: 'max-content',
  display: 'flex',
  padding: `0 ${Spacing.X_SMALL}px`,
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontSize: FontSize.META,
  fontWeight: FontWeight.SEMI_BOLD,
  borderRadius: BorderRadius.LARGE,

  '> span:not(:only-child)': {
    marginRight: Spacing.XXX_SMALL,
  },
};

// TODO: Figure where these live and what to call them
enum PillBackgroundColor {
  SUCCESS = '#C9FEEF',
  WARNING = '#FFF0B0',
  ERROR = '#FFD3D0',
}

// TODO: Figure where these live and what to call them
enum PillTextColor {
  SUCCESS = '#00AAA0',
  WARNING = '#F4A305',
  ERROR = '#D92812',
}

const pillTypes: { [t in PillType]: Interpolation } = {
  success: {
    color: PillTextColor.SUCCESS,
    backgroundColor: PillBackgroundColor.SUCCESS,
  },
  info: {
    color: ShadeColor.SHADE_2,
    backgroundColor: ShadeColor.SHADE_4,
  },
  warning: {
    color: PillTextColor.WARNING,
    backgroundColor: PillBackgroundColor.WARNING,
  },
  error: {
    color: PillTextColor.ERROR,
    backgroundColor: PillBackgroundColor.ERROR,
  },
};
