import { RouteComponentProps } from '@reach/router';
import { Transaction } from '@sargon/api-client';
import { CenteredLayout } from 'components/centered-layout';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { formatCurrency, getTransactionDescription } from 'helpers/format';
import { useSortedTransactions } from 'helpers/transactions';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useStores } from 'stores';
import {
  BorderWidth,
  CommonColor,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

export const Transactions: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const {
      sargonStore: { getTransactions, transactions },
    } = useStores();

    const { popToast } = useNotifications();

    const [sortedTransactions, sort, setSort] = useSortedTransactions(
      transactions,
    );

    useEffect(() => {
      (async (): Promise<void> => {
        try {
          await getTransactions();
        } catch {
          popToast({
            message: `There was an error fetching your transactions.`,
            level: 'error',
          });
        }
      })();
    }, []);

    const grouped = useMemo(() => {
      const group: { [k: string]: Transaction[] } = {};
      sortedTransactions.forEach((tx: Transaction) => {
        const month = moment(tx.paidDate).format('MMMM YYYY');
        group[month] = group[month] || [];
        group[month].push(tx);
      });
      return group;
    }, [sortedTransactions]);

    return (
      <CenteredLayout size="xlarge">
        <h1 className={css({ marginTop: Spacing.MEDIUM })}>
          Transaction history
        </h1>

        {!sortedTransactions.length ? (
          <p>Your account has no transactions yet.</p>
        ) : (
          <div>
            <div className={css(row, label)}>
              <div
                className="date"
                onClick={(): void => {
                  setSort(sort === 'asc' ? 'desc' : 'asc');
                }}
              >
                <span>Date</span>
                {sort === 'asc' ? (
                  <ChevronUp className="icon" />
                ) : (
                  <ChevronDown className="icon" />
                )}
              </div>
              <div className="type">Type</div>
              <div className="amount">Amount</div>
            </div>
            <p>
              Showing {sortedTransactions.length}{' '}
              {sortedTransactions.length > 1 ? 'transactions' : 'transaction'}
            </p>
          </div>
        )}

        {Object.entries(grouped).map(([month, txs]) => (
          <div key={month} className={css(group)}>
            <h3 className={css(monthHeader)}>{month}</h3>
            {txs.map(
              ({ id, paidDate, transactionType, amountIn, amountOut }) => {
                const amount =
                  (amountIn && amountIn.amount) ||
                  (amountOut && amountOut.amount) ||
                  0;
                return (
                  <div key={id} className={css(row)}>
                    <div className="date">
                      {moment(paidDate).format('DD.MM.YYYY')}
                    </div>
                    <div className="type">
                      {getTransactionDescription(transactionType)}
                    </div>
                    <div className="amount">
                      <span className={amount > 0 ? 'positive' : 'negative'}>
                        {formatCurrency(amount / 100)}
                      </span>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        ))}
      </CenteredLayout>
    );
  },
);

const row: Interpolation = {
  display: 'flex',
  justifyContent: 'row',
  paddingTop: Spacing.X_SMALL,

  '.date': {
    minWidth: 100,
    width: 100,
    alignItems: 'center',
  },
  '.type': {
    flexGrow: 1,
    color: CommonColor.WHITE,
    alignItems: 'center',
  },
  '.amount': {
    minWidth: 150,
    width: 150,
    textAlign: 'right',
    alignItems: 'center',
  },
  '.positive': {
    color: PrimaryColor.GREEN,
  },
};

const label: Interpolation = {
  color: CommonColor.WHITE,

  '.date': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.icon': { paddingRight: Spacing.X_SMALL },
};

const monthHeader: Interpolation = {
  borderColor: ShadeColor.SHADE_2,
  borderBottomStyle: 'solid',
  borderBottomWidth: BorderWidth.SMALL,
  paddingBottom: Spacing.XX_SMALL,
  marginBottom: Spacing.SMALL,
};

const group: Interpolation = {
  marginBottom: Spacing.MEDIUM,
};
