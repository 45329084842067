import { css, Interpolation } from 'emotion';
import React, { Fragment, ReactNode } from 'react';
import { X } from 'react-feather';
import {
  Breakpoint,
  CommonColor,
  IconSize,
  Opacity,
  ShadeColor,
  Spacing,
  ZIndex,
} from 'styles';

import { Card } from './card';

interface Props {
  children: ReactNode;
  showModal: boolean;
  closeHandler?: () => void;
}

export const Modal = ({
  children,
  showModal,
  closeHandler,
}: Props): JSX.Element | null => {
  if (!showModal) {
    return null;
  }

  return (
    <Fragment>
      <div
        className={css(overlay)}
        onClick={(): void => closeHandler && closeHandler()}
      />
      <div className={css(modal)}>
        <Card
          type="tertiary"
          margin={{ marginLeft: Spacing.SMALL, marginRight: Spacing.SMALL }}
        >
          {closeHandler && (
            <button
              className={css(close)}
              onClick={(): void => {
                closeHandler;
              }}
            >
              <X size={IconSize.MEDIUM} color={ShadeColor.SHADE_2} />
            </button>
          )}
          <div className={css(content)}>{children}</div>
        </Card>
      </div>
    </Fragment>
  );
};

const MODAL_MAX_WIDTH = 400;
const MODAL_DESKTOP_TOP_MARGIN = 96;
const MODAL_MOBILE_TOP_MARGIN = 48;

const CLOSE_BUTTON_MARGIN = 16;

const overlay: Interpolation = {
  zIndex: ZIndex.OVERLAY,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: CommonColor.BLACK,
  opacity: Opacity.DARK,
};

const modal: Interpolation = {
  zIndex: ZIndex.MODAL,
  position: 'fixed',
  top: MODAL_DESKTOP_TOP_MARGIN,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: MODAL_MAX_WIDTH,
  maxHeight: '100%',

  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    top: MODAL_MOBILE_TOP_MARGIN,
    width: '100%',
  },
};

const content: Interpolation = {
  width: '100%',
};

const close: Interpolation = {
  position: 'absolute',
  right: CLOSE_BUTTON_MARGIN + Spacing.SMALL,
  top: CLOSE_BUTTON_MARGIN,
  background: 'none',
  border: 'none',
  padding: 0,
  lineHeight: 0,
  cursor: 'pointer',
};
