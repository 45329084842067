import { RouteComponentProps } from '@reach/router';
import { BeneficiaryBase, BeneficiaryIntent } from '@sargon/api-client';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { CenteredLayout } from 'components/centered-layout';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { STATES_DROPDOWN_OPTONS } from 'helpers/constants';
import { useIntercom } from 'helpers/contexts/intercom';
import { useNotifications } from 'helpers/contexts/notifications';
import {
  camelPad,
  dateFromInput,
  formatDateToInput,
  formatNumberWithOrdinal,
} from 'helpers/format';
import { commonValidationRules } from 'helpers/validation';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BorderRadius,
  HEADING_2,
  hex2rgba,
  IconSize,
  INPUT_MARGIN,
  LineHeight,
  MEDIA_QUERY_MOBILE_MAX,
  PrimaryColor,
  Spacing,
} from 'styles';
import uuid from 'uuid';

interface BeneficiaryForm {
  beneficiaries: { [id: string]: InputBeneficiary };
}

interface InputBeneficiary extends Omit<BeneficiaryBase, 'birthDate'> {
  birthDate?: string | Date;
}

const keyForId = (key: string, id: string): string =>
  `beneficiaries[${id}].${key}`;

export const Beneficiaries: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const {
      sargonStore: {
        getBeneficiaries,
        beneficiaries,
        createBeneficiaries,
        member: { memberStatus },
      },
    } = useStores();

    const { popToast } = useNotifications();

    useEffect(() => {
      (async (): Promise<void> => {
        try {
          await getBeneficiaries();
        } catch (e) {
          popToast({
            message: `There was an error fetching your beneficiaries.`,
            level: 'error',
          });
        }
      })();
    }, []);

    return (
      <CenteredLayout size="xlarge">
        <h1 className={css(heading)}>Non-binding beneficiaries</h1>
        <p>
          A beneficiary is the person you have nominated to receive your super
          in the event of your death. You can nominate multiple beneficiaries.
          To ensure your benefits go to the right person(s), make sure you keep
          this information up to date.
        </p>
        {beneficiaries !== undefined && (
          <BeneficiaryForm
            currentBeneficiaries={beneficiaries}
            saveBeneficiaries={createBeneficiaries}
            canEdit={memberStatus == 'Active' || memberStatus == 'PeerReview'}
          />
        )}
      </CenteredLayout>
    );
  },
);

interface BeneficiaryProps {
  canEdit: boolean;
  currentBeneficiaries: BeneficiaryBase[];
  saveBeneficiaries: (
    beneficiaries: BeneficiaryBase[],
  ) => Promise<BeneficiaryIntent | undefined>;
}

const BeneficiaryForm: FC<BeneficiaryProps> = ({
  canEdit,
  currentBeneficiaries,
  saveBeneficiaries,
}) => {
  // Default to view mode if customer already has beneficiaries
  const [editMode, setEditMode] = useState(
    !currentBeneficiaries.length && canEdit,
  );
  const [loading, setLoading] = useState(false);
  const intercom = useIntercom();

  const initialBeneficiaries = currentBeneficiaries.reduce(
    (acc: { [id: string]: InputBeneficiary }, b) => ({
      ...acc,
      [uuid.v4()]: {
        ...b,
        birthDate: b.birthDate && formatDateToInput(b.birthDate),
      },
    }),
    {},
  );

  // If we start off in edit mode, that means that the customer has no
  // existing beneficiaries, so we should start with an empty beneficiary.
  // Otherwise, render their existing beneficiaries
  const [beneficiaries, setBeneficiaries] = useState<{
    [id: string]: InputBeneficiary;
  }>(editMode ? { [uuid.v4()]: {} } : initialBeneficiaries);

  const { watch, errors, register, handleSubmit } = useForm<BeneficiaryForm>({
    submitFocusError: true,
    mode: 'onBlur',
    defaultValues: {
      beneficiaries: initialBeneficiaries,
    },
  });

  const addBeneficiary = (): void => {
    setBeneficiaries({ ...beneficiaries, [uuid.v4()]: {} });
  };

  const removeBeneficiary = (id: string): void => {
    const { [id]: deleted, ...rest } = beneficiaries;
    setBeneficiaries(rest);
  };

  const allocation = Object.values(
    watch(
      Object.keys(beneficiaries).map(id => keyForId('benefitProportion', id)),
    ),
  ).reduce((total, allocation) => (total += Number(allocation || 0)), 0);

  const { popToast, popBanner } = useNotifications();

  return (
    <form
      className={css(container)}
      onSubmit={handleSubmit(async ({ beneficiaries }) => {
        setLoading(true);
        if (allocation !== 100) {
          popToast({
            message: 'Benefit proportions must add up to 100%.',
            level: 'warning',
          });
          setLoading(false);
          return;
        }
        try {
          const beneficiariesToSubmit = Object.values(beneficiaries).map(b => ({
            ...b,
            birthDate: b.birthDate ? dateFromInput(b.birthDate) : undefined,
            beneficiaryType: BeneficiaryBase.BeneficiaryTypeEnum.NonBinding,
            benefitProportion: Number(b.benefitProportion),
            address: b.address
              ? {
                  ...b.address,
                  countryCode: 'AU',
                }
              : undefined,
          }));
          await saveBeneficiaries(beneficiariesToSubmit);
          setEditMode(false);
          popToast({
            level: 'success',
            message:
              'Thanks, we’ve updated your beneficiary nomination(s). Please allow up to five business days for this change to be reflected in your account.',
          });
        } catch (error) {
          popBanner({
            message:
              error.message ||
              'Something went wrong submitting your beneficiaries.',
          });
        }
        setLoading(false);
      })}
    >
      <div className={css(beneficiariesContainer)}>
        {Object.keys(beneficiaries).map((id, ind, list) => {
          const givenNameError = errors[keyForId('givenName', id)];
          const familyNameError = errors[keyForId('familyName', id)];
          const relationshipError = errors[keyForId('relationship', id)];
          const birthDateError = errors[keyForId('birthDate', id)];
          const benefitProportionError =
            errors[keyForId('benefitProportion', id)];
          const line1Error = errors[keyForId('address.line1', id)];
          const suburbError = errors[keyForId('address.suburb', id)];
          const stateError = errors[keyForId('address.state', id)];
          const postCodeError = errors[keyForId('address.postcode', id)];

          return (
            <Card margin={{ marginBottom: Spacing.LARGE }} key={id} type="none">
              <div className={css(beneficiaryContent)}>
                <div className={css(beneficiaryHeader)}>
                  <h2>{formatNumberWithOrdinal(ind + 1)} Beneficiary</h2>
                  {!!ind && editMode && (
                    <Button
                      onClick={(): void => removeBeneficiary(id)}
                      size="small"
                      variant="text"
                      trackingProperties={{
                        name: 'beneficiaries_remove_one',
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
                <div className={css(row)}>
                  <Input
                    margin={INPUT_MARGIN}
                    type="text"
                    name={keyForId('givenName', id)}
                    placeholder="Given name"
                    errorMessage={givenNameError && givenNameError.message}
                    disabled={!editMode}
                    ref={register({ required: 'Given name is required' })}
                  />
                  <Input
                    disabled={!editMode}
                    margin={INPUT_MARGIN}
                    type="text"
                    name={keyForId('familyName', id)}
                    placeholder="Family name"
                    errorMessage={familyNameError && familyNameError.message}
                    ref={register({
                      required: 'Family name is required',
                    })}
                  />
                </div>
                <Input
                  margin={INPUT_MARGIN}
                  type="text"
                  name={keyForId('birthDate', id)}
                  format="date"
                  placeholder="Date of birth"
                  disabled={!editMode}
                  errorMessage={birthDateError && birthDateError.message}
                  ref={register({
                    ...commonValidationRules.date,
                    required: 'Date of birth is required',
                  })}
                />
                <Dropdown
                  options={[
                    { value: '', label: 'Select a relationship' },
                    ...Object.entries(BeneficiaryBase.RelationshipEnum).map(
                      ([label, value]) => ({
                        label: camelPad(label),
                        value,
                      }),
                    ),
                  ]}
                  margin={INPUT_MARGIN}
                  name={keyForId('relationship', id)}
                  disabled={!editMode}
                  placeholder="Relationship"
                  errorMessage={relationshipError && relationshipError.message}
                  ref={register({ required: 'Relationship is required' })}
                />
                {editMode && (
                  <div>
                    <Input
                      margin={INPUT_MARGIN}
                      type="text"
                      disabled={!editMode}
                      name={keyForId('address.line1', id)}
                      placeholder="Address line 1"
                      errorMessage={line1Error && line1Error.message}
                      ref={register({
                        required: 'Street address is required',
                      })}
                    />
                    <Input
                      margin={INPUT_MARGIN}
                      type="text"
                      disabled={!editMode}
                      name={keyForId('address.line2', id)}
                      placeholder="Address line 2"
                      ref={register}
                    />
                    <Input
                      margin={INPUT_MARGIN}
                      type="text"
                      disabled={!editMode}
                      name={keyForId('address.suburb', id)}
                      placeholder="Suburb"
                      errorMessage={suburbError && suburbError.message}
                      ref={register({ required: 'Suburb is required' })}
                    />
                    <div className={css(row)}>
                      <Dropdown
                        options={STATES_DROPDOWN_OPTONS}
                        margin={INPUT_MARGIN}
                        disabled={!editMode}
                        name={keyForId('address.state', id)}
                        placeholder="State"
                        errorMessage={stateError && stateError.message}
                        ref={register({ required: 'State is required' })}
                      />
                      <Input
                        margin={INPUT_MARGIN}
                        disabled={!editMode}
                        type="number"
                        format="postcode"
                        name={keyForId('address.postcode', id)}
                        placeholder="Postcode"
                        errorMessage={postCodeError && postCodeError.message}
                        ref={register({ required: 'Postcode is required' })}
                      />
                    </div>
                  </div>
                )}
                <Input
                  type="number"
                  disabled={!editMode}
                  name={keyForId('benefitProportion', id)}
                  placeholder="Proportion"
                  errorMessage={
                    benefitProportionError && benefitProportionError.message
                  }
                  ref={register({
                    required: 'Benefit proportion is required',
                    max: {
                      value: 100,
                      message: 'Benefit proportion must be no more than 100%',
                    },
                    min: {
                      value: 0,
                      message: 'Benefit proportion cannot be negative',
                    },
                  })}
                />
                {editMode && ind === list.length - 1 && (
                  <div className={css(selfAlignedButton)}>
                    <Button
                      margin={{ marginTop: Spacing.LARGE }}
                      onClick={addBeneficiary}
                      variant="text"
                      trackingProperties={{
                        name: 'beneficiaries_add_another',
                      }}
                    >
                      Add another
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          );
        })}
        {!editMode && Object.keys(beneficiaries).length < 1 && (
          <p>You have not nominated any beneficiaries.</p>
        )}
        {!canEdit && (
          <div className={css(warningText)}>
            <AlertTriangle size={IconSize.MEDIUM} />
            <p>
              You won’t be able to nominate beneficiaries until your account is
              active.
            </p>
          </div>
        )}
      </div>
      {editMode && (
        <div className={css(submitArea)}>
          <h3>
            <strong>{allocation}/100%</strong> allocated
          </h3>
          <div className="buttons">
            <Button
              variant="secondary"
              margin={{ marginRight: Spacing.MEDIUM }}
              onClick={(): void => location.reload()}
              trackingProperties={{
                name: 'beneficiaries_cancel',
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={loading}
              disabled={!canEdit}
              trackingProperties={{
                name: 'beneficiaries_submit',
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
      {!editMode && canEdit && (
        <div className={css(row)}>
          <Button
            variant="secondary"
            margin={{ marginRight: Spacing.MEDIUM }}
            onClick={async (): Promise<void> => {
              setLoading(true);

              try {
                await saveBeneficiaries([]);
                popToast({
                  level: 'success',
                  message:
                    'Thanks, we’ve removed your beneficiary nomination(s). Please allow up to five business days for this change to be reflected in your account.',
                });
              } catch (error) {
                popBanner({
                  message:
                    'Please contact support if you wish to remove your beneficiary nomination(s).',
                  cta: {
                    message: 'Contact support',
                    action: intercom.pop,
                  },
                });
              }

              setLoading(false);
            }}
            loading={loading}
            trackingProperties={{
              name: 'beneficiaries_remove_all',
            }}
          >
            Remove
          </Button>
          <Button
            onClick={(): void => setEditMode(true)}
            trackingProperties={{
              name: 'beneficiaries_edit',
            }}
          >
            Edit
          </Button>
        </div>
      )}
    </form>
  );
};

const container: Interpolation = {
  paddingBottom: Spacing.LARGE,
};

const heading: Interpolation = {
  marginTop: Spacing.MEDIUM,

  [MEDIA_QUERY_MOBILE_MAX]: {
    ...HEADING_2,
    marginTop: Spacing.MEDIUM,
  },
};

const submitArea: Interpolation = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  '.buttons': {
    display: 'flex',

    'div:first-child': {
      marginRight: Spacing.MEDIUM,
    },
  },

  [MEDIA_QUERY_MOBILE_MAX]: {
    h3: {
      marginBottom: Spacing.SMALL,
    },
    flexDirection: 'column',
  },
};

const beneficiaryHeader: Interpolation = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: Spacing.MEDIUM,
};

const row: Interpolation = {
  display: 'flex',

  'div:first-child': {
    marginRight: Spacing.MEDIUM,
  },
  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'block',

    'div:first-child': {
      marginRight: 0,
    },
  },
};

const beneficiariesContainer: Interpolation = {
  marginBottom: Spacing.MEDIUM,
  ':last-child': {
    marginBottom: 0,
  },
};

const beneficiaryContent: Interpolation = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: Spacing.LARGE,
  paddingRight: Spacing.LARGE,
  paddingTop: Spacing.MEDIUM,
  paddingBottom: Spacing.MEDIUM,

  [MEDIA_QUERY_MOBILE_MAX]: {
    paddingLeft: Spacing.MEDIUM,
    paddingRight: Spacing.MEDIUM,
    paddingTop: Spacing.SMALL,
    paddingBottom: Spacing.SMALL,
  },
};

const selfAlignedButton: Interpolation = {
  alignSelf: 'center',
};

const warningText: Interpolation = {
  display: 'flex',
  flexDirection: 'row',
  color: PrimaryColor.GOLD_100,
  backgroundColor: hex2rgba(PrimaryColor.GOLD_100, 0.1),
  padding: Spacing.SMALL,
  paddingRight: Spacing.MEDIUM,
  borderRadius: BorderRadius.SMALL,
  marginTop: Spacing.LARGE,

  p: {
    margin: 0,
    marginLeft: Spacing.MEDIUM,
    lineHeight: LineHeight.BODY,
  },
};
