export const STATES_DROPDOWN_OPTONS = [
  { value: '', label: 'Select a state' },
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'ACT', label: 'ACT' },
  { value: 'TAS', label: 'TAS' },
  { value: 'SA', label: 'SA' },
  { value: 'WA', label: 'WA' },
  { value: 'NT', label: 'NT' },
];

export const links = {
  appStore: 'https://spaceship.app.link/azRjbH71z9',
  googlePlay: 'https://spaceship.app.link/azRjbH71z9',
};
