import { Transaction } from '@sargon/api-client';
import moment from 'moment';
type Locale = 'en-AU';
type Currency = 'AUD';

const formatter = (locale: Locale, currency: Currency): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, { style: 'currency', currency });

const audFormatter = formatter('en-AU', 'AUD');

export const formatCurrency = (
  value: string | number,
  formatter: Intl.NumberFormat = audFormatter,
): string => {
  if (isNaN(Number(value)) || value === '' || value === null) {
    return '—';
  }
  return formatter.format(Number(value));
};

// Also supports Date input because Sargon API. Don't port.
export const dateFromInput = (input: string | Date): Date =>
  moment(input, INPUT_DATE).toDate();

export const formatDateToInput = (input?: Date): string =>
  moment(input).format(INPUT_DATE);

export const LONG_DATE_FORMAT = 'D MMMM YYYY';
export const INPUT_DATE = 'DD-MM-YYYY';
export const SHORT_DATE_FORMAT = 'D/M/YY';

export const formatNumberWithOrdinal = (i: number): string => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const camelPad = (str: string): string =>
  str
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, function(str) {
      return str.toUpperCase();
    })
    .trim();

const TRANSACTION_DESCRIPTION_MAP = {
  [Transaction.TransactionTypeEnum.AccountBalance]: 'Account Balance',
  [Transaction.TransactionTypeEnum.BenefitPayment]: 'Benefit Payment',
  [Transaction.TransactionTypeEnum.Commission]: 'Commission',
  [Transaction.TransactionTypeEnum.Contribution]: 'Contribution',
  [Transaction.TransactionTypeEnum.ContributionsTax]: 'Contributions Tax',
  [Transaction.TransactionTypeEnum.Fees]: 'Fees',
  [Transaction.TransactionTypeEnum.GST]: 'GST',
  [Transaction.TransactionTypeEnum.InsurancePremium]: 'Insurance Premium',
  [Transaction.TransactionTypeEnum.InsurerPayment]: 'Insurer Payment',
  [Transaction.TransactionTypeEnum.MemberProtection]: 'Member Protection',
  [Transaction.TransactionTypeEnum.MiscExpense]: 'Misc Expense',
  [Transaction.TransactionTypeEnum.MiscIncome]: 'Misc Income',
  [Transaction.TransactionTypeEnum.OtherTaxes]: 'Other Taxes',
  [Transaction.TransactionTypeEnum.PAYGTaxes]: 'PAYG Taxes',
  [Transaction.TransactionTypeEnum.PaymentAmount]: 'Payment Amount',
  [Transaction.TransactionTypeEnum.PensionPayment]: 'Pension Payment',
  [Transaction.TransactionTypeEnum.RITC]: 'RITC',
  [Transaction.TransactionTypeEnum.Surcharge]: 'Surcharge',
  [Transaction.TransactionTypeEnum.TaxRebate]: 'TaxRebate',
  [Transaction.TransactionTypeEnum.TaxRemittance]: 'Tax Remittance',
  [Transaction.TransactionTypeEnum.TransferToReserve]: 'Transfer To Reserve',
};

export const getTransactionDescription = (
  type?: Transaction.TransactionTypeEnum,
): string => {
  return type ? TRANSACTION_DESCRIPTION_MAP[type] || camelPad(type) : '-';
};
