import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { CenteredLayout } from 'components/centered-layout';
import { css, cx, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { DEFAULT_PORTFOLIO, PORTFOLIOS } from 'helpers/portfolio';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores';
import {
  BODY,
  BODY_SMALL,
  BorderRadius,
  BorderWidth,
  Breakpoint,
  columns,
  CommonColor,
  CONSENT_BOX,
  hex2rgba,
  ShadeColor,
  Spacing,
} from 'styles';

import { ExternalRoutes, Routes } from './routes';

export const OnboardingInvestmentOption: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const notifications = useNotifications();
    const [loading, setLoading] = useState(false);
    const [portfolio, setPortfolio] = useState<string>(DEFAULT_PORTFOLIO);
    const {
      sargonStore: {
        listInvestmentOptions,
        investmentOptions,
        updateFutureCashAllocations,
      },
    } = useStores();

    const allowedOptions = useMemo(
      () =>
        investmentOptions.filter(
          ({ legacy, frozen, key }) =>
            !legacy && !frozen && !!key && key in PORTFOLIOS,
        ),
      [investmentOptions],
    );

    useEffect(() => {
      (async (): Promise<void> => {
        try {
          await listInvestmentOptions();
        } catch (e) {
          notifications.popToast({
            message: `There was an error fetching investment options.`,
            level: 'error',
          });
        }
      })();
    }, []);

    return (
      <CenteredLayout size="xlarge">
        <div className={css(container)}>
          <div className={css(content)}>
            <h1>Choose your portfolio</h1>

            <p className={css(BODY)}>
              Invest your super where the world is going. Find out more about
              these portfolios by reading the{' '}
              <a
                href={ExternalRoutes.PRODUCT_DISCLOSURE_STATEMENT}
                rel="noopener noreferrer"
                target="_blank"
              >
                PDS
              </a>{' '}
              and{' '}
              <a
                href={ExternalRoutes.REFERENCE_GUIDE}
                rel="noopener noreferrer"
                target="_blank"
              >
                Reference Guide
              </a>
              .
            </p>
          </div>
          <form
            onSubmit={async (
              e: React.FormEvent<HTMLFormElement>,
            ): Promise<void> => {
              e.preventDefault();
              setLoading(true);
              try {
                const { id, key } = allowedOptions.filter(
                  ({ key }) => key === portfolio,
                )[0];
                await updateFutureCashAllocations({
                  id: id,
                  key: key || '',
                  percent: 100,
                });

                await navigate(Routes.DOWNLOAD_APP);
              } catch (error) {
                notifications.popToast({
                  level: 'error',
                  message: error.message,
                });
              }
              setLoading(false);
            }}
          >
            <div className={css(portfolios)}>
              {allowedOptions.map(({ id, key, displayName }) => {
                if (!key || !(key in PORTFOLIOS)) {
                  return null;
                }
                const { adminFee, annualFree, description, logo } = PORTFOLIOS[
                  key
                ];
                return (
                  <div
                    key={id}
                    className={cx(css(option), {
                      selected: portfolio === key,
                    })}
                    onClick={(): void => {
                      setPortfolio(key);
                    }}
                  >
                    <img src={logo} alt={displayName} />
                    <h2>{displayName}</h2>
                    <p className="tagline">
                      {adminFee} of your balance* + {annualFree} p.a.
                    </p>
                    <p>{description}</p>
                  </div>
                );
              })}
            </div>

            <div className={css(center)}>
              <div className={css(content)}>
                <p className={css(disclaimer)}>
                  You&apos;ll need to consider which investment option is right
                  for you. Find out more about these investment options in
                  section 5 of the{' '}
                  <a
                    href={ExternalRoutes.REFERENCE_GUIDE}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Reference Guide
                  </a>{' '}
                  and read section 7 of that guide to understand the fees and
                  costs you may be charged.
                </p>
                <p className={css(disclaimer)}>
                  * The fees above represent the combined Administration and
                  Investment fee for each portfolio.
                </p>

                <div className={css(CONSENT_BOX)}>
                  <p>
                    By clicking &apos;Continue&apos;, I direct the Trustee to
                    invest my contributions into my selected investment option.
                  </p>
                </div>
                <div className={css(center, { marginBottom: Spacing.LARGE })}>
                  <Button
                    type="submit"
                    loading={loading}
                    trackingProperties={{
                      name: 'onboarding_investment_option_submit',
                      portfolio,
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </CenteredLayout>
    );
  },
);

const content: Interpolation = {
  maxWidth: columns(6),
};

const center: Interpolation = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const container: Interpolation = {
  ...center,
  marginTop: Spacing.LARGE,
};

const disclaimer: Interpolation = {
  ...BODY_SMALL,
  textAlign: 'left',
  padding: Spacing.SMALL,
};

const portfolios: Interpolation = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: Spacing.MEDIUM,

  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    flexDirection: 'column',
  },
};

const option: Interpolation = {
  flex: 0.49,
  marginTop: Spacing.MEDIUM,
  paddingTop: Spacing.LARGE,
  paddingBottom: Spacing.LARGE,
  paddingLeft: Spacing.MEDIUM,
  paddingRight: Spacing.MEDIUM,
  backgroundColor: hex2rgba(CommonColor.WHITE, 0.01),
  borderStyle: 'solid',
  borderRadius: BorderRadius.X_SMALL,
  borderWidth: BorderWidth.MEDIUM,
  borderColor: hex2rgba(CommonColor.WHITE, 0.02),
  cursor: 'pointer',

  p: {
    ...BODY,
  },
  '.tagline': {
    ...BODY_SMALL,
    color: ShadeColor.SHADE_2,
    marginBottom: Spacing.MEDIUM,
  },
  '&.selected': {
    borderColor: CommonColor.GREEN,
  },
};
