import { RouteComponentProps } from '@reach/router';
import { Address, Member, MemberUpdate } from '@sargon/api-client';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Column, ColumnLayout } from 'components/column-layout';
import { Dropdown } from 'components/dropdown';
import { Illustration } from 'components/illustration';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { STATES_DROPDOWN_OPTONS } from 'helpers/constants';
import { useNotifications } from 'helpers/contexts/notifications';
import { asciiStringValidate, commonValidationRules } from 'helpers/validation';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Home, Mail } from 'react-feather';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import { IconSize, INPUT_MARGIN, Spacing } from 'styles';

export const AccountAddress: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const {
      sargonStore: {
        member: { addressResidential, addressPostal },
        updateMember,
      },
    } = useStores();

    return (
      <ColumnLayout>
        <Column verticalCenter={true}>
          <h1>Address details</h1>

          {(!!addressResidential || !!addressPostal) && (
            <AddressForm
              residential={addressResidential}
              postal={addressPostal}
              onUpdate={updateMember}
            />
          )}
        </Column>
        <Column verticalCenter={true} display="desktop-only">
          <Illustration type="identity" />
        </Column>
      </ColumnLayout>
    );
  },
);

interface FormData {
  sameAddress: boolean;
  addressResidential: Address;
  addressPostal: Address;
}

interface AddressProps {
  residential?: Address;
  postal?: Address;
  onUpdate: (memberUpdate: MemberUpdate) => Promise<Member | undefined>;
}

const AddressForm: FC<AddressProps> = ({ residential, postal, onUpdate }) => {
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);
  const [showPostal, setShowPostal] = useState(false);
  const sameAddress = JSON.stringify(residential) === JSON.stringify(postal);

  useEffect(() => {
    setShowPostal(!sameAddress);
  }, [sameAddress]);

  const { register, watch, errors, handleSubmit } = useForm<FormData>({
    submitFocusError: true,
    defaultValues: {
      sameAddress,
      addressResidential: residential,
      addressPostal: postal,
    },
  });

  const residentialLine1Error = errors['addressResidential.line1'];
  const residentialLin2Error = errors['addressResidential.line2'];
  const residentialSuburbError = errors['addressResidential.suburb'];
  const residentialStateError = errors['addressResidential.state'];
  const residentialPostcodeError = errors['addressResidential.postcode'];
  const residentialState = watch('addressResidential.state', '') as string;

  const postalLine1Error = errors['addressPostal.line1'];
  const postalLin2Error = errors['addressPostal.line2'];
  const postalSuburbError = errors['addressPostal.suburb'];
  const postalStateError = errors['addressPostal.state'];
  const postalPostcodeError = errors['addressPostal.postcode'];
  const postalState = watch('addressPostal.state', '') as string;

  return (
    <form
      onSubmit={handleSubmit(
        async ({ sameAddress, addressResidential, addressPostal }) => {
          setLoading(true);
          // Hard code this as we only support AU
          addressResidential.countryCode = 'AU';
          if (addressPostal) {
            addressPostal.countryCode = 'AU';
          }

          try {
            await onUpdate({
              addressResidential,
              addressPostal: sameAddress ? addressResidential : addressPostal,
            });
            notifications.popToast({
              level: 'success',
              message: 'Address details updated',
            });
          } catch (error) {
            notifications.popToast({
              level: 'error',
              message: error.message,
            });
          }
          setLoading(false);
        },
      )}
    >
      <h3 className={css(header)}>
        <Home size={IconSize.MEDIUM} /> Residential Address
      </h3>
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.line1"
        placeholder="Address line 1"
        ref={register({
          required: 'Address line 1 is required',
          validate: (value: string) =>
            asciiStringValidate(value, 'Address line 1'),
        })}
        errorMessage={residentialLine1Error && residentialLine1Error.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.line2"
        placeholder="Address line 2"
        ref={register({
          validate: (value: string) =>
            asciiStringValidate(value, 'Address line 2'),
        })}
        errorMessage={residentialLin2Error && residentialLin2Error.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.suburb"
        placeholder="Suburb"
        ref={register({
          required: 'Suburb is required',
          validate: (value: string) => asciiStringValidate(value, 'Suburb'),
        })}
        errorMessage={residentialSuburbError && residentialSuburbError.message}
      />
      <div className={css(row)}>
        <Dropdown
          options={STATES_DROPDOWN_OPTONS}
          margin={{ ...INPUT_MARGIN, marginRight: Spacing.MEDIUM }}
          name="addressResidential.state"
          placeholder="State"
          ref={register({
            required: 'State is required',
          })}
          errorMessage={residentialStateError && residentialStateError.message}
        />

        <Input
          type="number"
          format="postcode"
          name="addressResidential.postcode"
          placeholder="Postcode"
          ref={register({
            required: 'Postcode is required',
            validate: postcode =>
              commonValidationRules.postcode.validate(
                residentialState,
                postcode,
              ),
          })}
          errorMessage={
            residentialPostcodeError && residentialPostcodeError.message
          }
        />
      </div>

      <Checkbox
        margin={INPUT_MARGIN}
        name="sameAddress"
        ref={register}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          setShowPostal(!event.target.checked);
        }}
      >
        Use residential address for my postal address
      </Checkbox>

      {showPostal && (
        <div>
          <h3 className={css(header)}>
            <Mail size={IconSize.MEDIUM} /> Postal Address
          </h3>
          <Input
            margin={INPUT_MARGIN}
            type="text"
            name="addressPostal.line1"
            placeholder="Address line 1"
            ref={register({
              required: 'Address line 1 is required',
              validate: (value: string) =>
                asciiStringValidate(value, 'Address line 1'),
            })}
            errorMessage={postalLine1Error && postalLine1Error.message}
          />
          <Input
            margin={INPUT_MARGIN}
            type="text"
            name="addressPostal.line2"
            placeholder="Address line 2"
            ref={register({
              validate: (value: string) =>
                asciiStringValidate(value, 'Address line 2'),
            })}
            errorMessage={postalLin2Error && postalLin2Error.message}
          />
          <Input
            margin={INPUT_MARGIN}
            type="text"
            name="addressPostal.suburb"
            placeholder="Suburb"
            ref={register({
              required: 'Suburb is required',
              validate: (value: string) => asciiStringValidate(value, 'Suburb'),
            })}
            errorMessage={postalSuburbError && postalSuburbError.message}
          />
          <div className={css(row)}>
            <Dropdown
              options={STATES_DROPDOWN_OPTONS}
              margin={{ ...INPUT_MARGIN, marginRight: Spacing.MEDIUM }}
              name="addressPostal.state"
              placeholder="State"
              ref={register({
                required: 'State is required',
              })}
              errorMessage={postalStateError && postalStateError.message}
            />
            <Input
              type="number"
              format="postcode"
              name="addressPostal.postcode"
              placeholder="Postcode"
              ref={register({
                required: 'Postcode is required',
                validate: postcode =>
                  commonValidationRules.postcode.validate(
                    postalState,
                    postcode,
                  ),
              })}
              errorMessage={postalPostcodeError && postalPostcodeError.message}
            />
          </div>
        </div>
      )}
      <div className={css(center)}>
        <Button
          type="submit"
          loading={loading}
          trackingProperties={{
            name: 'account_address_submit',
          }}
        >
          Submit changes
        </Button>
      </div>
    </form>
  );
};

const row: Interpolation = {
  display: 'flex',
};

const header: Interpolation = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: Spacing.SMALL,

  svg: {
    marginRight: Spacing.X_SMALL,
  },
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
