import { RouteComponentProps } from '@reach/router';
import ufo from 'assets/images/ufo.png';
import { CenteredLayout } from 'components/centered-layout';
import { css, Interpolation } from 'emotion';
import React, { FC } from 'react';
import { BODY, HEADER_HEIGHT, MEDIA_QUERY_MOBILE_MAX, Spacing } from 'styles';

export const PageNotFound: FC<RouteComponentProps> = (): JSX.Element => (
  <CenteredLayout size="large">
    <div className={css(container)}>
      <img src={ufo} alt="ufo" />
      <h1 className={css(heading)}>
        404
        <br />
        Page Missing
      </h1>
      <p className={css(centeredText)}>
        Sorry but we can’t find the page you’re looking for. If you think this
        is an error, you can send us an email at{' '}
        <a href="mailto:hello@spaceship.com.au">hello@spaceship.com.au</a> or
        contact one of our helpful Spaceship team members on{' '}
        <a href="tel:1300 049 532">1300&nbsp;049&nbsp;532</a>.
      </p>
    </div>
  </CenteredLayout>
);

const container: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  justifyContent: 'center',

  img: {
    [MEDIA_QUERY_MOBILE_MAX]: {
      display: 'none',
      paddingBottom: 0,
    },
    maxHeight: 400,
    paddingBottom: Spacing.MEDIUM,
  },
};

const centeredText: Interpolation = {
  ...BODY,
  textAlign: 'center',
};

const heading: Interpolation = {
  textAlign: 'center',
  paddingBottom: Spacing.MEDIUM,
};
