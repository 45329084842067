import { Link, navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { PasswordInput } from 'components/password-input';
import { SpaceshipAppPromotionBanner } from 'components/spaceship-app-promotion';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BODY_SMALL,
  columns,
  HEADER_HEIGHT,
  INPUT_MARGIN,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MIN,
  Spacing,
} from 'styles';

import { Routes } from './routes';

const validation = {
  email: {
    required: 'Email is required',
  },
  password: {
    required: 'Password is required',
  },
};

export const Login: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const notifications = useNotifications();
    const {
      sargonStore: { login, loginState },
    } = useStores();

    useEffect(() => {
      if (loginState === 'logged-in') {
        navigate(Routes.DASHBOARD);
      }
    }, [loginState]);

    const { register, errors, handleSubmit } = useForm<{
      email: string;
      password: string;
    }>({
      submitFocusError: true,
    });

    return (
      <div className={css(container)}>
        <div className={css(loginContainer)}>
          <h1 className={css(noWrap)}>Log in to Spaceship</h1>
          <form
            className={css(loginForm)}
            onSubmit={handleSubmit(async ({ email, password }) => {
              setLoading(true);
              try {
                await login(email, password);
              } catch (error) {
                if (error.passwordResetRequired) {
                  await navigate(
                    `${
                      Routes.RESET_PASSWORD
                    }?required=1&email=${encodeURIComponent(email)}`,
                  );
                } else {
                  notifications.popToast({
                    level: 'error',
                    message:
                      error.message ||
                      'Something went wrong, please check your login details and try again.',
                  });
                }
              }
              setLoading(false);
            })}
          >
            <Input
              type="email"
              name="email"
              placeholder="Email address"
              ref={register(validation.email)}
              margin={{ ...INPUT_MARGIN, marginTop: Spacing.LARGE }}
              errorMessage={errors.email && errors.email.message}
            />
            <PasswordInput
              className="fs-hide"
              name="password"
              ref={register(validation.password)}
              errorMessage={errors.password && errors.password.message}
            />
            <div className={css(buttonContainer)}>
              <Button
                type="submit"
                loading={loading}
                trackingProperties={{ name: 'login_submit' }}
              >
                Log in
              </Button>
            </div>
          </form>
          <div className={css(subtext)}>
            Forgot your details?{' '}
            <Link to={Routes.RESET_PASSWORD}>Reset your password</Link>
          </div>
          <div className={css(subtext)}>
            Don&apos;t have an account? <Link to={Routes.SIGNUP}>Sign up</Link>
          </div>
        </div>

        <SpaceshipAppPromotionBanner variant="login" />
      </div>
    );
  },
);

const container: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  width: '100%',
};

const loginContainer: Interpolation = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: Spacing.MEDIUM,
  width: columns(5),

  [MEDIA_QUERY_MOBILE_MAX]: {
    maxWidth: columns(5),
    width: '100%',
  },
};

const loginForm: Interpolation = {
  width: '100%',
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  padding: Spacing.LARGE,
};

const noWrap: Interpolation = {
  [MEDIA_QUERY_TABLET_MIN]: {
    whiteSpace: 'nowrap',
  },
};

const subtext: Interpolation = {
  ...BODY_SMALL,
  marginBottom: Spacing.X_SMALL,

  [MEDIA_QUERY_TABLET_MIN]: {
    whiteSpace: 'nowrap',
  },
};
