import { navigate, RouteComponentProps } from '@reach/router';
import { Member, MemberCreate } from '@sargon/api-client';
import groupPhoto from 'assets/images/group-photo.jpg';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Column, ColumnLayout } from 'components/column-layout';
import { PasswordInput } from 'components/password-input';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { useSignupContext } from 'helpers/contexts/signup';
import { commonValidationRules } from 'helpers/validation';
import React, { FC, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BODY_SMALL,
  CommonColor,
  CONSENT_BOX,
  HEADING_2,
  INPUT_MARGIN,
  MEDIA_QUERY_MOBILE_MAX,
  Spacing,
} from 'styles';

import { ExternalRoutes, Routes } from './routes';

const validation = {
  password: commonValidationRules.password,
  psd: { required: 'Required' },
  fsg: { required: 'Required' },
  rg: { required: 'Required' },
  pp: { required: 'Required' },
  tc: { required: 'Required' },
};

export const SignupPassword: FC<RouteComponentProps> = (): JSX.Element => {
  const [member, setMember] = useSignupContext();

  useEffect(() => {
    switch (true) {
      case member && !member.givenName:
        navigate(Routes.SIGNUP);
        break;
      case member && !member.birthDate:
        navigate(Routes.SIGNUP_ADDRESS);
        break;
    }
  }, [member]);

  const {
    sargonStore: { createMember },
  } = useStores();

  return (
    <ColumnLayout>
      <Column display="desktop-only" verticalCenter={true}>
        <div className={css(illustration)}>
          <img src={groupPhoto} alt="Group photo" />
          <blockquote>
            You&apos;ll be joining thousands of Australians who trust Spaceship
            to manage over $200 million of investment assets.
          </blockquote>
        </div>
      </Column>
      <Column verticalCenter={true}>
        <div className={css(container)}>
          <h1 className={css(header, center)}>Choose your password</h1>
          <p className={css(center, INPUT_MARGIN)}>
            Your new password must be at least 8 characters long, and contain an
            uppercase character, a lowercase character, and a numeric character.
          </p>
          {member !== undefined && (
            <SignupForm
              member={member}
              setMember={setMember}
              createMember={createMember}
            />
          )}
        </div>
      </Column>
    </ColumnLayout>
  );
};

const SignupForm = ({
  member,
  setMember,
  createMember,
}: {
  member: MemberCreate | undefined;
  setMember: (data: MemberCreate) => void;
  createMember: (member: MemberCreate) => Promise<Member | undefined>;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();

  const { register, errors, handleSubmit } = useForm<MemberCreate>({
    submitFocusError: true,
    defaultValues: member,
  });

  return (
    <form
      onSubmit={handleSubmit(async ({ password }) => {
        setLoading(true);
        const newMember = {
          ...member,
          password,
        };
        setMember(newMember);

        try {
          await createMember(newMember);
          await navigate(Routes.ONBOARDING_INVESTMENT_OPTION);
        } catch (error) {
          notifications.popToast({
            message:
              error.message || 'There was an error creating your account.',
            level: 'error',
          });
        }
        setLoading(false);
      })}
    >
      <PasswordInput
        className="fs-hide"
        margin={INPUT_MARGIN}
        name="password"
        ref={register(validation.password)}
        errorMessage={errors.password && errors.password.message}
      />
      <p className={css(BODY_SMALL, { marginBottom: Spacing.MEDIUM })}>
        To ensure that you are fully aware of the service we offer and
        understand the terms and conditions,{' '}
        <span className={css(BODY_SMALL, white)}>
          please download and read the following documents
        </span>
        .
      </p>

      <Checkbox
        name="psd"
        ref={register(validation.psd)}
        errorMessage={errors.psd && errors.psd.message}
        onClick={(): void => {
          window.open(ExternalRoutes.PRODUCT_DISCLOSURE_STATEMENT, '_blank');
        }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={ExternalRoutes.PRODUCT_DISCLOSURE_STATEMENT}
        >
          Product Disclosure Statement
        </a>
      </Checkbox>
      <Checkbox
        name="fsg"
        ref={register(validation.fsg)}
        errorMessage={errors.fsg && errors.fsg.message}
        onClick={(): void => {
          window.open(ExternalRoutes.FINANCIAL_SERVICES_GUIDE, '_blank');
        }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={ExternalRoutes.FINANCIAL_SERVICES_GUIDE}
        >
          Financial Services Guide
        </a>
      </Checkbox>
      <Checkbox
        name="rg"
        ref={register(validation.rg)}
        errorMessage={errors.rg && errors.rg.message}
        onClick={(): void => {
          window.open(ExternalRoutes.REFERENCE_GUIDE, '_blank');
        }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={ExternalRoutes.REFERENCE_GUIDE}
        >
          Reference Guide
        </a>
      </Checkbox>
      <Checkbox
        name="pp"
        ref={register(validation.pp)}
        errorMessage={errors.pp && errors.pp.message}
        onClick={(): void => {
          window.open(ExternalRoutes.PRIVACY_POLICY, '_blank');
        }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={ExternalRoutes.PRIVACY_POLICY}
        >
          Privacy Policy
        </a>
      </Checkbox>
      <Checkbox
        name="tc"
        ref={register(validation.tc)}
        errorMessage={errors.tc && errors.tc.message}
        onClick={(): void => {
          window.open(ExternalRoutes.TERMS_AND_CONDITIONS, '_blank');
        }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={ExternalRoutes.TERMS_AND_CONDITIONS}
        >
          Terms and Conditions
        </a>
      </Checkbox>

      <div className={css(CONSENT_BOX)}>
        <p>By clicking ‘Set password’, I confirm that:</p>
        <ul>
          <li>
            I want to apply to Spaceship for admission to the Spaceship Super
            fund;
          </li>
          <li>
            I have read, understood and accept the Product Disclosure Statement,
            Reference Guide, the website Terms and Conditions that apply to this
            site and Privacy Policy (and agree to the handling of my personal
            information as set out in that policy).
          </li>
        </ul>
      </div>
      <div className={css(center)}>
        <Button
          margin={{ marginRight: Spacing.SMALL }}
          variant="secondary"
          onClick={(): void => {
            navigate(Routes.SIGNUP_ADDRESS);
          }}
          trackingProperties={{ name: 'signup_password_back' }}
        >
          Back
        </Button>
        <Button
          type="submit"
          loading={loading}
          trackingProperties={{ name: 'signup_password_submit' }}
        >
          Set password
        </Button>
      </div>
    </form>
  );
};

const container: Interpolation = {
  maxWidth: 500,
};

const header: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    ...HEADING_2,
  },
};

const white: Interpolation = {
  colour: CommonColor.WHITE,
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const illustration: Interpolation = {
  maxWidth: 470,
  img: {
    maxWidth: '100%',
    display: 'block',
    objectFit: 'contain',
  },
};
