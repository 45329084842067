import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { css, Interpolation } from 'emotion';
import React, { FC } from 'react';
import { Check, Eye, FileText } from 'react-feather';
import { BODY, CommonColor, Spacing } from 'styles';

import { Routes } from './routes';

export const TransferSuccess: FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <ColumnLayout>
      <Column verticalCenter={true}>
        <h1 className={css(heading)}>Transfer in progress</h1>
        <div className={css(iconAndCopyRow)}>
          <div className={css(iconContainer)}>
            <Check size={Spacing.MEDIUM} color={CommonColor.WHITE} />
          </div>
          <p className={css(copy)}>
            Great job! — Your funds are on their way. It may take 1 - 2 weeks
            for the transfer to land in your account.
          </p>
        </div>
        <div className={css(iconAndCopyRow)}>
          <div className={css(iconContainer)}>
            <FileText size={Spacing.MEDIUM} color={CommonColor.WHITE} />
          </div>
          <p className={css(copy)}>
            Want to keep building wealth? Make sure to send your Employer
            Contribution Form to your employer.
          </p>
        </div>
        <div className={css(iconAndCopyRow)}>
          <div className={css(iconContainer)}>
            <Eye size={Spacing.MEDIUM} color={CommonColor.WHITE} />
          </div>
          <p className={css(copy)}>
            Looking for something to do while you wait? Why not kill some time
            by reading our <a href="http://spaceship.com.au/blog">blog</a>.
          </p>
        </div>

        <div className={css(buttonContainer)}>
          <Button
            onClick={(): void => {
              navigate(Routes.DASHBOARD);
            }}
            trackingProperties={{
              name: 'transfer_success_dashboard',
            }}
          >
            Go to dashboard
          </Button>
        </div>
      </Column>
      <Column display="desktop-only" verticalCenter={true}>
        <Illustration type="rocket" />
      </Column>
    </ColumnLayout>
  );
};

const iconAndCopyRow: Interpolation = {
  display: 'flex',
  marginBottom: Spacing.X_SMALL,
};

const iconContainer: Interpolation = {
  paddingRight: Spacing.X_SMALL,
};

const copy: Interpolation = {
  ...BODY,
  marginBottom: 0,
  marginTop: 0,
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
};

const heading: Interpolation = {
  textAlign: 'center',
};
