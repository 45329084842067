import { Link, navigate, RouteComponentProps } from '@reach/router';
import { MemberCreate } from '@sargon/api-client';
import { Button } from 'components/button';
import { COLUMN_LAYOUT_MAX_WIDTH } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { Input } from 'components/input';
import { SpaceshipAppPromotionBanner } from 'components/spaceship-app-promotion';
import { css, cx, Interpolation } from 'emotion';
import { useSignupContext } from 'helpers/contexts/signup';
import { asciiStringValidate, commonValidationRules } from 'helpers/validation';
import { AsYouType } from 'libphonenumber-js';
import React, { FC, useState } from 'react';
import useForm from 'react-hook-form';
import {
  columns,
  Gutter,
  HEADER_HEIGHT,
  INPUT_MARGIN,
  LineHeight,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MAX,
  META,
  ShadeColor,
  Spacing,
} from 'styles';

import { Routes } from './routes';

const validation = {
  givenName: {
    required: 'First name is required',
    validate: (value: string): boolean | string =>
      asciiStringValidate(value, 'First name'),
  },
  familyName: {
    required: 'Last name is required',
    validate: (value: string): boolean | string =>
      asciiStringValidate(value, 'Last name'),
  },
  email: {
    required: 'Email is required',
  },
  phoneMobile: {
    required: 'Mobile is required',
    ...commonValidationRules.phoneNumber,
  },
};

export const Signup: FC<RouteComponentProps> = (): JSX.Element => {
  const [member, setMember] = useSignupContext();

  return (
    <div className={css(container)}>
      <div className={css(signupContainer)}>
        <div className={cx(css(centerContentColumn), css(mobileHidden))}>
          <Illustration type="form" />
        </div>

        <div className={css(centerContentColumn)}>
          <h1 className={css(header)}>Let&apos;s start your account</h1>
          {member !== undefined && (
            <SignupForm member={member} setMember={setMember} />
          )}
          <p className={css(loginText)}>
            Already a Spaceship Super member?{' '}
            <Link to={Routes.LOGIN}>Log in</Link>
          </p>
        </div>
      </div>

      <SpaceshipAppPromotionBanner variant="signup" />
    </div>
  );
};

const SignupForm = ({
  member,
  setMember,
}: {
  member: MemberCreate | undefined;
  setMember: (data: MemberCreate) => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm<MemberCreate>({
    submitFocusError: true,
    defaultValues: member,
  });

  return (
    <form
      className={css(signupForm)}
      onSubmit={handleSubmit(async memberCreateRequest => {
        setLoading(true);
        setMember({
          ...member,
          ...memberCreateRequest,
        });
        setLoading(false);
        await navigate(Routes.SIGNUP_ADDRESS);
      })}
    >
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="givenName"
        placeholder="First name"
        ref={register(validation.givenName)}
        errorMessage={errors.givenName && errors.givenName.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="familyName"
        placeholder="Last name"
        ref={register(validation.familyName)}
        errorMessage={errors.familyName && errors.familyName.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="email"
        name="email"
        placeholder="Email address"
        ref={register(validation.email)}
        errorMessage={errors.email && errors.email.message}
      />
      <Input
        type="tel"
        name="phoneMobile"
        placeholder="Mobile"
        ref={register(validation.phoneMobile)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
          event.target.value = new AsYouType().input(event.target.value);
        }}
        errorMessage={errors.phoneMobile && errors.phoneMobile.message}
      />
      <p className={css(phoneNumberHint)}>
        Must include country code, e.g. +61 456 456 456
      </p>
      <div className={css(buttonContainer)}>
        <Button
          type="submit"
          loading={loading}
          trackingProperties={{ name: 'signup_submit' }}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

const container: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  width: '100%',
};

const signupContainer: Interpolation = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: COLUMN_LAYOUT_MAX_WIDTH,
  padding: `0 ${Gutter.DESKTOP}px`,
  width: '100%',

  [MEDIA_QUERY_MOBILE_MAX]: {
    padding: `0 ${Gutter.MOBILE}px`,
  },
};

const centerContentColumn: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  marginBottom: Spacing.MEDIUM,
  marginTop: Spacing.MEDIUM,
  width: '100%',

  [MEDIA_QUERY_TABLET_MAX]: {
    marginBottom: Spacing.LARGE,
  },
};

const mobileHidden: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },
};

const header: Interpolation = {
  marginBottom: Spacing.MEDIUM,

  [MEDIA_QUERY_TABLET_MAX]: {
    lineHeight: LineHeight.HEADING_2,
  },
};

const loginText: Interpolation = {
  [MEDIA_QUERY_TABLET_MAX]: {
    textAlign: 'center',
  },
};

const signupForm: Interpolation = {
  width: '100%',

  [MEDIA_QUERY_TABLET_MAX]: {
    maxWidth: columns(5),
  },
};

const phoneNumberHint: Interpolation = {
  ...META,
  color: ShadeColor.SHADE_2,
  marginTop: Spacing.X_SMALL,
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  padding: Spacing.LARGE,
};
