import { Tooltip } from 'components/tooltip';
import { css, cx, Interpolation } from 'emotion';
import React, {
  forwardRef,
  ReactNode,
  RefForwardingComponent,
  useState,
} from 'react';
import {
  BorderWidth,
  CommonColor,
  Margin,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string;
  children: ReactNode;
  // TODO: SAR-1870: tooltip instead of label
  errorMessage?: string;
  disabled?: boolean;
  checked?: boolean;
  margin?: Margin;
  onClick?: () => void;
}

const Checkbox: RefForwardingComponent<HTMLInputElement, Props> = (
  { children, disabled, errorMessage, margin, onClick, checked, ...props },
  ref,
): JSX.Element => {
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <div
      className={css(container)}
      onClick={(e): void => {
        if (onClick) {
          e.preventDefault();
          onClick();
          setIsChecked(isChecked => !isChecked);
        }
      }}
    >
      <label
        className={cx(css(checkbox, { ...margin }), {
          error: !!errorMessage && !disabled,
        })}
      >
        <input
          type="checkbox"
          {...{ ...props, disabled, ref }}
          checked={isChecked}
        />

        {!!errorMessage && !disabled ? (
          <Tooltip text={errorMessage} error>
            <span className="checkbox" />
          </Tooltip>
        ) : (
          <span className="checkbox" />
        )}
        <div className="label">{children}</div>
      </label>
    </div>
  );
};

const CHECKBOX_RADIUS = 4;
const CHECKBOX_WIDTH = 24;
const CHECKBOX_HEIGHT = CHECKBOX_WIDTH;

const CHECKMARK_BORDER_WIDTH = 2;
const CHECKMARK_HEIGHT = 5;
const CHECKMARK_WIDTH = 10;

const container: Interpolation = {
  display: 'flex',
  flexDirection: 'column',
};

const checkbox: Interpolation = {
  position: 'relative',
  display: 'flex',
  margin: `${Spacing.X_SMALL}px 0`,
  cursor: 'pointer',

  '.label': {
    display: 'inline-block',
    paddingLeft: Spacing.SMALL,
  },

  '&.error span::before': {
    borderColor: CommonColor.RED,
  },

  input: {
    // Hide native checkbox
    clipPath: 'polygon(0 0)',
    position: 'absolute',

    // Fill checkbox (when checked)
    ':checked + span::before': {
      background: PrimaryColor.PURPLE_1,
    },

    // Fill checkbox (when disabled)
    ':disabled + span::before': {
      borderColor: ShadeColor.SHADE_3,
      background: ShadeColor.SHADE_4,
    },

    // Disable text
    ':disabled + span': {
      color: ShadeColor.SHADE_2,
    },

    // Show checkmark (when checked)
    ':checked + span::after': {
      content: '""',
    },
  },

  '.checkbox': {
    display: 'inline-block',
    marginRight: Spacing.X_SMALL,
    width: CHECKBOX_WIDTH,

    // Checkbox
    '::before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      left: 0,
      top: -3,
      width: CHECKBOX_WIDTH,
      height: CHECKBOX_HEIGHT,
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: BorderWidth.MEDIUM,
      borderColor: 'transparent',
      borderRadius: CHECKBOX_RADIUS,
      backgroundColor: CommonColor.WHITE,
    },

    // Checkmark
    '::after': {
      // Hide by default (if not checked)
      content: 'none',
      display: 'inline-block',
      position: 'absolute',
      left: 6,
      top: 4,
      height: CHECKMARK_HEIGHT,
      width: CHECKMARK_WIDTH,
      borderLeft: CHECKMARK_BORDER_WIDTH,
      borderLeftStyle: 'solid',
      borderBottom: CHECKMARK_BORDER_WIDTH,
      borderBottomStyle: 'solid',
      borderColor: CommonColor.WHITE,
      transform: 'rotate(-45deg)',
    },
  },
};

const ForwardedCheckbox = forwardRef<HTMLInputElement, Props>(Checkbox);
export { ForwardedCheckbox as Checkbox };
