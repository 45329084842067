import { UnitPrice } from '@sargon/api-client';
import { Decimal } from 'decimal.js';
import { css, Interpolation } from 'emotion';
import { LONG_DATE_FORMAT } from 'helpers/format';
import moment from 'moment';
import React from 'react';
import { Info } from 'react-feather';
import {
  CommonColor,
  HEADING_2,
  IconSize,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MIN,
  META,
  ShadeColor,
  Spacing,
} from 'styles';

import { Tooltip } from './tooltip';

interface Props {
  portfolioDisplayName: string;
  unitPrice?: UnitPrice;
}

export const PortfolioUnitPrice = ({
  portfolioDisplayName,
  unitPrice,
}: Props): JSX.Element => {
  const price =
    unitPrice?.sellPrice?.amount && unitPrice?.sellPrice?.precision
      ? new Decimal(unitPrice.sellPrice.amount)
          .dividedBy(new Decimal(10).pow(unitPrice.sellPrice.precision))
          .toDecimalPlaces(4)
          .toString()
      : undefined;

  return (
    <div className={css(unitPriceSection)}>
      <h4 className={css(portfolioDisplayNameText)}>
        {portfolioDisplayName.toUpperCase()}
      </h4>

      <div className={css(unitPriceLabel)}>
        <h4 className={css(unitPriceLabelText)}>UNIT PRICE</h4>
        {unitPrice?.date && (
          <Tooltip
            text={`Unit prices reflect the value of the assets or
              investments within each investment option. The
              unit price shown is as of ${moment(unitPrice?.date).format(
                LONG_DATE_FORMAT,
              )}.`}
          >
            <span>
              <Info
                className={css(unitPriceInfoIcon)}
                color={ShadeColor.SHADE_2}
                size={IconSize.SMALL}
              />
            </span>
          </Tooltip>
        )}
      </div>
      <h2 className={css(unitPriceText)}>
        ${price === undefined ? '-' : price}
      </h2>
    </div>
  );
};

const portfolioDisplayNameText: Interpolation = {
  ...META,
  color: ShadeColor.SHADE_3,

  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },
};

const unitPriceLabelText: Interpolation = {
  ...META,
  color: ShadeColor.SHADE_2,
  marginRight: Spacing.XXX_SMALL,
};

const unitPriceText: Interpolation = {
  ...HEADING_2,
  color: CommonColor.GREEN,
};

const unitPriceSection: Interpolation = {
  display: 'flex',
  flexDirection: 'column',

  [MEDIA_QUERY_TABLET_MIN]: {
    alignItems: 'flex-end',
  },
};

const unitPriceLabel: Interpolation = {
  display: 'flex',
};

const unitPriceInfoIcon: Interpolation = {
  lineHeight: 0,
};
