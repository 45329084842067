import { Link } from '@reach/router';
import avatar from 'assets/images/avatar.png';
import { CenteredLayout } from 'components/centered-layout';
import { Logo } from 'components/logo';
import { Pill } from 'components/pill';
import { Tooltip } from 'components/tooltip';
import { css, cx, Interpolation } from 'emotion';
import { config } from 'helpers/config';
import { useIntercom } from 'helpers/contexts/intercom';
import { observer } from 'mobx-react';
import { ExternalRoutes, Routes } from 'pages/routes';
import React, { Fragment, useEffect, useState } from 'react';
import {
  ChevronDown,
  ChevronRight,
  LogOut,
  Mail,
  MessageSquare,
  Phone,
} from 'react-feather';
import { useStores } from 'stores';
import {
  BODY,
  BODY_SMALL,
  BorderRadius,
  BorderWidth,
  Breakpoint,
  CommonColor,
  FontSize,
  FontWeight,
  Gutter,
  HEADER_HEIGHT,
  hex2rgba,
  IconSize,
  LineHeight,
  MEDIA_QUERY_DESKTOP_MIN,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MAX,
  MEDIA_QUERY_TABLET_MIN,
  PrimaryColor,
  ShadeColor,
  Spacing,
  TRANSITION_DURATION,
  ZIndex,
} from 'styles';

interface Props {
  currentPath: string;
}

export const Header = observer(
  ({ currentPath }: Props): JSX.Element => {
    const SIGNUP_TOTAL_STEPS = 4;
    let signupStep;
    const intercom = useIntercom();
    const {
      sargonStore: { hasSubmittedTFN, loginState, logout, member },
    } = useStores();
    const [open, setOpen] = useState(false);

    /* HEADER TYPES (+ hasSubmittedTFN) */
    const isSignup = currentPath.includes(Routes.SIGNUP);
    const isOnboarding = currentPath.includes(
      Routes.ONBOARDING_INVESTMENT_OPTION,
    );
    const isLoggedIn = loginState === 'logged-in';
    const isFullDashboard = isLoggedIn && !isOnboarding && hasSubmittedTFN;
    /* HEADER TYPES: END */

    /* EVENT HANDLERS */
    const handleOpen = (): void => {
      setOpen(!open);
    };

    const handleIntercomPop = (): void => {
      setOpen(false);
      intercom.pop();
    };

    const handleLogout = async (): Promise<void> => {
      setOpen(false);
      await logout();
    };
    /* EVENT HANDLERS: END */

    if (isSignup) {
      switch (true) {
        case currentPath.includes(Routes.SIGNUP_PASSWORD):
          signupStep = 4;
          break;
        case currentPath.includes(Routes.SIGNUP_TFN):
          signupStep = 3;
          break;
        case currentPath.includes(Routes.SIGNUP_ADDRESS):
          signupStep = 2;
          break;
        default:
          signupStep = 1;
          break;
      }
    }

    useEffect(() => {
      if (open) {
        setOpen(false);
      }
    }, [currentPath]);

    return (
      <header className={css(header)}>
        <CenteredLayout size="full">
          <div className={css(container)}>
            <div className={css(logoContainer)}>
              {!isSignup && !isLoggedIn ? (
                <a
                  className={css(logo)}
                  href={ExternalRoutes.SUPER_MARKETING_ROOT}
                >
                  <Logo variant="dark" />
                </a>
              ) : (
                <Link className={css(logo)} to="/">
                  <Logo variant="dark" />
                </Link>
              )}

              {!isSignup && !isLoggedIn && (
                <a className={css(hiring)} href={ExternalRoutes.CAREERS}>
                  <Pill text="We're hiring!" />
                </a>
              )}
            </div>

            {isSignup ? (
              <Tooltip
                text={`Step ${signupStep} of ${SIGNUP_TOTAL_STEPS}`}
                placement="bottom"
              >
                <progress
                  className={css(onboardingProgress)}
                  max={SIGNUP_TOTAL_STEPS}
                  value={signupStep}
                />
              </Tooltip>
            ) : (
              <Fragment>
                <button
                  className={cx(css(menuToggle), { open })}
                  onClick={handleOpen}
                >
                  <span>Menu</span>
                </button>

                <nav className={css(nav)}>
                  {isLoggedIn && !!member.id && (
                    <div className={css(memberInfo)}>
                      <h3>
                        <img src={avatar} alt="avatar" />
                        {member.givenName} {member.familyName}
                      </h3>

                      <div>
                        <p>
                          <Mail
                            size={IconSize.SMALL}
                            color={ShadeColor.SHADE_2}
                          />
                          {member.email}
                        </p>
                        <p>
                          <Phone
                            size={IconSize.SMALL}
                            color={ShadeColor.SHADE_2}
                          />
                          {member.phoneMobile}
                        </p>
                      </div>
                    </div>
                  )}

                  <ul className={css(menu)}>
                    {isFullDashboard && (
                      <li>
                        <Link to={Routes.DASHBOARD}>
                          Super
                          <ChevronDown size={IconSize.SMALL} />
                        </Link>

                        <nav>
                          <div>
                            <h4>
                              <Link to={Routes.INVESTMENT_CHANGE_PORTFOLIO}>
                                Investment
                              </Link>
                            </h4>
                            <p>
                              GrowthX is a diversified portfolio with technology
                              at its core.
                            </p>

                            <ul>
                              <li>
                                <Link to={Routes.DASHBOARD}>
                                  <ChevronRight size={IconSize.SMALL} />
                                  Overview
                                </Link>
                              </li>
                              <li>
                                <Link to={Routes.INVESTMENT_CHANGE_PORTFOLIO}>
                                  <ChevronRight size={IconSize.SMALL} />
                                  Change investment option
                                </Link>
                              </li>
                            </ul>

                            <h4>
                              <Link to={Routes.TRANSACTIONS}>Transactions</Link>
                            </h4>
                            <p>Your Super account transactions overview.</p>

                            <ul>
                              <li>
                                <Link to={Routes.TRANSACTIONS}>
                                  <ChevronRight size={IconSize.SMALL} />
                                  History
                                </Link>
                              </li>
                            </ul>
                          </div>

                          <div>
                            <h4>
                              <Link to={Routes.BENEFICIARIES}>
                                Non-binding beneficiaries
                              </Link>
                            </h4>
                            <p>Update and review your beneficiaries.</p>

                            <ul>
                              <li>
                                <Link to={Routes.BENEFICIARIES}>
                                  <ChevronRight size={IconSize.SMALL} />
                                  Add/View/Edit
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </li>
                    )}

                    {!isLoggedIn && (
                      <Fragment>
                        <li>
                          <a href={ExternalRoutes.SUPER_PRODUCTS}>
                            Super
                            <ChevronDown size={IconSize.SMALL} />
                          </a>

                          <nav>
                            <div>
                              <h4>
                                <a href={ExternalRoutes.SUPER_GROWTHX_OVERVIEW}>
                                  GrowthX
                                </a>
                              </h4>
                              <p>
                                GrowthX is a diversified portfolio with
                                technology at its core.
                              </p>

                              <ul>
                                <li>
                                  <a
                                    href={ExternalRoutes.SUPER_GROWTHX_OVERVIEW}
                                  >
                                    <ChevronRight size={IconSize.SMALL} />
                                    Overview
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div>
                              <h4>
                                <a
                                  href={
                                    ExternalRoutes.SUPER_GLOBAL_INDEX_OVERVIEW
                                  }
                                >
                                  Global Index
                                </a>
                              </h4>
                              <p>More diversification. Less fees.</p>

                              <ul>
                                <li>
                                  <a
                                    href={
                                      ExternalRoutes.SUPER_GLOBAL_INDEX_OVERVIEW
                                    }
                                  >
                                    <ChevronRight size={IconSize.SMALL} />
                                    Overview
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </nav>
                        </li>

                        <li>
                          <a href={ExternalRoutes.ABOUT}>About</a>
                        </li>
                      </Fragment>
                    )}

                    {(isFullDashboard || !isLoggedIn) && (
                      <li>
                        <a href={ExternalRoutes.LEARN}>
                          Learn
                          <ChevronDown size={IconSize.SMALL} />
                        </a>

                        <nav>
                          <div>
                            <h4>
                              <a href={ExternalRoutes.LEARN}>Blog</a>
                            </h4>
                            <p>
                              We want to help you understand how the world
                              works, so you can make better decisions.
                              That&apos;s why we write about investing,
                              economics, philosophy, learning, and mental
                              models.
                            </p>

                            <p>
                              In a world full of noise, we believe these topics
                              are the topics that lead to meaningful
                              understanding.
                            </p>
                          </div>

                          <div>
                            <h4>
                              <a href={ExternalRoutes.LEARN_SUPER}>Super 101</a>
                            </h4>
                            <p>All you need to know, in one simple series.</p>

                            <h4>
                              <a href={ExternalRoutes.LEARN_FINANCE}>
                                Finance 101
                              </a>
                            </h4>
                            <p>
                              Finance isn&apos;t as hard as some people want you
                              to think.
                            </p>

                            <h4>
                              <a href={ExternalRoutes.LEARN_PERSONAL_FINANCE}>
                                Personal Finance 101
                              </a>
                            </h4>
                            <p>Learn how to better manage your money.</p>

                            <h4>
                              <a href={ExternalRoutes.LEARN_ECONOMICS}>
                                Economics 101
                              </a>
                            </h4>
                            <p>
                              We&apos;re making economics easy-to-understand for
                              everyone.
                            </p>
                          </div>
                        </nav>
                      </li>
                    )}

                    {isLoggedIn ? (
                      <Fragment>
                        {!isOnboarding && (
                          <li className={css(ctaItem)}>
                            {!hasSubmittedTFN && (
                              <Link
                                className={css(ctaLink)}
                                to={Routes.ACCOUNT_TFN}
                              >
                                Add your TFN
                              </Link>
                            )}
                            {hasSubmittedTFN && !config.supermatchDisabled && (
                              <Link
                                className={css(ctaLink)}
                                to={Routes.TRANSFER_SEARCH}
                              >
                                Rollover to Spaceship
                              </Link>
                            )}
                          </li>
                        )}

                        <li className={cx(css(avatarItem), 'left')}>
                          <span className={css(avatarContainer)}>
                            <img src={avatar} alt="avatar" />
                          </span>

                          <nav>
                            <div>
                              {!!member.id && (
                                <Fragment>
                                  <h4>
                                    {member.givenName} {member.familyName}
                                  </h4>
                                  <p>
                                    <Mail
                                      size={IconSize.SMALL}
                                      color={ShadeColor.SHADE_2}
                                    />
                                    {member.email}
                                  </p>
                                  <p>
                                    <Phone
                                      size={IconSize.SMALL}
                                      color={ShadeColor.SHADE_2}
                                    />
                                    {member.phoneMobile}
                                  </p>
                                </Fragment>
                              )}

                              <ul>
                                {!isOnboarding && (
                                  <Fragment>
                                    <li>
                                      <Link to={Routes.ACCOUNT_CONTACT}>
                                        <ChevronRight size={IconSize.SMALL} />
                                        Contact details
                                      </Link>
                                    </li>

                                    <li>
                                      <Link to={Routes.ACCOUNT_ADDRESS}>
                                        <ChevronRight size={IconSize.SMALL} />
                                        Address details
                                      </Link>
                                    </li>
                                    {!hasSubmittedTFN && (
                                      <li>
                                        <Link to={Routes.ACCOUNT_TFN}>
                                          <ChevronRight size={IconSize.SMALL} />
                                          Tax file number
                                        </Link>
                                      </li>
                                    )}
                                  </Fragment>
                                )}

                                <li>
                                  <button onClick={handleIntercomPop}>
                                    <MessageSquare size={IconSize.SMALL} />
                                    Contact support
                                  </button>
                                </li>

                                <li>
                                  <button onClick={handleLogout}>
                                    <LogOut size={IconSize.SMALL} />
                                    Log out
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </nav>
                        </li>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <li>
                          <a href={ExternalRoutes.CONTACT}>Contact</a>
                        </li>

                        <li>
                          <Link to={Routes.LOGIN}>Log in</Link>
                        </li>

                        <li className={css(ctaItem)}>
                          <Link className={css(ctaLink)} to={Routes.SIGNUP}>
                            Sign up
                          </Link>
                        </li>
                      </Fragment>
                    )}
                  </ul>
                </nav>
              </Fragment>
            )}
          </div>
        </CenteredLayout>
      </header>
    );
  },
);

const SUBMENU_COLUMN_WIDTH_DESKTOP = 270;
const SUBMENU_COLUMN_WIDTH_TABLET = 250;
const MENU_TOGGLE_LINE_HEIGHT = 2;
const MENU_TOGGLE_LINE_WIDTH = 24;
const MENU_TOGGLE_PADDING = Spacing.SMALL;
const MENU_TOGGLE_LINE_OFFSET =
  MENU_TOGGLE_PADDING + MENU_TOGGLE_LINE_HEIGHT + 1;
const MENU_TOGGLE_OPEN_LINE_WIDTH = 33;
const ONBOARDING_PROGRESS_HEIGHT = 10;
const ONBOARDING_PROGRESS_WIDTH_TABLET = 180;
const ONBOARDING_PROGRESS_WIDTH_MOBILE = 120;
const AVATAR_ORIGINAL_HEIGHT = 103;
const AVATAR_ORIGINAL_WIDTH = 67;
const AVATAR_HEIGHT = AVATAR_ORIGINAL_HEIGHT / 3;
const AVATAR_WIDTH = AVATAR_ORIGINAL_WIDTH / 3;

const header: Interpolation = {
  borderBottom: `${BorderWidth.SMALL}px solid ${hex2rgba(
    CommonColor.WHITE,
    0.1,
  )}`,

  [MEDIA_QUERY_MOBILE_MAX]: {
    backgroundColor: CommonColor.BACKGROUND,
    position: 'fixed',
    width: '100vw',
    zIndex: ZIndex.MENU,

    '& + *': {
      paddingTop: HEADER_HEIGHT,
    },
  },
};

const container: Interpolation = {
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  height: HEADER_HEIGHT - BorderWidth.SMALL,
  justifyContent: 'space-between',
};

const ctaItem: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    marginBottom: Spacing.LARGE,
    order: -1,
  },
};

const logoContainer: Interpolation = {
  alignItems: 'center',
  display: 'flex',
};

const logo: Interpolation = {
  display: 'inline-block',
  marginTop: Spacing.XXX_SMALL,
};

const hiring: Interpolation = {
  marginLeft: Spacing.LARGE,

  [`@media(max-width: ${Breakpoint.TABLET + 100}px)`]: {
    display: 'none',
  },
};

const onboardingProgress: Interpolation = {
  appearance: 'none',
  backgroundClip: 'content-box',
  border: 0,
  height: ONBOARDING_PROGRESS_HEIGHT,
  width: ONBOARDING_PROGRESS_WIDTH_MOBILE,

  [MEDIA_QUERY_TABLET_MIN]: {
    width: ONBOARDING_PROGRESS_WIDTH_TABLET,
  },

  '::-webkit-progress-bar': {
    backgroundColor: hex2rgba(ShadeColor.SHADE_2, 0.3),
    borderRadius: ONBOARDING_PROGRESS_HEIGHT / 2,
  },

  '::-webkit-progress-value': {
    backgroundColor: PrimaryColor.PURPLE_2,
    borderRadius: `${ONBOARDING_PROGRESS_HEIGHT /
      2}px 0 0 ${ONBOARDING_PROGRESS_HEIGHT / 2}px`,
  },

  '::-moz-progress-bar': {
    backgroundColor: PrimaryColor.PURPLE_2,
  },

  '&[value="3"]::-webkit-progress-value': {
    borderRadius: ONBOARDING_PROGRESS_HEIGHT / 2,
  },
};

const avatarItem: Interpolation = {
  alignItems: 'center',
  display: 'flex',

  [MEDIA_QUERY_TABLET_MIN]: {
    height: HEADER_HEIGHT,
  },

  nav: {
    [MEDIA_QUERY_MOBILE_MAX]: {
      width: '100%',
    },

    'h4, p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [MEDIA_QUERY_MOBILE_MAX]: {
        display: 'none',
      },
    },

    '> div': {
      p: {
        '&, & + p': {
          margin: `${Spacing.SMALL}px ${Spacing.XXX_SMALL}px 0`,
        },

        '+ p': {
          marginTop: Spacing.X_SMALL,
        },

        svg: {
          marginTop: `calc((${LineHeight.BODY_SMALL} - ${IconSize.SMALL}px + ${Spacing.XXXX_SMALL}px) / 2)`,
        },

        '& + ul': {
          [MEDIA_QUERY_TABLET_MIN]: {
            borderTop: `${BorderWidth.MEDIUM}px solid ${ShadeColor.SHADE_1}`,
            marginTop: Spacing.SMALL,
            paddingTop: Spacing.SMALL,
          },
        },
      },

      ul: {
        marginTop: 0,

        [MEDIA_QUERY_TABLET_MIN]: {
          'li, li:not(:first-child)': {
            margin: `${Spacing.SMALL}px ${Spacing.XXX_SMALL}px 0`,
          },

          'li:first-child, svg': {
            marginTop: 0,
          },
        },
      },

      'p, ul': {
        svg: {
          marginLeft: 0,
          marginRight: Spacing.X_SMALL,
        },
      },
    },
  },
};

const avatarContainer: Interpolation = {
  backgroundColor: 'transparent',
  border: `${BorderWidth.MEDIUM}px solid ${PrimaryColor.PURPLE_2}`,
  borderRadius: BorderRadius.CIRCLE,
  cursor: 'pointer',
  display: 'inline-block',
  height: AVATAR_HEIGHT + Spacing.X_SMALL,
  textAlign: 'center',
  width: AVATAR_HEIGHT + Spacing.X_SMALL,

  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },

  [MEDIA_QUERY_TABLET_MIN]: {
    marginLeft: Spacing.LARGE,
  },

  img: {
    height: AVATAR_HEIGHT,
    padding: `${Spacing.X_SMALL / 2}px 0`,
    width: AVATAR_WIDTH,
  },
};

const memberInfo: Interpolation = {
  backgroundColor: hex2rgba(ShadeColor.SHADE_2, 0.3),
  borderRadius: BorderRadius.SMALL,
  padding: Spacing.SMALL,
  marginBottom: Spacing.MEDIUM,

  [MEDIA_QUERY_TABLET_MIN]: {
    display: 'none',
  },

  'h3, p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  h3: {
    ...BODY,
    color: CommonColor.WHITE,
    fontWeight: FontWeight.SEMI_BOLD,
    lineHeight: `${AVATAR_HEIGHT - Spacing.XXX_SMALL}px`,
    margin: `${Spacing.XXX_SMALL}px 0 0`,

    img: {
      height: AVATAR_HEIGHT,
      verticalAlign: 'top',
      marginRight: Spacing.SMALL,
      marginTop: -Spacing.XXX_SMALL,
      width: AVATAR_WIDTH,
    },
  },

  p: {
    margin: `${Spacing.SMALL}px 0 0`,

    svg: {
      display: 'inline-block',
      margin: `0 ${(AVATAR_WIDTH - IconSize.SMALL) / 2 +
        Spacing.SMALL}px 0 ${(AVATAR_WIDTH - IconSize.SMALL) / 2}px`,
      stroke: CommonColor.WHITE,
      verticalAlign: 'bottom',
    },
  },
};

const ctaLink: Interpolation = {
  backgroundColor: PrimaryColor.PURPLE_1,
  borderRadius: Spacing.LARGE / 2,
  fontSize: FontSize.BODY_SMALL,
  color: CommonColor.WHITE,
  display: 'inline-block',
  lineHeight: `${Spacing.LARGE}px`,
  padding: `0 ${Spacing.MEDIUM}px`,

  [MEDIA_QUERY_MOBILE_MAX]: {
    borderRadius: Spacing.X_LARGE / 2,
    lineHeight: `${Spacing.X_LARGE}px`,
    textAlign: 'center',
  },

  [MEDIA_QUERY_TABLET_MIN]: {
    marginLeft: Spacing.SMALL,
  },

  ':hover': {
    backgroundColor: PrimaryColor.PURPLE_2,
  },
};

const nav: Interpolation = {
  [MEDIA_QUERY_TABLET_MIN]: {
    zIndex: ZIndex.MENU,
  },

  [MEDIA_QUERY_MOBILE_MAX]: {
    backgroundColor: hex2rgba(CommonColor.BACKGROUND, 0.95),
    bottom: 0,
    boxSizing: 'border-box',
    left: 0,
    overflow: 'auto',
    padding: `${Spacing.MEDIUM}px ${Gutter.MOBILE}px`,
    position: 'fixed',
    top: HEADER_HEIGHT,
    transform: 'translateX(-100%)',
    transitionDuration: TRANSITION_DURATION,
    width: '100vw',

    '.open + &': {
      transform: 'translateX(0)',
    },
  },
};

const menu: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  listStyle: 'none',
  margin: 0,
  padding: 0,

  [MEDIA_QUERY_MOBILE_MAX]: {
    alignItems: 'stretch',
    flexDirection: 'column',
  },

  'a, button': {
    display: 'inline-block',
    boxSizing: 'border-box',
    textDecoration: 'none',

    [MEDIA_QUERY_MOBILE_MAX]: {
      width: '100%',
    },
  },

  '> li': {
    position: 'relative',

    [`:not(.${css(ctaItem)})`]: {
      [MEDIA_QUERY_MOBILE_MAX]: {
        marginLeft: -Gutter.MOBILE,
        marginRight: -Gutter.MOBILE,
      },
    },

    [`:not(.${css(ctaItem)}):not(.${css(avatarItem)}), &.${css(
      avatarItem,
    )} li`]: {
      [MEDIA_QUERY_MOBILE_MAX]: {
        borderBottom: `${BorderWidth.SMALL}px solid ${hex2rgba(
          CommonColor.WHITE,
          0.5,
        )}`,
      },

      ':first-child': {
        [MEDIA_QUERY_MOBILE_MAX]: {
          borderTop: `${BorderWidth.SMALL}px solid ${hex2rgba(
            CommonColor.WHITE,
            0.5,
          )}`,
        },
      },
    },

    'a, button': {
      fontSize: FontSize.BODY_SMALL,
      fontWeight: FontWeight.REGULAR,
    },

    [`a, button, &.${css(avatarItem)} nav a, &.${css(
      avatarItem,
    )} nav button`]: {
      [MEDIA_QUERY_MOBILE_MAX]: {
        fontSize: FontSize.BODY,
        fontWeight: FontWeight.SEMI_BOLD,
        textAlign: 'center',
      },
    },

    [`a, button, .${css(avatarContainer)}`]: {
      transitionDuration: TRANSITION_DURATION,
    },

    [`> a:not(.${css(ctaLink)})`]: {
      color: hex2rgba(ShadeColor.SHADE_3, 0.8),
      lineHeight: `${HEADER_HEIGHT}px`,

      [MEDIA_QUERY_TABLET_MAX]: {
        padding: `0 ${Spacing.SMALL}px`,
      },

      [MEDIA_QUERY_DESKTOP_MIN]: {
        padding: `0 ${Spacing.MEDIUM}px`,
      },
    },

    [`> a:not(.${css(ctaLink)}), &.${css(avatarItem)} nav a, &.${css(
      avatarItem,
    )} nav button`]: {
      [MEDIA_QUERY_MOBILE_MAX]: {
        backgroundColor: hex2rgba(CommonColor.GREY, 0.15),
        color: CommonColor.WHITE,
        lineHeight: `${Spacing.X_LARGE}px`,
      },
    },

    ':hover > a, > a:hover': {
      color: CommonColor.WHITE,
    },

    ':hover > a:not(:only-child), > a:not(:only-child):hover': {
      [MEDIA_QUERY_TABLET_MIN]: {
        backgroundColor: CommonColor.WHITE,
        color: ShadeColor.SHADE_1,
      },
    },

    [`:hover > .${css(avatarContainer)}, > .${css(avatarContainer)}:hover`]: {
      [MEDIA_QUERY_TABLET_MIN]: {
        backgroundColor: CommonColor.WHITE,
        borderColor: CommonColor.WHITE,
      },
    },

    '> nav': {
      [MEDIA_QUERY_TABLET_MIN]: {
        opacity: 0,
        pointerEvents: 'none',
        transform: 'scale(0)',
      },
    },

    ':not(.left) nav': {
      [MEDIA_QUERY_TABLET_MIN]: {
        transform: 'scale(0) translateX(50%)',
      },

      [MEDIA_QUERY_DESKTOP_MIN]: {
        transform: 'scale(0)',
      },
    },

    ':hover nav, > a:hover ~ nav': {
      [MEDIA_QUERY_TABLET_MIN]: {
        opacity: 1,
        pointerEvents: 'auto',
        transform: 'scale(1)',
      },
    },

    ':not(.left):hover nav, :not(.left) > a:hover ~ nav': {
      [MEDIA_QUERY_TABLET_MIN]: {
        transform: 'scale(1) translateX(50%)',
      },

      [MEDIA_QUERY_DESKTOP_MIN]: {
        transform: 'scale(1)',
      },
    },

    svg: {
      margin: `${(HEADER_HEIGHT - IconSize.SMALL) / 2}px 0 0 ${
        Spacing.XXX_SMALL
      }px`,
      verticalAlign: 'top',

      [MEDIA_QUERY_MOBILE_MAX]: {
        display: 'none',
      },
    },
  },

  nav: {
    [MEDIA_QUERY_TABLET_MIN]: {
      backgroundColor: CommonColor.WHITE,
      color: ShadeColor.SHADE_1,
      display: 'flex',
      padding: Spacing.MEDIUM,
      position: 'absolute',
      right: 0,
      top: '100%',
      transformOrigin: 'top right',
      transitionDuration: TRANSITION_DURATION,
    },

    div: {
      boxSizing: 'border-box',

      [MEDIA_QUERY_MOBILE_MAX]: {
        ':last-child ul': {
          marginBottom: Spacing.SMALL,
        },
      },

      [MEDIA_QUERY_TABLET_MIN]: {
        width: SUBMENU_COLUMN_WIDTH_TABLET,

        ':not(:first-child)': {
          marginLeft: Spacing.MEDIUM,
        },
      },

      [MEDIA_QUERY_DESKTOP_MIN]: {
        width: SUBMENU_COLUMN_WIDTH_DESKTOP,
      },
    },

    'h4, h4 a': {
      ...BODY,
      fontWeight: FontWeight.SEMI_BOLD,

      [MEDIA_QUERY_MOBILE_MAX]: {
        ...BODY_SMALL,
        color: CommonColor.WHITE,
        fontWeight: FontWeight.SEMI_BOLD,
        lineHeight: `${Spacing.X_LARGE}px`,
      },
    },

    h4: {
      [MEDIA_QUERY_TABLET_MIN]: {
        borderBottom: `${BorderWidth.MEDIUM}px solid ${ShadeColor.SHADE_1}`,
        paddingBottom: Spacing.XXX_SMALL,

        ':not(:first-child)': {
          marginTop: Spacing.MEDIUM,
        },
      },
    },

    p: {
      ...BODY_SMALL,
      color: ShadeColor.SHADE_2,
      fontWeight: FontWeight.REGULAR,
      marginTop: Spacing.X_SMALL,

      '+ p': {
        marginTop: Spacing.MEDIUM,
      },

      [MEDIA_QUERY_MOBILE_MAX]: {
        display: 'none',
      },
    },

    ul: {
      listStyle: 'none',
      margin: 0,
      padding: 0,

      [MEDIA_QUERY_TABLET_MIN]: {
        margin: `${Spacing.SMALL}px 0 0`,

        'li:not(:first-child)': {
          marginTop: Spacing.XX_SMALL,
        },
      },
    },

    'a, button': {
      color: ShadeColor.SHADE_1,

      [MEDIA_QUERY_MOBILE_MAX]: {
        color: CommonColor.TEXT,
        fontSize: FontSize.BODY_SMALL,
        lineHeight: `${Spacing.LARGE}px`,
        fontWeight: FontWeight.REGULAR,
      },

      ':hover': {
        [MEDIA_QUERY_TABLET_MIN]: {
          color: PrimaryColor.PURPLE_2,
        },
      },

      svg: {
        margin: `${Spacing.XXXX_SMALL}px ${Spacing.XXX_SMALL}px 0`,
        verticalAlign: 'top',
      },
    },
  },

  '> li:not(.left) nav': {
    [MEDIA_QUERY_TABLET_MIN]: {
      right: '50%',
    },

    [MEDIA_QUERY_DESKTOP_MIN]: {
      right: 0,
    },
  },
};

const menuToggle: Interpolation = {
  border: 0,
  boxSizing: 'content-box',
  cursor: 'pointer',
  marginRight: -Gutter.MOBILE,
  outline: 'none',
  padding: `${MENU_TOGGLE_LINE_WIDTH / 2 -
    MENU_TOGGLE_LINE_HEIGHT / 2 +
    MENU_TOGGLE_PADDING}px ${MENU_TOGGLE_PADDING}px`,
  position: 'relative',

  '&, span': {
    color: 'transparent',
    display: 'inline-block',
    fontSize: 0,
    lineHeight: 0,
  },

  [MEDIA_QUERY_TABLET_MIN]: {
    display: 'none',
  },

  'span, ::before, ::after': {
    backgroundColor: CommonColor.WHITE,
    height: MENU_TOGGLE_LINE_HEIGHT,
    transitionDuration: TRANSITION_DURATION,
    width: MENU_TOGGLE_LINE_WIDTH,
  },

  '::before, ::after': {
    content: '""',
    display: 'block',
    left: MENU_TOGGLE_PADDING,
    position: 'absolute',
  },

  '::before': {
    top: MENU_TOGGLE_LINE_OFFSET,
  },

  '::after': {
    bottom: MENU_TOGGLE_LINE_OFFSET,
  },

  '&.open': {
    span: {
      backgroundColor: 'transparent',
    },

    '::before, ::after': {
      width: MENU_TOGGLE_OPEN_LINE_WIDTH,
    },

    '::before': {
      transform: 'rotate(45deg) translate(2px, 9px)',
    },

    '::after': {
      transform: 'rotate(-45deg) translate(2px, -9px)',
    },
  },
};
