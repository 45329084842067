import { navigate, RouteComponentProps } from '@reach/router';
import { InvestmentBalance } from '@sargon/api-client';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { css, Interpolation } from 'emotion';
import { useIntercom } from 'helpers/contexts/intercom';
import { useNotifications } from 'helpers/contexts/notifications';
import {
  PortfolioKey,
  portfolioKeyToPrettyName,
  PORTFOLIOS,
} from 'helpers/portfolio';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BODY,
  BorderRadius,
  CommonColor,
  FontWeight,
  HEADER_HEIGHT,
  HEADING_2,
  HEADING_3,
  hex2rgba,
  IconSize,
  LineHeight,
  MEDIA_QUERY_MOBILE_MAX,
  META,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

import { ExternalRoutes, Routes } from './routes';

export const InvestmentChangePortfolio: FC<RouteComponentProps> = observer(
  (): JSX.Element | null => {
    const {
      sargonStore: {
        listInvestmentOptions,
        investmentOptions,
        futureCashAllocations,
        portfolio,
        updateFutureCashAllocations,
        listFutureCashAllocations,
        requestInvestmentRebalanceTransfer,
        listInvestmentsBalance,
      },
    } = useStores();
    const { handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const [investmentBalance, setInvestmentBalance] = useState<
      InvestmentBalance[]
    >([]);
    const notifications = useNotifications();
    const intercom = useIntercom();

    useEffect(() => {
      (async (): Promise<void> => {
        try {
          await listInvestmentOptions();
        } catch (e) {
          notifications.popToast({
            message: 'There was an error fetching investment options.',
            level: 'error',
          });
        }
        try {
          if (!futureCashAllocations.length) {
            await listFutureCashAllocations();
          }
        } catch (e) {
          notifications.popToast({
            message: 'There was an error determining your portfolio.',
            level: 'error',
          });
        }
        try {
          const balance = await listInvestmentsBalance();
          setInvestmentBalance(balance || []);
        } catch (e) {
          notifications.popToast({
            message: 'There was an error fetching your investment balance.',
            level: 'error',
          });
        }
      })();
    }, []);

    const otherInvestmentOption = useMemo(() => {
      if (!portfolio) {
        return undefined;
      }

      return investmentOptions
        .filter(
          option =>
            option.key !== portfolio.key && option.key !== PortfolioKey.CASH,
        )
        .shift();
    }, [portfolio, investmentOptions]);

    const portfolioKey = !!portfolio && portfolio.key;
    const otherPortfolioKey =
      !!otherInvestmentOption && otherInvestmentOption.key;

    if (!(portfolioKey || otherPortfolioKey)) {
      return <ErrorFallback />;
    }

    return (
      <ColumnLayout>
        <Column display="desktop-only" verticalCenter={true}>
          <Illustration type="rocket" />
        </Column>
        <Column verticalCenter={true}>
          <h1 className={css(title)}>Change investment option</h1>
          <form
            onSubmit={handleSubmit(async () => {
              setLoading(true);
              const { id, key } = otherInvestmentOption || {};
              const percent = 100;
              if (id && key) {
                try {
                  await updateFutureCashAllocations({
                    id,
                    key,
                    percent,
                  });
                  if (investmentBalance.length > 0) {
                    await requestInvestmentRebalanceTransfer({ id, percent });
                  }
                  notifications.popToast({
                    level: 'success',
                    message:
                      'Thanks, we’ve updated your investment option. Please allow up to five business days for this change to be reflected in your account.',
                  });
                } catch (error) {
                  notifications.popToast({
                    level: 'error',
                    message:
                      'Something went wrong while changing your investment option',
                    cta: {
                      message: 'Contact support',
                      action: intercom.pop,
                    },
                  });
                }
              }
              setLoading(false);
            })}
          >
            <div className={css(portfolioSelection, marginBottom)}>
              <span className={css(portfolioText)}>
                <h3>
                  {portfolioKey && portfolioKeyToPrettyName[portfolioKey]}
                </h3>
                <p>&nbsp;&mdash; currently selected</p>
              </span>
              <p>{portfolioKey && PORTFOLIOS[portfolioKey].description}</p>
              <a href={portfolioKey ? PORTFOLIOS[portfolioKey].link : '#'}>
                Find out more &rarr;
              </a>
            </div>

            <div className={css(portfolioSelection, marginBottom)}>
              <span className={css(portfolioText)}>
                <h3>
                  {otherPortfolioKey &&
                    portfolioKeyToPrettyName[otherPortfolioKey]}
                </h3>
              </span>
              <p>
                {otherPortfolioKey && PORTFOLIOS[otherPortfolioKey].description}
              </p>
              <a
                href={
                  otherPortfolioKey ? PORTFOLIOS[otherPortfolioKey].link : '#'
                }
              >
                Find out more &rarr;
              </a>
            </div>

            <div className={css(form, marginBottom)}>
              <h4 className={css(label)}>My new investment option</h4>
              <p>
                {otherPortfolioKey &&
                  portfolioKeyToPrettyName[otherPortfolioKey]}
              </p>
            </div>

            <div className={css(warningText)}>
              <AlertTriangle size={IconSize.MEDIUM} />
              <p>
                Switching investment options may incur certain fees and costs,
                including a buy/sell spread. Refer to the{' '}
                <a
                  href={ExternalRoutes.REFERENCE_GUIDE}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Reference Guide
                </a>{' '}
                for more information.
                <br />
                <br />
                If a switch request is received prior to 2:30pm AEST on a
                business day in Brisbane, you will receive the unit price for
                that day. If a switch is received after 2:30pm AEST on a
                business day in Brisbane it will be effective the following
                business day. You’ll generally be able to see your new
                investment option within seven days.
              </p>
            </div>

            <div className={css(buttons)}>
              <Button
                variant="secondary"
                margin={{ marginRight: Spacing.SMALL }}
                onClick={(): void => {
                  navigate(Routes.DASHBOARD);
                }}
                trackingProperties={{
                  name: 'investment_change_portfolio_back',
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                loading={loading}
                disabled={investmentBalance === undefined}
                trackingProperties={{
                  name: 'investment_change_portfolio_submit',
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Column>
      </ColumnLayout>
    );
  },
);

const ErrorFallback = (): JSX.Element => {
  const intercom = useIntercom();
  return (
    <ColumnLayout>
      <Column display="desktop-only" verticalCenter={true}>
        <Illustration type="lost" />
      </Column>

      <Column verticalCenter={true}>
        <div className={css(columnContainer)}>
          <h1 className={css(heading)}>Change investment option</h1>
          <p className={css(copy)}>
            Something went wrong and we don&apos;t have enough information to
            change your investment option at this time.
          </p>
          <Button
            onClick={intercom.pop}
            trackingProperties={{ name: 'investment_change_portfolio_support' }}
          >
            Contact support
          </Button>
        </div>
      </Column>
    </ColumnLayout>
  );
};

const title: Interpolation = {
  textAlign: 'left',

  [MEDIA_QUERY_MOBILE_MAX]: {
    ...HEADING_2,
    marginBottom: Spacing.MEDIUM,
  },
};

const warningText: Interpolation = {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridColumnGap: Spacing.SMALL,
  color: PrimaryColor.GOLD_100,
  backgroundColor: hex2rgba(PrimaryColor.GOLD_100, 0.1),
  padding: Spacing.SMALL,
  paddingRight: Spacing.MEDIUM,
  borderRadius: BorderRadius.SMALL,

  p: {
    margin: 0,
    lineHeight: LineHeight.BODY,
  },
};

const marginBottom: Interpolation = {
  marginBottom: Spacing.LARGE,
};

const portfolioText: Interpolation = {
  display: 'flex',
  alignItems: 'baseline',
};

const portfolioSelection: Interpolation = {
  h3: {
    marginTop: 0,
  },

  p: {
    marginTop: 0,
  },
};

const form: Interpolation = {
  backgroundColor: hex2rgba(CommonColor.WHITE, 0.05),
  padding: Spacing.SMALL,
  paddingRight: Spacing.MEDIUM,
  borderRadius: BorderRadius.SMALL,

  p: {
    ...HEADING_3,
    color: PrimaryColor.PURPLE_1,
    fontWeight: FontWeight.BOLD,
  },
};

const label: Interpolation = {
  ...META,
  color: ShadeColor.SHADE_2,
  textTransform: 'uppercase',
};

const buttons: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: Spacing.LARGE,
};

const copy: Interpolation = {
  ...BODY,
  marginBottom: Spacing.MEDIUM,
  textAlign: 'center',
};

const heading: Interpolation = {
  textAlign: 'center',
};

const columnContainer: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  justifyContent: 'center',
};
