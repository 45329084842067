import { navigate, RouteComponentProps } from '@reach/router';
import { FundSearchFund, RolloverStatusDetail } from '@sargon/api-client';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Column, ColumnLayout } from 'components/column-layout';
import { Input } from 'components/input';
import { Decimal } from 'decimal.js';
import { css, Interpolation } from 'emotion';
import { useIntercom } from 'helpers/contexts/intercom';
import { useNotifications } from 'helpers/contexts/notifications';
import { formatCurrency } from 'helpers/format';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BorderWidth,
  Breakpoint,
  CommonColor,
  CONSENT_BOX,
  IconSize,
  Spacing,
} from 'styles';

import { Routes } from './routes';

const validation = {
  amount: {
    required: 'Amount is required',
  },
  agree: {
    required: 'Consent is required',
  },
};

export const TransferFunds: FC<RouteComponentProps> = observer(
  (): JSX.Element | null => {
    const notifications = useNotifications();
    const intercom = useIntercom();
    const [loading, setLoading] = useState(false);
    const [transferUnclaimed, setTransferUnclaimed] = useState(false);
    const [fundTransfer, setFundTransfer] = useState<{
      [usi: string]: boolean;
    }>({});

    const {
      sargonStore: {
        createManualRolloverRequest,
        fundSearchResults,
        rolloverRequests,
      },
    } = useStores();

    const { funds, unclaimedMoneys } = fundSearchResults || {};

    const { register, errors, handleSubmit } = useForm<{
      agree: boolean;
      rolloverFund: { [key: string]: boolean };
      rolloverAmount: { [key: string]: number };
      rolloverUnclaimedMoneys: boolean;
    }>({
      submitFocusError: true,
    });

    useEffect(() => {
      (async (): Promise<void> => {
        if (fundSearchResults === undefined) {
          await navigate(Routes.TRANSFER_SEARCH);
        }
      })();
    }, [fundSearchResults]);

    const isTransferring =
      Object.values(fundTransfer).some(value => !!value) || transferUnclaimed;

    const hasConsentedFund = (
      checkFundABN: string | undefined,
      checkAccountNumber: string | undefined,
    ): boolean =>
      !!rolloverRequests?.funds?.some(
        ({ fundABN, accountNumber }) =>
          fundABN === checkFundABN && accountNumber === checkAccountNumber,
      );

    return (
      <ColumnLayout>
        <Column>
          <h1 className={css(centeredHeading)}>
            Want to consolidate your super into Spaceship?
          </h1>

          <form
            onSubmit={handleSubmit(
              async ({
                rolloverFund,
                rolloverAmount,
                rolloverUnclaimedMoneys,
              }) => {
                setLoading(true);
                try {
                  const selectedFunds: RolloverStatusDetail[] = [];
                  if (rolloverFund) {
                    Object.entries(rolloverFund)
                      .filter(([, isTransferring]) => isTransferring)
                      .forEach(([key]) => {
                        const fund = funds?.filter(
                          ({ fundABN, accountNumber }) =>
                            `${fundABN}-${accountNumber}` === key,
                        )[0];

                        if (!fund) {
                          return;
                        }
                        const amount = rolloverAmount && rolloverAmount[key];

                        let transferAmount = undefined;
                        if (
                          amount &&
                          fund.accountBalance?.amount &&
                          fund.accountBalance?.currency
                        ) {
                          const selectedAmount = new Decimal(amount).mul(100);

                          transferAmount =
                            selectedAmount.comparedTo(
                              fund.accountBalance.amount,
                            ) !== 0
                              ? {
                                  amount: selectedAmount.toNumber(),
                                  currency: fund.accountBalance.currency,
                                }
                              : undefined;
                        }

                        selectedFunds.push({
                          fundABN: fund.fundABN,
                          uniqueSuperIdentifier: fund.uniqueSuperIdentifier,
                          accountNumber: fund.accountNumber,
                          transferAmount,
                        });
                      });
                  }
                  await createManualRolloverRequest({
                    rolloverUnclaimedMoneys: !!rolloverUnclaimedMoneys,
                    funds: selectedFunds,
                  });
                  await navigate(Routes.TRANSFER_SUCCCESS);
                } catch (error) {
                  if (error.message) {
                    notifications.popToast(error.message);
                  } else {
                    notifications.popToast({
                      level: 'error',
                      message: 'Something went wrong transferring your funds',
                      cta: {
                        message: 'Contact support',
                        action: intercom.pop,
                      },
                    });
                  }
                }
                setLoading(false);
              },
            )}
          >
            <p>
              Please note, the funds and their balances come directly from the
              ATO and can be up to twelve months out of date. When you transfer
              your account over to Spaceship, the full
              balance&nbsp;will&nbsp;come&nbsp;across.
            </p>

            <p>
              Consolidating your super may help reduce the total fees you pay
              and can make it easier to stay in control of your super money.
            </p>

            <p>
              You should consider how rolling over your existing super funds
              will affect you. Things to think about before deciding to
              consolidate include:
            </p>

            <ul className={css(list)}>
              <li>
                How the fees, risks and benefits of your other super funds
                compare to the fees, risks and benefits of Spaceship Super.
              </li>
              <li>
                By rolling over the full value of an existing super account to
                Spaceship Super, your existing super account will be closed and
                you may lose some benefits, like life insurance.
              </li>
              <li>
                Spaceship Super does not currently offer Life, TPD or Income
                Protection insurance.
              </li>
              <li>What investment or tax implications may arise for you.</li>
            </ul>

            <p>
              Talking to a professional financial adviser can help you make this
              decision.
            </p>
            <hr />

            <div className={css(options)}>
              {funds &&
                funds.map(
                  (
                    {
                      friendlyFundName,
                      insurance,
                      definedBenefit,
                      accountNumber,
                      activityStatus,
                      uniqueSuperIdentifier,
                      accountBalance,
                      canRollover,
                      rolloverStatus,
                      fundABN,
                      fundAcceptGovernmentContributions,
                      inwardRollover,
                    },
                    index,
                  ) => {
                    const balance = new Decimal(
                      accountBalance?.amount || 0,
                    ).dividedBy(100);

                    const canTransfer =
                      canRollover &&
                      rolloverStatus !==
                        FundSearchFund.RolloverStatusEnum.OwnFund;

                    const key = `${fundABN}-${accountNumber}`;
                    const fundKey = `rolloverFund[${key}]`;
                    const amountKey = `rolloverAmount[${key}]`;
                    const inputError = errors[amountKey];
                    const consented = hasConsentedFund(fundABN, accountNumber);

                    const fundName =
                      uniqueSuperIdentifier === '34300938877005'
                        ? 'Spaceship'
                        : friendlyFundName;

                    const getCheckedComponent = (
                      isChecked: boolean | undefined,
                    ): React.ReactElement =>
                      isChecked ? (
                        <CheckCircle
                          color={CommonColor.GREEN}
                          size={IconSize.SMALL}
                        />
                      ) : (
                        <XCircle
                          color={CommonColor.RED}
                          size={IconSize.SMALL}
                        />
                      );

                    return (
                      <div key={index} className="option">
                        <div className="info">
                          <h3>{fundName}</h3>

                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Insurance
                            </label>
                            <span>{getCheckedComponent(insurance)}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Defined benefits
                            </label>
                            <span>{getCheckedComponent(definedBenefit)}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Accepts government contributions
                            </label>
                            <span>
                              {getCheckedComponent(
                                fundAcceptGovernmentContributions,
                              )}
                            </span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Accepts inward rollovers
                            </label>
                            <span>{getCheckedComponent(inwardRollover)}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Account number
                            </label>
                            <span>{accountNumber}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>
                              Activity status
                            </label>
                            <span>{activityStatus}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>ABN</label>
                            <span>{fundABN}</span>
                          </div>
                          <div className={css(infoDetail)}>
                            <label className={css(infoDetailLabel)}>USI</label>
                            <span>{uniqueSuperIdentifier}</span>
                          </div>
                        </div>
                        <div className="actions">
                          {!consented && canTransfer && (
                            <Checkbox
                              name={fundKey}
                              ref={register}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ): void => {
                                setFundTransfer({
                                  ...fundTransfer,
                                  [key]: event.target.checked,
                                });
                              }}
                            >
                              <p className={css(boldLabel)}>
                                Rollover to Spaceship
                              </p>
                            </Checkbox>
                          )}
                          {!consented &&
                          canTransfer &&
                          balance.greaterThan(0) &&
                          fundTransfer[key] ? (
                            <div>
                              <Input
                                type="number"
                                step=".01"
                                name={amountKey}
                                format="currency"
                                placeholder="Amount"
                                ref={register({
                                  ...validation.amount,
                                  validate: (
                                    amount: string,
                                  ): string | boolean => {
                                    const enteredAmount = new Decimal(amount);
                                    if (enteredAmount.comparedTo(balance) > 0) {
                                      return `Amount cannot be more than ${formatCurrency(
                                        balance.toNumber(),
                                      )}`;
                                    }
                                    return true;
                                  },
                                })}
                                errorMessage={inputError && inputError.message}
                              />
                              <p>{formatCurrency(balance.toNumber())}</p>
                            </div>
                          ) : (
                            <p>{formatCurrency(balance.toNumber())}</p>
                          )}
                          {definedBenefit && (
                            <div
                              className={css(CONSENT_BOX, {
                                marginTop: Spacing.SMALL,
                                marginBottom: Spacing.X_SMALL,
                              })}
                            >
                              <p>
                                This account is a defined benefit account and
                                cannot be consolidated online, please{' '}
                                <a onClick={intercom.pop}>contact us</a> for
                                more information to consolidate this account.
                              </p>
                            </div>
                          )}
                          {consented && (
                            <div
                              className={css(CONSENT_BOX, {
                                marginTop: Spacing.SMALL,
                                marginBottom: Spacing.X_SMALL,
                              })}
                            >
                              <p>
                                You already initiated a rollover of your funds.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
            </div>

            {unclaimedMoneys && unclaimedMoneys.length > 0 && (
              <div className={css(options)}>
                <h2 className={css(centeredHeading)}>Unclaimed funds</h2>

                <div className="option">
                  <div className="row">
                    <div className="info">&nbsp;</div>
                    <div className="actions">
                      {!rolloverRequests?.unclaimedMoney ? (
                        <Checkbox
                          name="rolloverUnclaimedMoneys"
                          ref={register}
                          errorMessage={
                            errors.rolloverUnclaimedMoneys &&
                            errors.rolloverUnclaimedMoneys.message
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            setTransferUnclaimed(event.target.checked);
                          }}
                          margin={{ marginTop: Spacing.MEDIUM }}
                        >
                          <p className={css(boldLabel)}>
                            Rollover to Spaceship
                          </p>
                        </Checkbox>
                      ) : (
                        <div
                          className={css(CONSENT_BOX, {
                            marginTop: Spacing.SMALL,
                            marginBottom: Spacing.X_SMALL,
                          })}
                        >
                          <p>
                            You already initiated a transfer of your unclaimed
                            funds.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {unclaimedMoneys.map(({ typeOfFund, amount }) => (
                    <div key={typeOfFund} className="row">
                      <div className="info">
                        <h3>{typeOfFund}</h3>
                      </div>
                      <div className="actions">
                        <p>
                          {formatCurrency(
                            (amount && amount.amount / 100) || '',
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {isTransferring && (
              <div>
                <hr />
                <h2 className={css(centeredHeading)}>Rollover Terms</h2>

                <ul className={css(list)}>
                  <li>
                    You agree to rollover super monies currently held with the
                    super funds you have selected (above) into your Spaceship
                    Super account. You authorise the trustee of the Spaceship
                    Super fund to rollover the nominated amount for those super
                    accounts, which may be the total balance or a part that
                    balance.
                  </li>
                  <li>
                    You understand that by selecting a nominated amount, only
                    that amount will rollover into your Spaceship Super account
                    and the remaining balance will be retained in the super
                    account from which the amount was rolled over. In the event
                    that the balance in your other super account is less than
                    your nominated amount, your rollover request may not
                    proceed. We will let you know if this happens.
                  </li>
                  <li>
                    You understand that rolling over your other super accounts
                    to Spaceship Super will not change future super
                    contributions made by your employer. If you want your
                    employer to make future employer contributions to Spaceship
                    Super, you will need to tell them to do so – you can do this
                    by completing a Super Choice form.
                  </li>
                  <li>
                    You understand you may ask the trustee of Spaceship Super
                    for information about any fees or charges that may apply to
                    a rollover, or any other information about the effect that
                    the rollover may have on your benefits, and confirm that do
                    not require such further information at this point.
                  </li>
                  <li>
                    You consent to your TFN, and any other information held by
                    Spaceship, being disclosed and used for the purposes of
                    consolidating your super accounts and rolling your super
                    monies held by other super providers into your Spaceship
                    Super account, and meeting any requirements set by the
                    superannuation law, the ATO or other regulatory authorities
                    in relation to consolidating your super accounts;
                  </li>
                  <li>
                    You understand that your consent provided in relation to the
                    use, storage and disclosure of your TFN when you provided
                    Spaceship with your TFN details continues to apply
                    unaffected; and
                  </li>
                  <li>
                    You understand that the consents and authorisations that you
                    have provided above are in force and valid for the duration
                    of your membership with Spaceship, and that you may revoke
                    your consent at any time by contacting Spaceship.
                  </li>
                </ul>
                <hr />
                <Checkbox
                  name="agree"
                  ref={register(validation.agree)}
                  errorMessage={errors.agree && errors.agree.message}
                >
                  <p className={css(termsCopy)}>
                    I agree to rollover the nominated balances of the super
                    accounts that I have selected into my Spaceship Super
                    account and I have read and understood the Rollover Terms.
                    <br />
                    <br />
                    I understand that rolling over the full balance of my super
                    accounts will result in the closure of my account with the
                    selected super funds and this may impact other benefits that
                    I may have with those super accounts, such as life
                    insurance, and that Spaceship does not currently offer Life,
                    TPD or Income Protection insurance.
                    <br />
                    <br />I declare that all of the details in this application
                    form are true and correct. I understand that this
                    application is subject to the Spaceship{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className={css(termLink)}
                      href="https://www.spaceship.com.au/important-documents/"
                    >
                      Product Disclosure Statement
                    </a>
                    ,{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className={css(termLink)}
                      href="https://www.spaceship.com.au/important-documents/"
                    >
                      Reference Guide
                    </a>
                    ,{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.spaceship.com.au/privacy-policy/"
                      className={css(termLink)}
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.spaceship.com.au/app-terms/"
                      className={css(termLink)}
                    >
                      Membership Terms and Conditions
                    </a>{' '}
                    which I have read, understood and accepted.
                  </p>
                </Checkbox>
              </div>
            )}

            <div className={css(buttons)}>
              <Button
                variant="secondary"
                onClick={(): void => {
                  navigate(Routes.DASHBOARD);
                }}
                trackingProperties={{
                  name: 'transfer_funds_skip',
                }}
              >
                Skip
              </Button>
              <div className={css(buttonSpacer)}></div>
              <Button
                type="submit"
                disabled={!isTransferring}
                loading={loading}
                trackingProperties={{
                  name: 'transfer_funds_submit',
                }}
              >
                {isTransferring ? 'Consolidate my super' : 'No funds selected'}
              </Button>
            </div>
          </form>
        </Column>
      </ColumnLayout>
    );
  },
);

const buttonSpacer: Interpolation = {
  width: Spacing.MEDIUM,
  height: Spacing.MEDIUM,
};

const buttons: Interpolation = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: Spacing.LARGE,
  marginBottom: Spacing.LARGE,
  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    flexDirection: 'column',
  },
};

const options: Interpolation = {
  '.option': {
    display: 'flex',
    justifyContent: 'row',
    flexWrap: 'wrap',

    flexDirection: 'row',
    [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
      flexDirection: 'column',
    },

    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderColor: CommonColor.WHITE,
    borderWidth: BorderWidth.SMALL,
    marginBottom: Spacing.MEDIUM,
  },
  '.row': {
    width: '100%',
    display: 'flex',
    justifyContent: 'row',

    '.info': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '.actions': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '.info': {
    flex: 0.6,
    paddingLeft: Spacing.MEDIUM,
    paddingRight: Spacing.MEDIUM,
    paddingTop: Spacing.SMALL,
    paddingBottom: Spacing.SMALL,
  },
  '.actions': {
    flex: 0.4,
    paddingLeft: Spacing.MEDIUM,
    paddingRight: Spacing.MEDIUM,
    paddingTop: Spacing.SMALL,
    paddingBottom: Spacing.SMALL,
  },
};

const termsCopy: Interpolation = {
  margin: 0,
};

const termLink: Interpolation = {
  color: CommonColor.WHITE,
  textDecoration: 'underline',
};

const infoDetail: Interpolation = {
  display: 'flex',
  paddingBottom: Spacing.XX_SMALL,
};

const infoDetailLabel: Interpolation = {
  flexGrow: 1,
};

const centeredHeading: Interpolation = {
  textAlign: 'center',
};

const boldLabel: Interpolation = {
  margin: 0,
  fontWeight: 'bold',
  color: CommonColor.WHITE,
};

const list: Interpolation = {
  ':last-child': {
    marginBottom: 0,
  },
  li: {
    marginBottom: Spacing.X_SMALL,
  },
};
