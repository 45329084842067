import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { SpaceshipAppPromotionWithBenefits } from 'components/spaceship-app-promotion';
import { css, Interpolation } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { Spacing } from 'styles';

import { Routes } from './routes';

export const DownloadApp: React.FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    return (
      <ColumnLayout>
        <Column verticalCenter={true}>
          <SpaceshipAppPromotionWithBenefits variant="onboarding" />
          <div className={css(buttonContainer)}>
            <Button
              trackingProperties={{ name: 'download-app-view-dashboard' }}
              onClick={(): void => {
                navigate(Routes.DASHBOARD);
              }}
            >
              View my dashboard
            </Button>
          </div>
        </Column>
      </ColumnLayout>
    );
  },
);

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  padding: Spacing.X_LARGE,
};
