export enum Routes {
  DASHBOARD = '/',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  RESET_PASSWORD_UPDATE = '/reset-password/update',
  SIGNUP = '/signup',
  SIGNUP_ADDRESS = '/signup/address',
  SIGNUP_TFN = '/signup/tax-file-number',
  SIGNUP_PASSWORD = '/signup/password',
  ONBOARDING_INVESTMENT_OPTION = '/onboarding/investment-option',
  TRANSFER_SEARCH = '/transfer',
  TRANSFER_FUNDS = '/transfer/funds',
  TRANSFER_NO_FUNDS = '/transfer/no-funds',
  TRANSFER_SUCCCESS = '/transfer/success',
  INVESTMENT_CHANGE_PORTFOLIO = '/investment/change-portfolio',
  TRANSACTIONS = '/transactions',
  BENEFICIARIES = '/beneficiaries',
  ACCOUNT_CONTACT = '/account/contact',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_TFN = '/account/tax-file-number',
  DOWNLOAD_APP = '/download-app',
}

export enum ExternalRoutes {
  SUPER_MARKETING_ROOT = 'https://www.spaceship.com.au/super/',
  ABOUT = 'https://www.spaceship.com.au/about/',
  CONTACT = 'https://help.spaceship.com.au/en/',
  CAREERS = 'https://www.spaceship.com.au/careers/',
  SUPER_PRODUCTS = 'https://www.spaceship.com.au/super/',
  SUPER_GROWTHX_OVERVIEW = 'https://www.spaceship.com.au/super/growthx/',
  SUPER_GLOBAL_INDEX_OVERVIEW = 'https://www.spaceship.com.au/super/global-index/',
  LEARN = 'https://www.spaceship.com.au/learn/',
  LEARN_SUPER = 'https://www.spaceship.com.au/learn/tag/superannuation/',
  LEARN_FINANCE = 'https://www.spaceship.com.au/learn/tag/economics/',
  LEARN_PERSONAL_FINANCE = 'https://www.spaceship.com.au/learn/tag/personal-finance/',
  LEARN_ECONOMICS = 'https://www.spaceship.com.au/learn/tag/economics/',
  ATO_LOST_TFN = 'https://www.ato.gov.au/Individuals/Tax-file-number/Lost-or-stolen-TFN',

  PRODUCT_DISCLOSURE_STATEMENT = 'https://cdn.sargon.cloud/SS/c5ce9248-456c-4fdc-8da3-047e43b5c69d/Product%20Disclosure%20Statement.pdf',
  FINANCIAL_SERVICES_GUIDE = 'https://cdn.sargon.cloud/SS/c463d965-1c72-402b-8b2a-05b023deb17c/Financial%20Services%20Guide.pdf',
  REFERENCE_GUIDE = 'https://cdn.sargon.cloud/SS/dfb75128-b5e1-48c0-ab8c-6301e25e0ca5/Reference%20Guide.pdf',
  PRIVACY_POLICY = 'https://www.spaceship.com.au/privacy-policy/',
  TERMS_AND_CONDITIONS = 'https://www.spaceship.com.au/app-terms/',

  IOS_APP_STORE = 'https://spaceship.app.link/azRjbH71z9',
  GOOGLE_PLAY = 'https://spaceship.app.link/azRjbH71z9',
}
