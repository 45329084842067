import { css, cx, Interpolation } from 'emotion';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  Info,
  X,
} from 'react-feather';
import {
  BorderRadius,
  BOX_SHADOW_TERTIARY,
  Breakpoint,
  CommonColor,
  FontSize,
  FontWeight,
  Gutter,
  IconSize,
  LineHeight,
  PrimaryColor,
  ShadeColor,
  Spacing,
  ZIndex,
} from 'styles';

const TOAST_DURATION_MS = 5000;

type ToastLevel = 'warning' | 'error' | 'success' | 'info';

interface NotificationProps {
  message: string;
  cta?: {
    message: string;
    action(): void;
  };
}

interface ToastProps extends NotificationProps {
  level: ToastLevel;
}

interface Notifications {
  popToast(props: ToastProps): void;
  popBanner(props: NotificationProps): void;
}

const NotificationsContext = createContext<Notifications>({
  popToast: () => {},
  popBanner: () => {},
});

export const NotificationsProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [activeBanner, setBanner] = useState<NotificationProps | null>();
  const [toasts, setToasts] = useState<{
    [message: string]: ToastProps;
  }>({});

  const popToast = (toast: ToastProps): void => {
    if (!(toasts[toast.message] || activeBanner)) {
      setToasts(toasts => ({ ...toasts, [toast.message]: toast }));
      setTimeout(
        () =>
          setToasts(({ [toast.message]: deleted, ...newToasts }) => newToasts),
        TOAST_DURATION_MS,
      );
    }
  };

  const popBanner = (banner: NotificationProps): void => {
    setBanner(banner);
    // Banner should dismiss all active toasts
    setToasts({});
  };

  const hideBanner = (): void => setBanner(null);

  return (
    <NotificationsContext.Provider value={{ popToast, popBanner }}>
      {children}
      <div className={css(toastContainer)}>
        {Object.entries(toasts).map(([message, { cta, level }]) => (
          <div
            key={message}
            className={cx(css(toast), {
              wrap: !!(cta && cta.message.length > 16),
            })}
          >
            <div className={css(toastContent)}>
              <ToastIcon
                className={css(toastIcon)}
                level={level}
                size={Spacing.MEDIUM}
              />
              {message}
            </div>
            {cta && (
              <button className={css(commonCta)} onClick={cta.action}>
                {cta.message}
              </button>
            )}
          </div>
        ))}
      </div>
      {activeBanner && (
        <div className={css(banner)}>
          <div className={css(bannerContent)}>
            <AlertTriangle
              className={css(bannerIcon)}
              size={Spacing.MEDIUM}
              color={ShadeColor.SHADE_1}
            />

            <div className={css(bannerMessageCta)}>
              <div className={css(bannerMessage)}>{activeBanner.message}</div>

              {activeBanner.cta && (
                <button
                  className={css(commonCta)}
                  onClick={activeBanner.cta.action}
                >
                  {activeBanner.cta.message}
                </button>
              )}
            </div>
          </div>

          <div onClick={hideBanner} className={css(bannerClose)}>
            <button>
              <X size={Spacing.MEDIUM} color={ShadeColor.SHADE_1} />
            </button>
          </div>
        </div>
      )}
    </NotificationsContext.Provider>
  );
};

const ToastIcon = ({
  level,
  ...props
}: {
  level: ToastLevel;
  className: string;
  size: Spacing;
}): JSX.Element => {
  switch (level) {
    case 'error':
      return <AlertOctagon {...props} color={PrimaryColor.RED_100} />;
    case 'warning':
      return <AlertTriangle {...props} color={PrimaryColor.GOLD_100} />;
    case 'success':
      return <CheckCircle {...props} color={PrimaryColor.MINT_050} />;
    default:
      return <Info {...props} color={PrimaryColor.PINK_080} />;
  }
};

export const useNotifications = (): Notifications =>
  useContext(NotificationsContext);

const MAX_TOAST_WIDTH = 420;
const MAX_BANNER_WIDTH = 960;
const MIN_HEIGHT = 56;
const NOTIFICATION_SPACING = Spacing.SMALL;

const bannerContent: Interpolation = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

const bannerMessageCta: Interpolation = {
  display: 'flex',

  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    flexDirection: 'column',
  },
};

const bannerMessage: Interpolation = {
  marginRight: Spacing.X_SMALL,
};

const commonCta: Interpolation = {
  textDecoration: 'underline',
  cursor: 'pointer',
};

const containerSizing: Interpolation = {
  zIndex: ZIndex.NOTIFICATION,
  width: `calc(100% - ${Gutter.DESKTOP * 2}px)`,
  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    width: `calc(100% - ${Gutter.MOBILE * 2}px)`,
  },
};

const toastContainer: Interpolation = {
  ...containerSizing,
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
  top: Spacing.LARGE,
  maxWidth: MAX_TOAST_WIDTH,
};

const toastContent: Interpolation = {
  display: 'flex',
  paddingRight: Spacing.XX_SMALL,
};

const toastIcon: Interpolation = {
  flexShrink: 0,
  paddingRight: Spacing.X_SMALL,
};

const bannerIcon: Interpolation = {
  paddingRight: Spacing.XX_SMALL,
  minWidth: IconSize.MEDIUM,
  minHeight: IconSize.MEDIUM,

  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    alignSelf: 'flex-start',
  },
};

const bannerClose: Interpolation = {
  cursor: 'pointer',
  lineHeight: 0,
  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    alignSelf: 'flex-start',
  },
};

const notification: Interpolation = {
  boxSizing: 'border-box',
  boxShadow: BOX_SHADOW_TERTIARY,
  lineHeight: LineHeight.BODY_SMALL,
  borderRadius: BorderRadius.SMALL,
  minHeight: MIN_HEIGHT,
  fontSize: FontSize.BODY_SMALL,
  fontWeight: FontWeight.SEMI_BOLD,
  padding: NOTIFICATION_SPACING,
  button: {
    textAlign: 'inherit',
    color: 'inherit',
    fontWeight: 'inherit',
    height: 'max-content',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
};

const toast: Interpolation = {
  ...notification,
  display: 'flex',
  '&.wrap': {
    flexDirection: 'column',
    button: {
      alignSelf: 'flex-end',
      marginTop: Spacing.XX_SMALL,
    },
  },
  justifyContent: 'space-between',
  marginBottom: Spacing.SMALL,
  backgroundColor: ShadeColor.SHADE_5,
  color: CommonColor.BLACK,
};

const banner: Interpolation = {
  ...notification,
  ...containerSizing,
  position: 'fixed',
  display: 'flex',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: Spacing.LARGE,
  backgroundColor: PrimaryColor.GOLD_050,
  color: ShadeColor.SHADE_1,
  maxWidth: MAX_BANNER_WIDTH,
  [`@media(max-width: ${Breakpoint.MOBILE}px)`]: {
    justifyContent: 'flex-start',
  },
};
