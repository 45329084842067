import { Location, navigate, RouteComponentProps, Router } from '@reach/router';
import { initAnalytics } from 'helpers/analytics';
import { IntercomProvider } from 'helpers/contexts/intercom';
import {
  NotificationsProvider,
  useNotifications,
} from 'helpers/contexts/notifications';
import { ResetPasswordEmailProvider } from 'helpers/contexts/password-reset';
import { SignupContextProvider } from 'helpers/contexts/signup';
import { initMonitoring } from 'helpers/monitoring';
import { observer } from 'mobx-react';
import { AccountAddress } from 'pages/account-address';
import { AccountContact } from 'pages/account-contact';
import { TaxFileNumber } from 'pages/account-tfn';
import { Beneficiaries } from 'pages/beneficiaries';
import { Dashboard } from 'pages/dashboard';
import { DownloadApp } from 'pages/download-app';
import { InvestmentChangePortfolio } from 'pages/investment-change-portfolio';
import { Login } from 'pages/login';
import { OnboardingInvestmentOption } from 'pages/onboarding-investment-option';
import { PageNotFound } from 'pages/page-not-found';
import { ResetPassword } from 'pages/reset-password';
import { ResetPasswordUpdate } from 'pages/reset-password-update';
import { Routes } from 'pages/routes';
import { Signup } from 'pages/signup';
import { SignupAddress } from 'pages/signup-address';
import { SignupPassword } from 'pages/signup-password';
import { SignupTaxFileNumber } from 'pages/signup-tfn';
import { Transactions } from 'pages/transactions';
import { TransferFunds } from 'pages/transfer-funds';
import { TransferNoFunds } from 'pages/transfer-no-funds';
import { TransferSearch } from 'pages/transfer-search';
import { TransferSuccess } from 'pages/transfer-success';
import React, { useEffect } from 'react';
import { useStores } from 'stores';
import { initGlobalStyles } from 'styles';

import { Header } from './header';
import { NavigationTracking } from './navigation-tracking';
import { OtpModal } from './otp-modal';

try {
  initMonitoring();
  initAnalytics();
} catch (e) {
  console.error(e);
}

initGlobalStyles();

export const App = observer(
  (): JSX.Element => (
    <NotificationsProvider>
      <IntercomProvider>
        <OtpModal />
        <NavigationTracking />
        <Location>
          {({ location: { pathname } }): JSX.Element => (
            <Header currentPath={pathname} />
          )}
        </Location>
        <Router>
          <Login path="/login" />
          <ResetPasswordRoutes path="/reset-password">
            <ResetPassword path="/" default={true} />
            <ResetPasswordUpdate path="/update" />
          </ResetPasswordRoutes>
          <SignupRoutes path="/signup">
            <Signup path="/" default={true} />
            <SignupAddress path="/address" />
            <SignupTaxFileNumber path="/tax-file-number" />
            <SignupPassword path="/password" />
          </SignupRoutes>
          <AuthenticatedRoutes path="/">
            <PageNotFound default={true} />
            <NestedRoutes path="/onboarding">
              <OnboardingInvestmentOption path="/investment-option" />
              <PageNotFound default={true} />
            </NestedRoutes>
            <NestedRoutes path="/transfer">
              <TransferSearch path="/" />
              <TransferFunds path="/funds" />
              <TransferNoFunds path="/no-funds" />
              <TransferSuccess path="/success" />
              <PageNotFound default={true} />
            </NestedRoutes>
            <Dashboard path="/" />
            <Transactions path="/transactions" />
            <NestedRoutes path="/account">
              <AccountContact path="/contact" />
              <AccountAddress path="/address" />
              <TaxFileNumber path="/tax-file-number" />
              <PageNotFound default={true} />
            </NestedRoutes>
            <NestedRoutes path="/investment">
              <InvestmentChangePortfolio path="/change-portfolio" />
              <PageNotFound default={true} />
            </NestedRoutes>
            <Beneficiaries path="/beneficiaries" />
            <DownloadApp path="/download-app" />
          </AuthenticatedRoutes>
          <PageNotFound default={true} />
        </Router>
      </IntercomProvider>
    </NotificationsProvider>
  ),
);

type NestedProps = RouteComponentProps & { children: JSX.Element[] };

const NestedRoutes = ({ children }: NestedProps): JSX.Element => (
  <div>{children}</div>
);

const ResetPasswordRoutes = ({ children }: NestedProps): JSX.Element => {
  const {
    sargonStore: { loginState },
  } = useStores();

  useEffect(() => {
    if (loginState === 'logged-in') {
      navigate(Routes.DASHBOARD);
    }
  }, [loginState]);

  return <ResetPasswordEmailProvider>{children}</ResetPasswordEmailProvider>;
};

const SignupRoutes = ({ children }: NestedProps): JSX.Element => (
  <SignupContextProvider>{children}</SignupContextProvider>
);

const AuthenticatedRoutes = observer(
  ({ children }: NestedProps): JSX.Element | null => {
    const {
      sargonStore: { member, loginState, getMember },
    } = useStores();

    const { popToast } = useNotifications();

    useEffect(() => {
      if (loginState === 'logged-out') {
        navigate(Routes.LOGIN);
      }

      if (loginState === 'logged-in') {
        (async (): Promise<void> => {
          try {
            if (!member.id) {
              await getMember();
            }
          } catch (e) {
            popToast({
              message: `There was an error fetching your member record.`,
              level: 'error',
            });
          }
        })();
      }
    }, [loginState]);

    return loginState === 'logged-in' ? <div>{children}</div> : null;
  },
);
