import { navigate, RouteComponentProps } from '@reach/router';
import { MemberCreate } from '@sargon/api-client';
import aaron from 'assets/images/aaron.jpg';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { STATES_DROPDOWN_OPTONS } from 'helpers/constants';
import { useSignupContext } from 'helpers/contexts/signup';
import { asciiStringValidate, commonValidationRules } from 'helpers/validation';
import React, { FC, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { INPUT_MARGIN, Spacing } from 'styles';

import { Routes } from './routes';

export const SignupAddress: FC<RouteComponentProps> = (): JSX.Element => {
  const [member, setMember] = useSignupContext();

  useEffect(() => {
    if (member && !member.givenName) {
      navigate(Routes.SIGNUP);
    }
  }, [member]);

  return (
    <ColumnLayout>
      <Column display="desktop-only" verticalCenter={true}>
        <div className={css(illustration)}>
          <img src={aaron} alt="Moustachioed man" />
          <blockquote>
            I really like Spaceship because they don&apos;t use jargon and make
            things easy to understand. I feel like I&apos;m actually learning
            about investing.
          </blockquote>
          <p className={css(quoted)}>&mdash; Aaron</p>
        </div>
      </Column>
      <Column verticalCenter={true}>
        <h1>A few more details</h1>
        {member !== undefined && (
          <SignupForm member={member} setMember={setMember} />
        )}
      </Column>
    </ColumnLayout>
  );
};

const SignupForm = ({
  member,
  setMember,
}: {
  member: MemberCreate | undefined;
  setMember: (data: MemberCreate) => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const { register, watch, errors, handleSubmit } = useForm<MemberCreate>({
    submitFocusError: true,
    defaultValues: member,
  });

  const line1Error = errors['addressResidential.line1'];
  const line2Error = errors['addressResidential.line2'];
  const suburbError = errors['addressResidential.suburb'];
  const stateError = errors['addressResidential.state'];
  const postcodeError = errors['addressResidential.postcode'];
  const state = watch('addressResidential.state', '') as string;

  return (
    <form
      className={css(signupForm)}
      onSubmit={handleSubmit(async memberCreateRequest => {
        setLoading(true);
        setMember({
          ...member,
          ...memberCreateRequest,
        });
        setLoading(false);
        await navigate(Routes.SIGNUP_TFN);
      })}
    >
      <input
        type="hidden"
        name="addressResidential.countryCode"
        value="AU"
        ref={register}
      />
      <Input
        margin={{ ...INPUT_MARGIN, marginTop: Spacing.LARGE }}
        type="text"
        name="addressResidential.line1"
        placeholder="Address line 1"
        ref={register({
          required: 'Address line 1 is required.',
          validate: (value: string) =>
            asciiStringValidate(value, 'Address line 1'),
        })}
        errorMessage={line1Error && line1Error.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.line2"
        placeholder="Address line 2"
        ref={register({
          validate: (value: string) =>
            asciiStringValidate(value, 'Address line 2'),
        })}
        errorMessage={line2Error && line2Error.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.suburb"
        placeholder="Suburb"
        ref={register({
          required: 'Suburb is required',
          validate: (value: string) => asciiStringValidate(value, 'Suburb'),
        })}
        errorMessage={suburbError && suburbError.message}
      />
      <Dropdown
        margin={INPUT_MARGIN}
        name="addressResidential.state"
        placeholder="State"
        options={STATES_DROPDOWN_OPTONS}
        ref={register({
          required: 'State is required',
        })}
        errorMessage={stateError && stateError.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="addressResidential.postcode"
        placeholder="Postcode"
        format="postcode"
        ref={register({
          required: 'Postcode is required',
          validate: postcode =>
            commonValidationRules.postcode.validate(state, postcode),
        })}
        errorMessage={postcodeError && postcodeError.message}
      />
      <Input
        margin={INPUT_MARGIN}
        type="text"
        name="birthDate"
        format="date"
        placeholder="Date of birth"
        ref={register({
          ...commonValidationRules.date,
          required: 'Date of birth is required',
        })}
        errorMessage={errors.birthDate && errors.birthDate.message}
      />
      <Dropdown
        margin={INPUT_MARGIN}
        name="gender"
        placeholder="Gender"
        options={[
          { value: '', label: 'Select your gender' },
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
          { value: 'Unspecified', label: 'Other' },
        ]}
        ref={register({
          required: 'Gender is required',
        })}
        errorMessage={errors.gender && errors.gender.message}
      />
      <div className={css(buttonContainer)}>
        <Button
          margin={{ marginRight: Spacing.SMALL }}
          variant="secondary"
          onClick={(): void => {
            navigate(Routes.SIGNUP);
          }}
          trackingProperties={{ name: 'signup_address_back' }}
        >
          Back
        </Button>
        <Button
          type="submit"
          loading={loading}
          trackingProperties={{ name: 'signup_address_submit' }}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

const illustration: Interpolation = {
  maxWidth: 470,
  img: {
    maxWidth: '100%',
    display: 'block',
    objectFit: 'contain',
  },
};

const quoted: Interpolation = {
  textAlign: 'right',
};

const signupForm: Interpolation = {
  width: '100%',
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  padding: Spacing.LARGE,
};
