import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { css, Interpolation } from 'emotion';
import { useIntercom } from 'helpers/contexts/intercom';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';
import { AlertTriangle, User } from 'react-feather';
import { useStores } from 'stores';
import {
  BODY,
  BorderRadius,
  CommonColor,
  hex2rgba,
  IconSize,
  LineHeight,
  META,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

import { Routes } from './routes';

export const AccountContact: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const {
      sargonStore: {
        member: {
          email,
          givenName,
          familyName,
          joinedDate,
          phoneMobile,
          birthDate,
          gender,
        },
      },
    } = useStores();

    const intercom = useIntercom();

    return (
      <ColumnLayout>
        <Column display="desktop-only" verticalCenter={true}>
          <Illustration type="lost" />
        </Column>
        <Column verticalCenter={true}>
          <div className={css(userDetails)}>
            <div className={css(nameIcon)}>
              <User
                className={css(icon)}
                size={IconSize.LARGE}
                color={CommonColor.WHITE}
              />
              <h3>
                {givenName} {familyName}
              </h3>
            </div>

            <p className={css(joinDate)}>
              Joined {moment(joinedDate).fromNow()}
            </p>

            <div className={css(labelField)}>
              <p>Email address</p>
              <p>{email}</p>
            </div>

            <div className={css(labelField)}>
              <p>Mobile</p>
              <p>{phoneMobile}</p>
            </div>

            <div className={css(labelField)}>
              <p>Age</p>
              <p>{moment().diff(birthDate, 'years')}</p>
            </div>

            <div className={css(labelField)}>
              <p>Gender</p>
              <p>{gender}</p>
            </div>

            <div className={css(warningText)}>
              <AlertTriangle size={IconSize.MEDIUM} />
              <p>
                We can&rsquo;t update these details after you&rsquo;ve created
                an account. If there&rsquo;s an issue please contact our support
                team and we&rsquo;ll be on it right away.
              </p>
            </div>

            <div className={css(buttons)}>
              <Button
                variant="secondary"
                margin={{ marginRight: Spacing.SMALL }}
                onClick={(): void => {
                  navigate(Routes.DASHBOARD);
                }}
                trackingProperties={{
                  name: 'account_contact_back',
                }}
              >
                Back
              </Button>
              <Button
                onClick={intercom.pop}
                trackingProperties={{
                  name: 'account_contact_support',
                }}
              >
                Contact support
              </Button>
            </div>
          </div>
        </Column>
      </ColumnLayout>
    );
  },
);

const nameIcon: Interpolation = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: Spacing.SMALL,

  p: {
    margin: 0,
  },

  h3: {
    margin: 0,
  },
};

const userDetails: Interpolation = {
  width: '100%',
};

const joinDate: Interpolation = {
  marginTop: 0,
  marginBottom: Spacing.MEDIUM,
};

const icon: Interpolation = {
  marginRight: Spacing.SMALL,
};

const labelField: Interpolation = {
  marginBottom: Spacing.SMALL,

  'p:first-child': {
    ...META,
    color: ShadeColor.SHADE_2,
    textTransform: 'uppercase',
  },

  'p:nth-child(2)': {
    ...BODY,
  },
};

const buttons: Interpolation = {
  display: 'flex',
  justifyContent: 'row',
  marginTop: Spacing.LARGE,
};

const warningText: Interpolation = {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridColumnGap: Spacing.SMALL,
  color: PrimaryColor.GOLD_100,
  backgroundColor: hex2rgba(PrimaryColor.GOLD_100, 0.1),
  padding: Spacing.SMALL,
  paddingRight: Spacing.MEDIUM,
  borderRadius: BorderRadius.SMALL,
  marginTop: Spacing.MEDIUM,

  p: {
    margin: 0,
    lineHeight: LineHeight.BODY,
  },
};
