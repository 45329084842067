import { css, cx, Interpolation } from 'emotion';
import React, { ReactNode } from 'react';
import {
  Gutter,
  HEADER_HEIGHT,
  MEDIA_QUERY_DESKTOP_MIN,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MAX,
  MEDIA_QUERY_TABLET_MIN,
  Spacing,
} from 'styles';

interface ColumnLayoutProps {
  children: ReactNode | ReactNode[];
}

export const ColumnLayout = ({ children }: ColumnLayoutProps): JSX.Element => (
  <div className={css(container)}>{children}</div>
);

type ColumnDisplay = 'all' | 'desktop-only' | 'mobile-only';

interface ColumnProps {
  children: ReactNode | ReactNode[];
  display?: ColumnDisplay;
  verticalCenter?: boolean;
}

export const Column = ({
  children,
  display = 'all',
  verticalCenter = false,
}: ColumnProps): JSX.Element => (
  <div className={css(columnStyle, columnDisplayStyles[display])}>
    <div className={cx(verticalCenter && css(verticalCenterStyle))}>
      {children}
    </div>
  </div>
);

export const COLUMN_LAYOUT_MAX_WIDTH = 1140;

const container: Interpolation = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  width: '100%',
  maxWidth: COLUMN_LAYOUT_MAX_WIDTH,
  margin: '0 auto',
  padding: `0 ${Gutter.DESKTOP}px`,
  [MEDIA_QUERY_MOBILE_MAX]: {
    padding: `0 ${Gutter.MOBILE}px`,
  },
};

const COLUMN_VERTICAL_SPACING = Spacing.MEDIUM;

const columnStyle: Interpolation = {
  width: '100%',
  marginTop: COLUMN_VERTICAL_SPACING,
  marginBottom: COLUMN_VERTICAL_SPACING,

  [MEDIA_QUERY_TABLET_MAX]: {
    marginBottom: Spacing.LARGE,
  },
};

const verticalCenterStyle: Interpolation = {
  [MEDIA_QUERY_TABLET_MIN]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - ${HEADER_HEIGHT + COLUMN_VERTICAL_SPACING * 2}px)`,
    justifyContent: 'center',
    flexGrow: 1,
  },
};

const columnDisplayStyles: { [t in ColumnDisplay]: Interpolation } = {
  all: {},
  'desktop-only': {
    display: 'none',
    [MEDIA_QUERY_DESKTOP_MIN]: {
      display: 'flex',
    },
  },
  'mobile-only': {
    display: 'none',
    [MEDIA_QUERY_MOBILE_MAX]: {
      display: 'flex',
    },
  },
};
