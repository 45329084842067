import { navigate, RouteComponentProps } from '@reach/router';
import { MemberCreate } from '@sargon/api-client';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { useSignupContext } from 'helpers/contexts/signup';
import { commonValidationRules } from 'helpers/validation';
import React, { FC, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import {
  BODY,
  BODY_SMALL,
  INPUT_MARGIN,
  MEDIA_QUERY_MOBILE_MAX,
  META,
  ShadeColor,
  Spacing,
} from 'styles';

import { ExternalRoutes, Routes } from './routes';

export const SignupTaxFileNumber: FC<RouteComponentProps> = (): JSX.Element => {
  const [member, setMember] = useSignupContext();

  useEffect(() => {
    switch (true) {
      case member && !member.givenName:
        navigate(Routes.SIGNUP);
        break;
      case member && !member.birthDate:
        navigate(Routes.SIGNUP_ADDRESS);
        break;
    }
  }, [member]);

  return (
    <ColumnLayout>
      <Column verticalCenter={true} display="desktop-only">
        <Illustration type="form" />
      </Column>
      <Column verticalCenter={true}>
        <div className={css(topMargin, center)}>
          <h1>Tax file number (TFN)</h1>

          <p className={css(copy)}>
            You can provide Spaceship with your TFN to help manage your
            Spaceship Super account
          </p>
        </div>
        {member !== undefined && (
          <SignupForm member={member} setMember={setMember} />
        )}
        <div className={css(BODY_SMALL, center)}>
          <p>
            Help,{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={ExternalRoutes.ATO_LOST_TFN}
            >
              I can&apos;t remember my TFN
            </a>
          </p>
        </div>
        <div className={css(disclaimer)}>
          <h4>DISCLAIMER</h4>
          <p>
            The Trustee for Spaceship is authorised to collect, use and disclose
            your TFN under the Superannuation Industry (Supervision) Act 1993.
          </p>
          <p>
            You don’t need to provide your TFN to Spaceship. However, providing
            it will have the following advantages:
          </p>
          <ul>
            <li>
              Spaceship will be able to accept all permitted types of
              contributions to your Spaceship Super account;
            </li>
            <li>
              you won’t pay more tax than you need to – other than the tax that
              may ordinarily apply. This affects both contributions to your
              Spaceship Super account and benefit payments when you start
              drawing down your super account; and
            </li>
            <li>
              it’ll make it much easier to find other super accounts in your
              name so that you receive all your super benefits when you retire.
            </li>
          </ul>
          <p>
            When your super monies are transferred into, or out of, your
            Spaceship Super account, the trustee of Spaceship Super may disclose
            your TFN to another super provider, unless you request in writing
            that you do not want Spaceship to disclose your TFN to any other
            super provider.
          </p>
        </div>
      </Column>
    </ColumnLayout>
  );
};

const SignupForm = ({
  member,
  setMember,
}: {
  member: MemberCreate | undefined;
  setMember: (data: MemberCreate) => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();

  const { register, errors, handleSubmit } = useForm<{
    taxId: string;
  }>({
    submitFocusError: true,
    defaultValues: {
      taxId: member?.taxId,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(async memberRequest => {
        setLoading(true);
        try {
          setMember({
            ...member,
            ...memberRequest,
          });
          await navigate(Routes.SIGNUP_PASSWORD);
        } catch (error) {
          notifications.popToast({
            level: 'error',
            message: error.message,
          });
        }
        setLoading(false);
      })}
    >
      <Input
        className="fs-hide"
        margin={INPUT_MARGIN}
        type="text"
        name="taxId"
        placeholder="Your tax file number"
        ref={register(commonValidationRules.taxFileNumber)}
        errorMessage={errors.taxId && errors.taxId.message}
        maxLength={9}
      />
      <div className={css(row)}>
        <Button
          variant="secondary"
          onClick={(): void => {
            setMember({
              ...member,
              taxId: undefined,
            });
            navigate(Routes.SIGNUP_PASSWORD);
          }}
          trackingProperties={{
            name: 'signup_tfn_skip',
          }}
        >
          Skip
        </Button>
        <Button
          type="submit"
          trackingProperties={{
            name: 'signup_tfn_submit',
          }}
          loading={loading}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

const topMargin: Interpolation = {
  marginTop: Spacing.LARGE,
};

const row: Interpolation = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: Spacing.SMALL,

  [MEDIA_QUERY_MOBILE_MAX]: {
    flexDirection: 'column',
    button: {
      width: '100%',
      ':first-child': {
        marginBottom: Spacing.MEDIUM,
      },
    },
  },
};

const copy: Interpolation = {
  ...BODY,
  maxWidth: 400,
  marginBottom: Spacing.SMALL,
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const disclaimer: Interpolation = {
  ...BODY_SMALL,
  marginTop: Spacing.XX_LARGE,
  paddingTop: Spacing.LARGE,
  borderTop: '1px solid #68758a',
  marginBottom: Spacing.LARGE,
  maxWidth: 450,

  h4: {
    ...META,
    color: ShadeColor.SHADE_2,
    textTransform: 'uppercase',
  },
};
