import { injectGlobal, Interpolation } from 'emotion';

export type Color = PrimaryColor | CommonColor | ShadeColor;

export enum PrimaryColor {
  GREEN = '#20fc8f',

  PURPLE_1 = '#a74eff',
  PURPLE_2 = '#7c2fc7',
  PURPLE_3 = '#7637b6',

  MINT_050 = '#08D2B2',
  GOLD_100 = '#F4A305',
  GOLD_050 = '#FFC600',
  RED_100 = '#E11B03',
  PINK_080 = '#E100CA',
}

export enum Opacity {
  MEDIUM = 0.4,
  DARK = 0.8,
}

export enum CommonColor {
  BLACK = '#000000',
  GREEN = '#0CB061',
  GREY = '#79809A',
  RED = '#fc686d',
  WHITE = '#FFFFFF',

  TEXT = '#d0e5ff',
  BACKGROUND = '#181E2E',
  SECONDARY = '#1a2234',
}

export enum ShadeColor {
  SHADE_1 = '#12003B',
  SHADE_2 = '#6F708C',
  SHADE_3 = '#E4E1E8',
  SHADE_4 = '#F1F0F3',
  SHADE_5 = '#F5F5F8',
  SHADE_6 = '#F5F6F7',
}

export enum Spacing {
  XXXX_SMALL = 2,
  XXX_SMALL = 4,
  XX_SMALL = 8,
  X_SMALL = 12,
  SMALL = 16,
  MEDIUM = 24,
  LARGE = 32,
  X_LARGE = 48,
  XX_LARGE = 64,
  XXX_LARGE = 96,
}

export const HEADER_HEIGHT = Spacing.XX_LARGE;

export enum BorderWidth {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 4,
}

export enum BorderRadius {
  X_SMALL = 4,
  SMALL = 8,
  MEDIUM = 16,
  LARGE = 32,
  CIRCLE = '50%',
}

export enum IconSize {
  SMALL = 16,
  MEDIUM = 24,
  LARGE = 32,
}

export enum FontSize {
  HEADMAST = 64,
  FOREMAST = 48,
  MIZZENMAST = 32,
  HEADING_1 = 28,
  HEADING_2 = 22,
  HEADING_3 = 18,
  BODY = 16,
  BODY_SMALL = 14,
  META = 12,
}

export enum FontWeight {
  LIGHT = 200,
  REGULAR = 300,
  SEMI_BOLD = 500,
  BOLD = 600,
  BOLDER = 700,
}

export enum LineHeight {
  HEADMAST = '76px',
  FOREMAST = '64px',
  MIZZENMAST = '46px',
  HEADING_1 = '56px',
  HEADING_2 = '48px',
  HEADING_3 = '42px',
  BODY = '26px',
  BODY_SMALL = '22px',
  META = '18px',
}

const TEXT = {
  margin: 0,
};

export const HEADMAST = {
  ...TEXT,
  fontSize: FontSize.HEADMAST,
  fontWeight: FontWeight.BOLDER,
  lineHeight: LineHeight.HEADMAST,
  letterSpacing: -2,
};

export const FOREMAST = {
  ...TEXT,
  fontSize: FontSize.FOREMAST,
  fontWeight: FontWeight.BOLD,
  lineHeight: LineHeight.FOREMAST,
  letterSpacing: -0.6,
};

export const MIZZENMAST = {
  ...TEXT,
  fontSize: FontSize.MIZZENMAST,
  fontWeight: FontWeight.BOLD,
  lineHeight: LineHeight.MIZZENMAST,
  letterSpacing: -0.6,
};

export const HEADING_1 = {
  ...TEXT,
  fontSize: FontSize.HEADING_1,
  fontWeight: FontWeight.LIGHT,
  lineHeight: LineHeight.HEADING_1,
  color: CommonColor.WHITE,
};

export const HEADING_2 = {
  ...TEXT,
  fontSize: FontSize.HEADING_1,
  fontWeight: FontWeight.LIGHT,
  lineHeight: LineHeight.HEADING_2,
};

export const HEADING_3 = {
  ...TEXT,
  fontSize: FontSize.HEADING_3,
  fontWeight: FontWeight.LIGHT,
  lineHeight: LineHeight.HEADING_3,
};

export const BODY = {
  ...TEXT,
  fontSize: FontSize.BODY,
  fontWeight: FontWeight.REGULAR,
  lineHeight: LineHeight.BODY,
};

export const BODY_SMALL = {
  ...TEXT,
  fontSize: FontSize.BODY_SMALL,
  fontWeight: FontWeight.REGULAR,
  lineHeight: LineHeight.BODY_SMALL,
};

export const META = {
  ...TEXT,
  fontSize: FontSize.META,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: LineHeight.META,
};

export const BUTTON_LINK: Interpolation = {
  textDecoration: 'underline',
  textAlign: 'left',
};

export enum Breakpoint {
  MOBILE = 560,
  TABLET = 760,
  DESKTOP = 1200,
  WIDE = 1920,
}

export enum Gutter {
  DESKTOP = 24,
  MOBILE = 16,
}

const COLUMN_WIDTH = 74;

export const columns = (
  columns: number,
  gutter: Gutter = Gutter.DESKTOP,
): number => columns * COLUMN_WIDTH + (columns - 1) * gutter;

export const MEDIA_QUERY_DESKTOP_MIN = `@media(min-width: ${Breakpoint.DESKTOP}px)`;
export const MEDIA_QUERY_TABLET_MIN = `@media(min-width: ${Breakpoint.TABLET}px)`;
export const MEDIA_QUERY_TABLET_MAX = `@media(max-width: ${Breakpoint.DESKTOP}px)`;
export const MEDIA_QUERY_MOBILE_MAX = `@media(max-width: ${Breakpoint.TABLET}px)`;

export const TRANSITION_DURATION = '150ms';

export enum ZIndex {
  MENU = 900,
  OVERLAY = 1000,
  MODAL = 1100,
  NOTIFICATION = 2000,
}

export function hex2rgba(color: Color, a: number): string {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${a})`;
}

export const INPUT_MARGIN = { marginBottom: Spacing.MEDIUM };

export interface Margin {
  marginTop?: Spacing;
  marginRight?: Spacing;
  marginBottom?: Spacing;
  marginLeft?: Spacing;
}

export const initGlobalStyles = (): void => injectGlobal`
  body {
    font-family: -apple-system-body,BlinkMacSystemFont,San Francisco UI Text,Helvetica Neue,Helvetica,sans-serif;
    background: #181E2E;
    color: ${CommonColor.TEXT};
    font-weight: 300;
  }
  a {
    color: #F6F1F2;
    cursor: pointer;
  }
  a:hover{
    text-decoration: underline;
  }
  p {
    line-height: ${LineHeight.BODY};
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  h1, h2, h3 {
    margin: 0;
    font-weight: 200;
    color: ${CommonColor.WHITE};
  }
  h1 {
    line-height: ${LineHeight.HEADING_1};
    font-size: ${FontSize.HEADING_1}pt;
  }
  h2 {
    line-height: ${LineHeight.HEADING_2};
    font-size: ${FontSize.HEADING_2}pt;
  }
  h3 {
    line-height: ${LineHeight.HEADING_3};
    font-size: ${FontSize.HEADING_3}pt;
  }

  hr {
    width: 100%;
    margin-top: ${Spacing.LARGE}px;
    margin-bottom: ${Spacing.LARGE}px;
    border-color: ${CommonColor.GREY};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0;
  }

  // Taken from:
  // https://github.com/spaceship-fspl/www.spaceship.com.au/blob/master/src/styles/tooltip.css
  .tooltip {
    position: absolute;
    top: 100px;
    left: 100px;
    border-radius: 3px;
    background: white;
    opacity: 1;
    color: black;
    padding: 20px;
    width: 360px;
    font-size: 12px;
    z-index: 10;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  }

  .tooltip .information h2 {
    color: #0c252f;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 1.2;
    margin: 0 0 8px;
    text-align: left;
    text-shadow: none;
  }

  .tooltip .information .symbols span {
    background-color: #efefef;
    border-radius: 3px;
    color: #181e2d;
    display: inline-block;
    font-size: 11px;
    line-height: 16px;
    margin-top: 6px;
    padding: 4px 6px;

    &:not(:first-child) {
        margin-left: 6px;
    }
  }

  .tooltip .information p {
    font-weight: 500;
    line-height: 1.2;
  }

  .tooltip .meta {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #8695aa;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .tooltip .meta .logo {
    width: 64px;
    height: 64px;
    display: block;
    flex-shrink: 0;
    margin-right: 16px;
    background-color: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
    border: 3px solid white;
  }

  .tooltip .bio {
    margin-bottom: 8px;
  }

  .tooltip p.name {
    line-height: 22px;
  }

  .tooltip p.disclaimer {
    border-top: 1px solid #8695aa;
    margin-top: 16px;
    padding-top: 16px;
  }

  .tooltip p,
  .tooltip small {
    font-size: 13px;
    color: #0c252f;
    line-height: 18px;
    margin: 0;
  }

  .tooltip small {
    font-size: 11px;
    line-height: 15px;
  }

  blockquote {
    position: relative;
    color: #d0e5ff;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.6;
    text-align: left;
  }
  blockquote:before {
    content: "\\201C";
    display: block;
    left: -25px;
    line-height: 48px;
    position: absolute;
    top: -8px;
    color: #fff;
    font-size: 48px;
    font-weight: 400;
  }
  blockquote:after {
    content: "\\201D";
    display: inline-block;
    line-height: 0;
    margin-left: 5px;
    vertical-align: bottom;
    color: #fff;
    font-size: 48px;
    font-weight: 400;
  }
`;

export const BOX_SHADOW_PRIMARY = `0px 2px 6px ${hex2rgba(
  ShadeColor.SHADE_1,
  0.12,
)}, 0px -1px 5px ${hex2rgba(ShadeColor.SHADE_1, 0.04)}`;

export const BOX_SHADOW_SECONDARY = `1px 4px 24px ${hex2rgba(
  ShadeColor.SHADE_1,
  0.12,
)}, 0px 4px 8px ${hex2rgba(ShadeColor.SHADE_1, 0.08)}`;

export const BOX_SHADOW_TERTIARY = `2px 24px 36px ${hex2rgba(
  ShadeColor.SHADE_1,
  0.16,
)}, 0px 0px 8px ${hex2rgba(ShadeColor.SHADE_1, 0.06)}`;

export const CONSENT_BOX: Interpolation = {
  ...BODY_SMALL,
  fontWeight: 300,
  background: hex2rgba(ShadeColor.SHADE_2, 0.2),
  paddingTop: Spacing.XXX_SMALL,
  paddingBottom: Spacing.XXX_SMALL,
  paddingLeft: Spacing.SMALL,
  paddingRight: Spacing.SMALL,
  marginTop: Spacing.MEDIUM,
  marginBottom: Spacing.MEDIUM,
  borderRadius: BorderRadius.X_SMALL,
  textAlign: 'left',
};
