import form from 'assets/images/form.png';
import identity from 'assets/images/identity.png';
import lost from 'assets/images/lost.png';
import phone from 'assets/images/phone.png';
import profile from 'assets/images/profile.png';
import rocket from 'assets/images/rocket.png';
import satellite from 'assets/images/satellite.png';
import ufo from 'assets/images/ufo.png';
import vault from 'assets/images/vault.png';
import { css, Interpolation } from 'emotion';
import React from 'react';

const imageMap = {
  vault,
  ufo,
  satellite,
  rocket,
  profile,
  phone,
  lost,
  identity,
  form,
};

type IllustrationType =
  | 'vault'
  | 'ufo'
  | 'satellite'
  | 'rocket'
  | 'profile'
  | 'phone'
  | 'lost'
  | 'identity'
  | 'form';

interface Props {
  type: IllustrationType;
}

export const Illustration = ({ type }: Props): JSX.Element => (
  <img src={imageMap[type]} className={css(img)} alt="form" />
);

const img: Interpolation = {
  maxHeight: 400,
};
