import logoGlobalIndex from 'assets/images/portfolio-global-index.png';
import logoGrowthX from 'assets/images/portfolio-growthx.png';
import { ExternalRoutes } from 'pages/routes';

export enum PortfolioKey {
  INDEX = 'global_index',
  GROWTHX = 'growthx',
  CASH = 'cash',
}

export const portfolioKeyToPrettyName: { [key: string]: string } = {
  [PortfolioKey.GROWTHX]: 'Growth X',
  [PortfolioKey.INDEX]: 'Global Index',
};

export const DEFAULT_PORTFOLIO = PortfolioKey.GROWTHX;

export const PORTFOLIOS: {
  [key: string]: {
    adminFee: string;
    annualFree: string;
    description: string;
    logo: string;
    link: string;
  };
} = {
  [PortfolioKey.INDEX]: {
    adminFee: '0.58%',
    annualFree: '$78',
    description:
      'Our investment option for the index investor. We’ve built the Global Index option for those looking for a diversified portfolio that passively invests in global companies.',
    logo: logoGlobalIndex,
    link: ExternalRoutes.SUPER_GLOBAL_INDEX_OVERVIEW,
  },
  [PortfolioKey.GROWTHX]: {
    adminFee: '0.916%',
    annualFree: '$78',
    description:
      'Our flagship portfolio. We’ve built the GrowthX portfolio for those looking for a diversified portfolio with a focus on global technology companies.',
    logo: logoGrowthX,
    link: ExternalRoutes.SUPER_GROWTHX_OVERVIEW,
  },
};
