import { Member } from '@sargon/api-client';
import { identify, reset } from 'helpers/analytics';
import { config } from 'helpers/config';
import { IntercomEvent, IntercomSettings } from 'helpers/contexts/intercom';

export const identifyUser = (member: Member): void => {
  if (!member.id) {
    console.log('Unable to identify user, missing member id.');
    return;
  }
  try {
    identify(member.id, {
      memberNumber: member.memberNumber || '',
      joinedDate: member.joinedDate || '',
      givenName: member.givenName || '',
      familyName: member.familyName || '',
      email: member.email || '',
      phoneMobile: member.phoneMobile || '',
      birthDate: member.birthDate || '',
      gender: member.gender || '',
      idVerificationStatus: member.idVerificationStatus || '',
      taxIdStatus: member.taxIdStatus || '',
      memberStatus: member.memberStatus || '',
    });
  } catch (e) {
    console.warn(e);
  }
  try {
    const traits: IntercomSettings = {
      app_id: config.intercomAppId,
      user_id: member.id || '',
      name: `${member.givenName || ''} ${member.familyName || ''}`.trim(),
      phone: member.phoneMobile || '',
    };
    if (member.joinedDate) {
      traits.created_at = Date.parse(String(member.joinedDate)) / 1000;
    }
    window.Intercom(IntercomEvent.UPDATE, traits);
  } catch (e) {
    console.warn(e);
  }
};

export const clearIdentifiedUser = (): void => {
  reset();
  window.Intercom(IntercomEvent.SHUTDOWN);
  window.Intercom(IntercomEvent.BOOT, {
    app_id: config.intercomAppId,
  });
};
