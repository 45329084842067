import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

const ResetPasswordEmailContext = createContext<
  [string, Dispatch<SetStateAction<string>>]
>(['', (): void => console.error('reset password context not provided')]);

export const ResetPasswordEmailProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => (
  <ResetPasswordEmailContext.Provider value={useState('')}>
    {children}
  </ResetPasswordEmailContext.Provider>
);

export const useResetPasswordEmailContext = (): [
  string,
  Dispatch<SetStateAction<string>>,
] => useContext(ResetPasswordEmailContext);
