import { Location } from '@reach/router';
import { page, track, TrackingEvent } from 'helpers/analytics';
import React, { useEffect, useRef } from 'react';

interface Props {
  pathname: string;
}

const NavigationTracking = ({ pathname: nextRoute }: Props): null => {
  const previousRoute = useRef<string | undefined>(undefined);
  const dwellTime = useRef(Date.now());

  useEffect(() => {
    if (previousRoute.current !== undefined) {
      track(TrackingEvent.NAVIGATE, {
        from: previousRoute.current,
        to: nextRoute,
      });
      track(TrackingEvent.DWELL_TIME, {
        from: previousRoute.current,
        to: nextRoute,
        dwellTime: Date.now() - dwellTime.current,
      });
      dwellTime.current = Date.now();
    }
    page(nextRoute);
    previousRoute.current = nextRoute;
  }, [nextRoute]);

  return null;
};

const withLocationNavigationTracking = (): JSX.Element => (
  <Location>
    {({ location: { pathname } }): JSX.Element => {
      return <NavigationTracking pathname={pathname} />;
    }}
  </Location>
);

export { withLocationNavigationTracking as NavigationTracking };
