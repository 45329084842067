import { Button } from 'components/button';
import { Input } from 'components/input';
import { Modal } from 'components/modal';
import { css, Interpolation } from 'emotion';
import { track, TrackingEvent } from 'helpers/analytics';
import { useNotifications } from 'helpers/contexts/notifications';
import { commonValidationRules } from 'helpers/validation';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import { BODY_SMALL, Spacing } from 'styles';

export const OtpModal = observer(
  (): JSX.Element => {
    const notifications = useNotifications();
    const {
      sargonStore: {
        logout,
        requestOTP,
        verifyOTP,
        pendingOTPAuthorisation,
        setPendingOTPAuthorisation,
        lastScopeRequest,
      },
    } = useStores();

    const { handleSubmit, register, errors } = useForm<{ otp: string }>({
      submitFocusError: true,
      mode: 'onBlur',
    });

    useEffect(() => {
      track(TrackingEvent.DISPLAY, {
        name: 'otp_modal',
        scope: lastScopeRequest,
      });
    }, []);

    return (
      <Modal showModal={pendingOTPAuthorisation}>
        <div className={css(container)}>
          <form
            onSubmit={handleSubmit(
              async ({ otp }): Promise<void> => {
                try {
                  await verifyOTP(otp);
                } catch (error) {
                  notifications.popToast({
                    level: 'error',
                    message: error.message,
                  });
                }
              },
            )}
          >
            <h3 className={css({ marginBottom: Spacing.MEDIUM })}>
              We&apos;ll need to verify your phone number to proceed
            </h3>
            <p className={css(BODY_SMALL, { marginBottom: Spacing.SMALL })}>
              Please enter the code we&apos;ve sent to your nominated mobile
              number.
            </p>
            <Input
              margin={{ marginBottom: Spacing.LARGE }}
              type="text"
              name="otp"
              format="otp"
              placeholder="SMS Code"
              ref={register(commonValidationRules.smsOtp)}
              errorMessage={errors.otp && errors.otp.message}
              maxLength={6}
            />
            <div className={css(submit)}>
              <Button type="submit" trackingProperties={{ name: 'otp_submit' }}>
                Submit code
              </Button>

              <p
                className={css(BODY_SMALL, {
                  marginTop: Spacing.SMALL,
                  marginBottom: Spacing.X_SMALL,
                })}
              >
                Didn&apos;t receive your code?{' '}
                <a
                  onClick={async (): Promise<void> => {
                    try {
                      await requestOTP(lastScopeRequest);
                      notifications.popToast({
                        level: 'success',
                        message: 'A new code is on its way!',
                      });
                    } catch {
                      notifications.popBanner({
                        message:
                          'Something went wrong while requesting your verification code.',
                      });
                    }
                  }}
                >
                  Send again
                </a>
                .
              </p>
              <p className={css(BODY_SMALL, { marginTop: 0 })}>
                Unable to verify right now?{' '}
                <a
                  onClick={(): void => {
                    setPendingOTPAuthorisation(false);
                    logout();
                  }}
                >
                  Log out
                </a>
              </p>
            </div>
          </form>
        </div>
      </Modal>
    );
  },
);

const container: Interpolation = {
  padding: Spacing.LARGE,
};

const submit: Interpolation = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
