import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { useResetPasswordEmailContext } from 'helpers/contexts/password-reset';
import { Source } from 'helpers/source';
import { commonValidationRules } from 'helpers/validation';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import { HEADER_HEIGHT, INPUT_MARGIN, Spacing } from 'styles';

import { Routes } from './routes';

const validation = {
  otp: {
    required: 'Email code is required',
  },
  newPassword: commonValidationRules.password,
};

export const ResetPasswordUpdate: FC<RouteComponentProps> = observer(
  ({ location }): JSX.Element => {
    const notifications = useNotifications();
    const [email] = useResetPasswordEmailContext();
    const [loading, setLoading] = useState(false);
    const source = location ? location.state.source : '';
    const action = source === Source.EMPLOYMENT_HERO ? 'Set' : 'Reset';

    const {
      sargonStore: { forgotPasswordSubmit },
    } = useStores();

    const { register, errors, handleSubmit } = useForm<{
      otp: string;
      newPassword: string;
    }>({
      submitFocusError: true,
    });

    return (
      <ColumnLayout>
        <Column verticalCenter={true}>
          <div className={css(container)}>
            <h1 className={css(center)}>{action} your password</h1>
            <p className={css(center, INPUT_MARGIN)}>
              Your new password must be at least 8 characters long, and contain
              an uppercase character, a lowercase character, and a numeric
              character.
            </p>

            <form
              className={css(loginForm)}
              onSubmit={handleSubmit(async ({ otp, newPassword }) => {
                setLoading(true);
                try {
                  await forgotPasswordSubmit(email, otp, newPassword);
                  await navigate(Routes.LOGIN);
                  notifications.popToast({
                    level: 'success',
                    message: 'Your password has been reset.',
                  });
                } catch (error) {
                  const codesToShow = [
                    'InvalidPasswordException',
                    'CodeMismatchException',
                  ];
                  if (codesToShow.includes(error.code)) {
                    notifications.popToast({
                      level: 'error',
                      message: error.message,
                    });
                  } else {
                    notifications.popToast({
                      level: 'error',
                      message:
                        'Something went wrong, please check your details and try again.',
                    });
                  }
                }
                setLoading(false);
              })}
            >
              <Input
                margin={INPUT_MARGIN}
                type="text"
                name="otp"
                placeholder="Reset code (sent via email)"
                ref={register(validation.otp)}
                errorMessage={errors.otp && errors.otp.message}
                maxLength={6}
              />
              <Input
                className="fs-hide"
                type="password"
                name="newPassword"
                placeholder="Your new password"
                ref={register(validation.newPassword)}
                margin={INPUT_MARGIN}
                errorMessage={errors.newPassword && errors.newPassword.message}
              />
              <div className={css(buttonContainer)}>
                <Button
                  type="submit"
                  loading={loading}
                  trackingProperties={{ name: 'reset_password_update_submit' }}
                >
                  {action} your password
                </Button>
              </div>
            </form>
          </div>
        </Column>
        <Column verticalCenter={true} display="desktop-only">
          <Illustration type="form" />
        </Column>
      </ColumnLayout>
    );
  },
);

const container: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  justifyContent: 'center',
  width: '100%',
  maxWidth: 450,
};

const loginForm: Interpolation = {
  width: '100%',
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: Spacing.LARGE,
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
};
