import { Link, navigate, RouteComponentProps } from '@reach/router';
import googlePlay from 'assets/images/appstore-gplay.svg';
import appleStore from 'assets/images/appstore-ios.svg';
import FeatureImage from 'assets/images/feature-image.png';
import QRCodeSvg from 'assets/images/qr-code-dashboard.svg';
import { CenteredLayout } from 'components/centered-layout';
import { PortfolioUnitPrice } from 'components/portfolio-unit-price';
import { Tooltip } from 'components/tooltip';
import { css, cx, Interpolation } from 'emotion';
import { links } from 'helpers/constants';
import { useNotifications } from 'helpers/contexts/notifications';
import {
  formatCurrency,
  getTransactionDescription,
  LONG_DATE_FORMAT,
} from 'helpers/format';
import { useSortedTransactions } from 'helpers/transactions';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Info } from 'react-feather';
import SVG from 'react-inlinesvg';
import { useStores } from 'stores';
import {
  BODY_SMALL,
  BUTTON_LINK,
  CommonColor,
  FontSize,
  FOREMAST,
  IconSize,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MAX,
  META,
  MIZZENMAST,
  PrimaryColor,
  ShadeColor,
  Spacing,
} from 'styles';

import { Routes } from './routes';

export const Dashboard: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const {
      sargonStore: {
        getDocuments,
        getInvestmentPerformance,
        getTransactions,
        getMemberBalance,
        getDocumentDetails,
        documentSummaries,
        futureCashAllocations,
        listFutureCashAllocations,
        transactions,
        balance,
        memberBalance,
        latestUnitPrice,
        portfolio,
        portfolioDisplayName,
        pendingOTPAuthorisation,
      },
    } = useStores();

    const { popToast } = useNotifications();
    const [sortedTransactions] = useSortedTransactions(transactions);

    useEffect(() => {
      (async (): Promise<void> => {
        try {
          if (!futureCashAllocations.length) {
            const allocations = await listFutureCashAllocations();
            if (!allocations || !allocations.length) {
              await navigate(Routes.ONBOARDING_INVESTMENT_OPTION);
            }
          }
        } catch (e) {
          await navigate(Routes.ONBOARDING_INVESTMENT_OPTION);
        }
      })();

      (async (): Promise<void> => {
        try {
          await getDocuments();
        } catch (e) {
          // Do not show errors until we have a better idea of what is a legitimate error
        }
      })();

      (async (): Promise<void> => {
        try {
          await getTransactions();
        } catch (e) {
          // Do not show errors until we have a better idea of what is a legitimate error
        }
      })();

      (async (): Promise<void> => {
        try {
          await getMemberBalance();
        } catch (e) {
          // Do not show errors until we have a better idea of what is a legitimate error
        }
      })();
    }, []);

    const portfolioID = portfolio?.id;
    useEffect(() => {
      (async (): Promise<void> => {
        try {
          if (portfolioID) {
            await getInvestmentPerformance(portfolioID);
          }
        } catch (e) {
          // Do not show errors until we have a better idea of what is a legitimate error
        }
      })();
    }, [portfolioID]);

    const openDocument = (id: string, type: string): void => {
      const documentWindow = window.open();

      const getDocumentUrl = async (): Promise<void> => {
        try {
          const details = await getDocumentDetails(id);

          if (!details) {
            throw Error();
          }

          if (documentWindow) {
            documentWindow.location.href = details.url;
          }
        } catch {
          popToast({
            message: `There was an error downloading your ${type}.`,
            level: 'error',
          });
        }
      };

      getDocumentUrl();
    };

    return (
      <CenteredLayout size="full">
        <div className={css(balanceUnitPrice)}>
          <div>
            <div className={css(balanceLabel)}>
              <h4 className={css(META, { color: ShadeColor.SHADE_2 })}>
                ACCOUNT BALANCE
              </h4>
              {memberBalance?.to && latestUnitPrice?.date && (
                <Tooltip
                  text={`The account balance displayed here is an estimate. It is based on the number of ${portfolioDisplayName} units you currently hold, multiplied by the unit price as at ${moment(
                    latestUnitPrice.date,
                  ).format(
                    LONG_DATE_FORMAT,
                  )}. If your investment is redeemed - for example, if you make an investment switch, roll out, benefit payment or other withdrawal - your redemption balance will reflect the most recent unit price as of the date of redemption and may therefore be different.`}
                >
                  <span>
                    <Info
                      className={css(infoIcon)}
                      color={ShadeColor.SHADE_2}
                      size={IconSize.SMALL}
                    />
                  </span>
                </Tooltip>
              )}
            </div>

            <h2 className={css(accountBalance)}>
              {pendingOTPAuthorisation ? undefined : formatCurrency(balance)}
            </h2>
          </div>

          <PortfolioUnitPrice
            portfolioDisplayName={portfolioDisplayName}
            unitPrice={latestUnitPrice}
          />
        </div>

        <div className={css(columns)}>
          <div className={css(column, outerColumn)}>
            <div className={css(columns)}>
              <div className={css(column, featureImage)}>
                <img src={FeatureImage} alt="Spaceship mobile app" />
              </div>
              <div className={css(column, qrCodeTablet)}>
                <SVG src={QRCodeSvg} width="80%" />
              </div>
            </div>
          </div>
          <div className={css(column, outerColumn, copyColumn)}>
            <div className={css(qrCode)}>
              <SVG src={QRCodeSvg} />
            </div>
            <h2 className={css(mobileAppTitle)}>
              We’ve got a brand new Spaceship Super web experience coming
              soon...
            </h2>
            <p className={css(mobileAppSubtitle)}>
              While you wait, download the Spaceship app today to see when,
              where and how your super is invested, whenever you like.
            </p>
            <div className={css(storeLinks)}>
              <a href={links.googlePlay}>
                <img src={googlePlay} alt="Google play" />
              </a>
              <a href={links.appStore}>
                <img src={appleStore} alt="Apple store" />
              </a>
            </div>
          </div>
        </div>

        <hr />

        <div className={css(footer)}>
          <div className={css(footerSection)}>
            <h4>Transaction history</h4>
            {!sortedTransactions.length && (
              <p>Your account has no transactions yet.</p>
            )}
            {!pendingOTPAuthorisation &&
              sortedTransactions
                .slice(0, 5)
                .map(
                  ({ id, paidDate, transactionType, amountIn, amountOut }) => {
                    const amount =
                      (amountIn && amountIn.amount) ||
                      (amountOut && amountOut.amount) ||
                      0;
                    return (
                      <div key={id} className={css(row)}>
                        <div className="date">
                          {moment(paidDate).format('DD.MM.YYYY')}
                        </div>
                        <div className="type">
                          {getTransactionDescription(transactionType)}
                        </div>
                        <div className="amount">
                          <span
                            className={amount > 0 ? 'positive' : 'negative'}
                          >
                            {formatCurrency(amount / 100)}
                          </span>
                        </div>
                      </div>
                    );
                  },
                )}
            <p className={css(viewAll)}>
              <Link to="/transactions" className={css(viewAll)}>
                View all &rarr;
              </Link>
            </p>
          </div>

          <div className={css(footerSection)}>
            <h4>Documents</h4>
            {!documentSummaries.length && (
              <p>Your account has no documents yet.</p>
            )}
            {!pendingOTPAuthorisation &&
              documentSummaries.map(({ documentDate, type, id }) => (
                <div key={id} className={css(row)}>
                  <div className="date">
                    {moment(documentDate).format('DD.MM.YYYY')}
                  </div>
                  <button
                    onClick={async (): Promise<void> =>
                      await openDocument(id, type)
                    }
                    className={cx('type', css(BUTTON_LINK))}
                  >
                    {type}
                  </button>
                </div>
              ))}
          </div>

          <div className={css(footerSection)}>
            <h4>Recent posts from Spaceship</h4>
            {articles.map(({ url, name }) => (
              <div key={name} className={css(row)}>
                <a rel="noopener noreferrer" target="_blank" href={url}>
                  {name}
                </a>
              </div>
            ))}
            <p className={css(viewAll)}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.spaceship.com.au/learn/"
              >
                View all &rarr;
              </a>
            </p>
          </div>
        </div>
      </CenteredLayout>
    );
  },
);

const balanceUnitPrice: Interpolation = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: Spacing.X_LARGE,
  marginBottom: Spacing.LARGE,

  [MEDIA_QUERY_MOBILE_MAX]: {
    flexDirection: 'column',
    marginTop: Spacing.SMALL,
  },
};

const viewAll: Interpolation = {
  a: {
    textDecoration: 'none',
  },
};

const footer: Interpolation = {
  ...BODY_SMALL,
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  h4: {
    ...META,
    color: ShadeColor.SHADE_2,
    textTransform: 'uppercase',
  },
};

const footerSection: Interpolation = {
  marginBottom: Spacing.MEDIUM,
};

const row: Interpolation = {
  display: 'flex',
  justifyContent: 'row',
  marginTop: Spacing.X_SMALL,

  '.date': {
    width: 100,
    minWidth: 100,
    alignItems: 'center',
  },
  '.type': {
    flexGrow: 1,
    color: CommonColor.WHITE,
    alignItems: 'center',
  },
  '.amount': {
    width: 150,
    minWidth: 150,
    textAlign: 'right',
    alignItems: 'center',
  },
  '.positive': {
    color: PrimaryColor.GREEN,
  },
};

const accountBalance: Interpolation = {
  ...FOREMAST,
  color: CommonColor.WHITE,

  [MEDIA_QUERY_MOBILE_MAX]: {
    ...MIZZENMAST,
    marginBottom: Spacing.SMALL,
  },
};

const infoIcon: Interpolation = {
  lineHeight: 0,
  paddingLeft: Spacing.XXX_SMALL,
};

const balanceLabel: Interpolation = {
  display: 'flex',
};

const articles = [
  {
    url:
      'https://www.spaceship.com.au/learn/pay-yourself-first-money-hack-reverse-budgeting/',
    name: 'A “pay yourself first” money hack: reverse budgeting',
  },
  {
    url: 'https://www.spaceship.com.au/learn/tiny-expenses-that-really-add-up/',
    name: 'The tiny expenses that really add up',
  },
  {
    url: 'https://www.spaceship.com.au/learn/ways-to-invest-your-money/',
    name: '9 ways to invest your money',
  },
  {
    url:
      'https://www.spaceship.com.au/learn/what-happens-to-my-super-when-i-leave-a-job/',
    name: 'What happens to my super when I leave a job?',
  },
  {
    url: 'https://www.spaceship.com.au/learn/why-cant-you-touch-your-super/',
    name: "Why can't you touch your super?",
  },
];

const qrCode: Interpolation = {
  marginBottom: Spacing.MEDIUM,

  [MEDIA_QUERY_TABLET_MAX]: {
    display: 'none',
  },
};

const qrCodeTablet: Interpolation = {
  display: 'none',
  width: '80%',

  [MEDIA_QUERY_TABLET_MAX]: {
    display: 'block',
  },
  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },
};

const columns: Interpolation = {
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  alignItems: 'center',
};

const column: Interpolation = {
  flexGrow: 'initial',
  flexBasis: '50%',
  maxWidth: '50%',
  boxSizing: 'border-box',
  paddingLeft: Spacing.LARGE,
  paddingRight: Spacing.LARGE,

  [MEDIA_QUERY_MOBILE_MAX]: {
    paddingLeft: Spacing.XX_SMALL,
    paddingRight: Spacing.XX_SMALL,
  },
};

const outerColumn: Interpolation = {
  [MEDIA_QUERY_TABLET_MAX]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
};

const copyColumn: Interpolation = {
  [MEDIA_QUERY_TABLET_MAX]: {
    flexBasis: '75%',
    maxWidth: '75%',
    justifyContent: 'center',
  },
  [MEDIA_QUERY_MOBILE_MAX]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
};

const featureImage: Interpolation = {
  flexBasis: '100%',
  maxWidth: '100%',

  [MEDIA_QUERY_TABLET_MAX]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },
  [MEDIA_QUERY_MOBILE_MAX]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },

  img: {
    width: '100%',
  },
};

const mobileAppTitle: Interpolation = {
  lineHeight: '40px',
  marginBottom: Spacing.MEDIUM,

  [MEDIA_QUERY_TABLET_MAX]: {
    textAlign: 'center',
  },
};

const mobileAppSubtitle: Interpolation = {
  color: CommonColor.WHITE,
  marginBottom: Spacing.LARGE,
  fontSize: FontSize.HEADING_3,

  [MEDIA_QUERY_TABLET_MAX]: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 430,
  },
};

const storeLinks: Interpolation = {
  'a:first-child': {
    marginRight: Spacing.SMALL,
  },

  [MEDIA_QUERY_TABLET_MAX]: {
    textAlign: 'center',
  },
};
