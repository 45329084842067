import { config, Environment } from 'helpers/config';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export enum TrackingEvent {
  FOCUS_INPUT = 'focus_input',
  DWELL_TIME = 'dwell_time',
  NAVIGATE = 'navigate',
  CLICK = 'click',
  INTERCOM = 'intercom',
  DISPLAY = 'display',
}

enum SegmentEvent {
  IDENTIFY = 'IDENTIFY',
  PAGE = 'PAGE',
  ALIAS = 'ALIAS',
  RESET = 'RESET',
  TRACK = 'TRACK',
}

export interface TrackingProperties {
  name: string;
  [key: string]: string;
}

const log = (args: {}): void => {
  if (process.env.ENV === Environment.DEVELOPMENT) {
    console.log(JSON.stringify(args, null, 2));
  }
};

export const initAnalytics = (): void => {
  try {
    window.analytics.load(config.segmentKey);
  } catch (e) {
    console.error(e);
  }
};

export const identify = (id: string, traits?: {}): void => {
  window.analytics.identify(id, traits);
  log({ event: SegmentEvent.IDENTIFY, id, traits });
};

export const page = (page: string): void => {
  window.analytics.page(page);
  log({ event: SegmentEvent.PAGE, page });
};

export const alias = (id: string): void => {
  window.analytics.alias(id);
  log({ event: SegmentEvent.ALIAS, id });
};

export const reset = (): void => {
  window.analytics.reset();
  log({ event: SegmentEvent.RESET });
};

export const track = (name: string, properties?: {}): void => {
  window.analytics.track(name, properties);
  log({ event: SegmentEvent.TRACK, name, properties });
};
