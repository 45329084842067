export enum PersistKey {
  OTP_EXPIRY = 's8.super.otp.expiry',
  MEMBER = 's8.super.member',
  MEMBER_BALANCE = 's8.super.member.balance',
  INVESTMENT_OPTIONS = 's8.super.investment.options',
  FUTURE_CASH_ALLOCATIONS = 's8.super.investment.future_cash_allocation',
  UNIT_PRICE = 's8.super.investment.unit_price',
  DOCUMENT_SUMMARIES = 's8.super.member.document_summaries',
  BENEFICIARIES = 's8.super.member.beneficiaries',
  TRANSACTIONS = 's8.super.member.transactions',
  SIGN_UP = 's8.super.signup.member',
}

export const retrieve = (key: PersistKey): string | null =>
  window.localStorage.getItem(key);

export const store = (key: PersistKey, value: string): void => {
  window.localStorage.setItem(key, value);
};

export const remove = (key: PersistKey): void => {
  window.localStorage.removeItem(key);
};

export const clear = (): void => {
  window.localStorage.clear();
};

export const removeCognitoKeys = (): void => {
  try {
    Object.keys(window.localStorage)
      .filter(key => key.startsWith('CognitoIdentityServiceProvider'))
      .forEach(key => window.localStorage.removeItem(key));
  } catch {
    // okay
  }
};

export const removeUserKeys = (): void => {
  try {
    Object.keys(window.localStorage)
      .filter(key => key.startsWith('s8.') && key !== PersistKey.SIGN_UP)
      .forEach(key => window.localStorage.removeItem(key));
  } catch {
    // okay
  }
};
