import { track, TrackingEvent } from 'helpers/analytics';
import { config } from 'helpers/config';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';

interface Intercom {
  pop(): void;
}

// Ref: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
export interface IntercomSettings {
  app_id: string;
  user_id?: string;
  name?: string;
  phone?: string;
  created_at?: number;
}

declare global {
  interface Window {
    Intercom: (event: IntercomEvent, settings?: IntercomSettings) => void;
    intercomSettings: {};
  }
}

export enum IntercomEvent {
  BOOT = 'boot',
  SHUTDOWN = 'shutdown',
  SHOW = 'show',
  HIDE = 'hide',
  UPDATE = 'update',
}

const IntercomContext = createContext<Intercom>({
  pop: () => {},
});

export const IntercomProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  useEffect(() => {
    if (!window.Intercom) {
      window.intercomSettings = {
        app_id: config.intercomAppId,
      };

      // Intercom interface enforces the below
      const q = [] as [IntercomEvent][];
      const intercom = Object.assign(
        (...args: [IntercomEvent]): void => {
          intercom.c(...args);
        },
        {
          q,
          c: (...args: [IntercomEvent]): void => {
            q.push(args);
          },
        },
      );

      window.Intercom = intercom;
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://widget.intercom.io/widget/' + config.intercomAppId;
      document.head.appendChild(script);
      window.Intercom(IntercomEvent.BOOT, {
        app_id: config.intercomAppId,
      });
    }

    return (): void => window.Intercom(IntercomEvent.SHUTDOWN);
  }, []);

  const pop = (): void => {
    if (window.Intercom !== undefined) {
      track(TrackingEvent.INTERCOM, { time: Date.now() });
      window.Intercom(IntercomEvent.SHOW);
    }
  };

  return (
    <IntercomContext.Provider value={{ pop }}>
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercom = (): Intercom => useContext(IntercomContext);
