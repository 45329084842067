import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { CenteredLayout } from 'components/centered-layout';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { commonValidationRules } from 'helpers/validation';
import React, { FC } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  BODY,
  BODY_SMALL,
  INPUT_MARGIN,
  META,
  ShadeColor,
  Spacing,
} from 'styles';

import { ExternalRoutes, Routes } from './routes';

export const TaxFileNumber: FC<RouteComponentProps> = (): JSX.Element => {
  const notifications = useNotifications();
  const {
    sargonStore: { updateMember },
  } = useStores();

  const { register, errors, handleSubmit } = useForm<{
    taxId: string;
  }>({
    submitFocusError: true,
  });

  return (
    <CenteredLayout size="large">
      <div className={css(topMargin, center)}>
        <h1>Tax file number (TFN)</h1>

        <p className={css(copy)}>
          You can provide Spaceship with your TFN to help manage your Spaceship
          super account
        </p>
      </div>
      <form
        onSubmit={handleSubmit(async memberRequest => {
          try {
            await updateMember(memberRequest);
            await navigate(Routes.DASHBOARD);
            notifications.popToast({
              level: 'success',
              message: 'Your tax file number has been updated.',
            });
          } catch (error) {
            notifications.popToast({
              level: 'error',
              message: error.message,
            });
          }
        })}
      >
        <Input
          className="fs-hide"
          margin={INPUT_MARGIN}
          type="text"
          name="taxId"
          placeholder="Your tax file number"
          ref={register(commonValidationRules.taxFileNumber)}
          errorMessage={errors.taxId && errors.taxId.message}
          maxLength={9}
        />
        <div className={css(center)}>
          <Button
            type="submit"
            trackingProperties={{
              name: 'account_tfn_submit',
            }}
          >
            Continue
          </Button>
        </div>
      </form>
      <div className={css(BODY_SMALL, center)}>
        <p>
          Help,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={ExternalRoutes.ATO_LOST_TFN}
          >
            I can&apos;t remember my TFN
          </a>
        </p>
      </div>
      <div className={css(disclaimer)}>
        <h4>DISCLAIMER</h4>
        <p>
          The Trustee for Spaceship is authorised to collect, use and disclose
          your TFN under the Superannuation Industry (Supervision) Act 1993.
        </p>
        <p>
          You don’t need to provide your TFN to Spaceship. However, providing it
          will have the following advantages:
        </p>
        <ul>
          <li>
            Spaceship will be able to accept all permitted types of
            contributions to your Spaceship Super account;
          </li>
          <li>
            you won’t pay more tax than you need to – other than the tax that
            may ordinarily apply. This affects both contributions to your
            Spaceship Super account and benefit payments when you start drawing
            down your super account; and
          </li>
          <li>
            it’ll make it much easier to find other super accounts in your name
            so that you receive all your super benefits when you retire.
          </li>
        </ul>
        <p>
          When your super monies are transferred into, or out of, your Spaceship
          Super account, the trustee of Spaceship Super may disclose your TFN to
          another super provider, unless you request in writing that you do not
          want Spaceship to disclose your TFN to any other super provider.
        </p>
      </div>
    </CenteredLayout>
  );
};

const topMargin: Interpolation = {
  marginTop: Spacing.LARGE,
};

const copy: Interpolation = {
  ...BODY,
  maxWidth: 400,
  marginBottom: Spacing.SMALL,
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const disclaimer: Interpolation = {
  ...BODY_SMALL,
  marginTop: Spacing.XX_LARGE,
  paddingTop: Spacing.LARGE,
  borderTop: '1px solid #68758a',
  marginBottom: Spacing.LARGE,

  h4: {
    ...META,
    color: ShadeColor.SHADE_2,
    textTransform: 'uppercase',
  },
};
