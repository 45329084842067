import { css, Interpolation } from 'emotion';
import React, { ReactNode } from 'react';
import {
  BorderRadius,
  BorderWidth,
  BOX_SHADOW_PRIMARY,
  BOX_SHADOW_SECONDARY,
  BOX_SHADOW_TERTIARY,
  CommonColor,
  Margin,
  ShadeColor,
} from 'styles';

type CardType = 'primary' | 'secondary' | 'tertiary' | 'none';
type CardBorderRadius = BorderRadius.SMALL | BorderRadius.MEDIUM;

interface Props {
  children: ReactNode;
  type?: CardType;
  borderRadius?: CardBorderRadius;
  margin?: Margin;
}

export const Card = ({
  type = 'primary',
  borderRadius = BorderRadius.SMALL,
  margin,
  children,
}: Props): JSX.Element => (
  <div
    className={css(baseStyle, typeStyles[type], {
      borderRadius,
      ...margin,
    })}
  >
    {children}
  </div>
);

const baseStyle: Interpolation = {
  backgroundColor: CommonColor.SECONDARY,
  border: `${BorderWidth.SMALL}px solid ${ShadeColor.SHADE_2}`,
};

const typeStyles: { [t in CardType]: Interpolation } = {
  primary: {
    boxShadow: BOX_SHADOW_PRIMARY,
  },
  secondary: {
    boxShadow: BOX_SHADOW_SECONDARY,
  },
  tertiary: {
    boxShadow: BOX_SHADOW_TERTIARY,
  },
  none: {
    boxShadow: 'none',
  },
};
