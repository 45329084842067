import Tippy from '@tippy.js/react';
import { css, Interpolation } from 'emotion';
import { Data as PopperData } from 'popper.js';
import React, { ReactElement } from 'react';
import {
  BorderRadius,
  BOX_SHADOW_SECONDARY,
  CommonColor,
  FontSize,
  FontWeight,
  LineHeight,
  ShadeColor,
  Spacing,
} from 'styles';
import { Placement as TippyPlacement } from 'tippy.js';

type TooltipPlacement =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left';

interface Props {
  children: ReactElement;
  placement?: TooltipPlacement;
  error?: boolean;
  text: string;
}

export const Tooltip = ({
  children,
  placement = 'top',
  error = false,
  text,
}: Props): JSX.Element => (
  <Tippy
    className={css(tooltipStyle)}
    content={text}
    duration={[TOOLTIP_ANIMATE_IN_DURATION, TOOLTIP_ANIMATE_OUT_DURATION]}
    maxWidth={TOOLTIP_MAX_WIDTH}
    popperOptions={popperOptions}
    placement={
      placement
        .replace('-left', '-start')
        .replace('-right', '-end') as TippyPlacement
    }
    showOnCreate={error}
  >
    {children}
  </Tippy>
);

const TOOLTIP_ANIMATE_IN_DURATION = 150;
const TOOLTIP_ANIMATE_OUT_DURATION = 75;
const TOOLTIP_MAX_WIDTH = 170;
const TOOLTIP_COLOR = ShadeColor.SHADE_2;
const TOOLTIP_ARROW_CLASSNAME = '.tippy-arrow';
const TOOLTIP_HIDDEN_SELECTOR = '&[data-state=hidden]';
const TOOLTIP_HIDE_TRANSLATE_DISTANCE = 10;
const ARROW_HEIGHT = 8;
const ARROW_WIDTH = 12;

const popperOptions = {
  modifiers: {
    popper: {
      order: 1,
      enabled: true,
      fn: (data: PopperData): PopperData => ({
        ...data,
        styles: {
          ...data.styles,
          ...tooltipPopperStyle,
        },
      }),
    },
  },
};

const tooltipPopperStyle = {
  pointerEvents: 'none',
  maxWidth: 'calc(100vw - 10px)',
  transitionTimingFunction: 'cubic-bezier(.165, .84, .44, 1)',
  transitionProperty: 'transform',
};

const tooltipStyle: Interpolation = {
  backgroundColor: TOOLTIP_COLOR,
  borderRadius: BorderRadius.X_SMALL,
  boxShadow: BOX_SHADOW_SECONDARY,
  color: CommonColor.WHITE,
  fontSize: FontSize.META,
  fontWeight: FontWeight.SEMI_BOLD,
  lineHeight: LineHeight.META,
  outline: 'none',
  position: 'relative',
  transitionProperty: 'visibility, opacity, transform',
  textAlign: 'center',

  [TOOLTIP_HIDDEN_SELECTOR]: {
    opacity: 0,
  },

  '.tippy-content': {
    padding: Spacing.X_SMALL,
  },

  [TOOLTIP_ARROW_CLASSNAME]: {
    position: 'absolute',
    borderColor: 'transparent',
    borderStyle: 'solid',
  },

  ['&[data-placement^=top]']: {
    [TOOLTIP_HIDDEN_SELECTOR]: {
      transform: `translateY(${TOOLTIP_HIDE_TRANSLATE_DISTANCE}px)`,
    },

    [TOOLTIP_ARROW_CLASSNAME]: {
      borderWidth: `${ARROW_HEIGHT}px ${ARROW_WIDTH / 2}px 0`,
      borderTopColor: TOOLTIP_COLOR,
      margin: '0 3px',
      transformOrigin: '50% 0',
      bottom: '-7px',
    },
  },

  ['&[data-placement^=bottom]']: {
    [TOOLTIP_HIDDEN_SELECTOR]: {
      transform: `translateY(-${TOOLTIP_HIDE_TRANSLATE_DISTANCE}px)`,
    },

    [TOOLTIP_ARROW_CLASSNAME]: {
      borderWidth: `0 ${ARROW_WIDTH / 2}px ${ARROW_HEIGHT}px`,
      borderBottomColor: TOOLTIP_COLOR,
      margin: '0 3px',
      transformOrigin: '50% 7px',
      top: '-7px',
    },
  },

  ['&[data-placement=left]']: {
    [TOOLTIP_HIDDEN_SELECTOR]: {
      transform: `translateX(${TOOLTIP_HIDE_TRANSLATE_DISTANCE}px)`,
    },

    [TOOLTIP_ARROW_CLASSNAME]: {
      borderWidth: `${ARROW_WIDTH / 2}px 0 ${ARROW_WIDTH /
        2}px ${ARROW_HEIGHT}px`,
      borderLeftColor: TOOLTIP_COLOR,
      margin: '3px 0',
      transformOrigin: '0 50%',
      right: '-7px',
    },
  },

  ['&[data-placement=right]']: {
    [TOOLTIP_HIDDEN_SELECTOR]: {
      transform: `translateX(-${TOOLTIP_HIDE_TRANSLATE_DISTANCE}px)`,
    },

    [TOOLTIP_ARROW_CLASSNAME]: {
      borderWidth: `${ARROW_WIDTH / 2}px ${ARROW_HEIGHT}px ${ARROW_WIDTH /
        2}px 0`,
      borderRightColor: TOOLTIP_COLOR,
      margin: '3px 0',
      transformOrigin: '7px 50%',
      left: '-7px',
    },
  },

  [`&[data-placement=top-start] ${TOOLTIP_ARROW_CLASSNAME}, [data-placement=bottom-start] ${TOOLTIP_ARROW_CLASSNAME}`]: {
    marginRight: '50%',
  },

  [`&[data-placement=top-end] ${TOOLTIP_ARROW_CLASSNAME}, [data-placement=bottom-end] ${TOOLTIP_ARROW_CLASSNAME}`]: {
    marginLeft: '50%',
  },
};
