import { Link, navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { css, Interpolation } from 'emotion';
import { useIntercom } from 'helpers/contexts/intercom';
import React, { FC } from 'react';
import { AlertTriangle, Eye, FileText } from 'react-feather';
import {
  BODY,
  CommonColor,
  IconSize,
  MEDIA_QUERY_MOBILE_MAX,
  Spacing,
} from 'styles';

import { Routes } from './routes';

export const TransferNoFunds: FC<RouteComponentProps> = (): JSX.Element => {
  const intercom = useIntercom();

  return (
    <div className={css(container)}>
      <ColumnLayout>
        <Column verticalCenter={true}>
          <h1>No funds found</h1>
          <div className={css(iconAndCopyRow)}>
            <AlertTriangle size={IconSize.MEDIUM} color={CommonColor.WHITE} />
            <p className={css(copy)}>
              We couldn&apos;t find any funds with your details. If you think
              this is wrong please <a onClick={intercom.pop}>contact support</a>
              , we&apos;d love to hear from you!
            </p>
          </div>
          <div className={css(iconAndCopyRow)}>
            <FileText size={IconSize.MEDIUM} color={CommonColor.WHITE} />
            <p className={css(copy)}>
              Don&apos;t worry, you can still add funds to your account by
              forwarding your employer your{' '}
              <Link to={Routes.DASHBOARD}>Employer Contribution Form</Link>.
            </p>
          </div>
          <div className={css(iconAndCopyRow)}>
            <Eye size={IconSize.MEDIUM} color={CommonColor.WHITE} />
            <p className={css(copy)}>
              Already set up your employer contributions? Why not kill some time
              between contributions by reading our{' '}
              <a href="http://spaceship.com.au/blog">blog</a>.
            </p>
          </div>

          <div className={css(ctaContainer)}>
            <Button
              onClick={(): void => {
                navigate(Routes.DASHBOARD);
              }}
              trackingProperties={{
                name: 'transfer_nofunds_dashboard',
              }}
            >
              Go to dashboard
            </Button>
          </div>
        </Column>
        <Column display="desktop-only" verticalCenter={true}>
          <Illustration type="rocket" />
        </Column>
      </ColumnLayout>
    </div>
  );
};

const container: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    marginBottom: Spacing.XXX_LARGE,
    marginTop: Spacing.MEDIUM,
  },

  h1: {
    textAlign: 'center',
    marginBottom: Spacing.LARGE,

    [MEDIA_QUERY_MOBILE_MAX]: {
      marginBottom: Spacing.SMALL,
    },
  },
};

const iconAndCopyRow: Interpolation = {
  alignItems: 'center',
  display: 'flex',

  ':not(:first-of-type)': {
    marginTop: Spacing.MEDIUM,
  },

  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'block',

    svg: {
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      marginLeft: Spacing.XXX_SMALL,
      marginRight: Spacing.X_SMALL,
    },
  },
};

const copy: Interpolation = {
  ...BODY,
  flex: 1,
  marginLeft: Spacing.MEDIUM,

  [MEDIA_QUERY_MOBILE_MAX]: {
    marginLeft: 0,
    lineHeight: `${Spacing.LARGE}px`,
    display: 'inline',
  },
};

const ctaContainer: Interpolation = {
  marginTop: Spacing.LARGE,

  button: {
    margin: '0 auto',
  },
};
