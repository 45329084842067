import { css, cx, Interpolation } from 'emotion';
import { track, TrackingEvent } from 'helpers/analytics';
import React, { forwardRef, RefForwardingComponent } from 'react';
import {
  BorderRadius,
  BorderWidth,
  CommonColor,
  FontSize,
  FontWeight,
  Margin,
  PrimaryColor,
  ShadeColor,
  Spacing,
  TRANSITION_DURATION,
} from 'styles';

interface Option {
  value?: string;
  label: string;
}

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string;
  options: Option[];
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  margin?: Margin;
}

const Dropdown: RefForwardingComponent<HTMLSelectElement, Props> = (
  {
    disabled,
    errorMessage,
    margin,
    name,
    options,
    placeholder,
    onFocus,
    ...rest
  },
  ref,
): JSX.Element => (
  <div
    className={cx(css(dropdown, { ...margin }), {
      error: !!errorMessage && !disabled,
    })}
  >
    <select
      {...{ ...rest, id: name, name, disabled, ref }}
      onFocus={(e: React.FocusEvent<HTMLSelectElement>): void => {
        track(TrackingEvent.FOCUS_INPUT, { name });
        onFocus && onFocus(e);
      }}
    >
      {options.map(({ value, label }) => (
        <option key={label} value={value === undefined ? label : value}>
          {label}
        </option>
      ))}
    </select>
    <label htmlFor={name}>{(!disabled && errorMessage) || placeholder}</label>
    <span className={css(arrow)} />
  </div>
);

const DEFAULT_BORDER = BorderWidth.SMALL;
const FOCUS_BORDER = BorderWidth.MEDIUM;
const DEFAULT_PADDING = Spacing.SMALL;
const FOCUS_PADDING = DEFAULT_PADDING - FOCUS_BORDER + DEFAULT_BORDER;
const ARROW_SIZE = 5.66;

const arrow: Interpolation = {
  border: `${BorderWidth.MEDIUM}px solid ${ShadeColor.SHADE_2}`,
  borderLeft: 0,
  borderTop: 0,
  display: 'inline-block',
  height: ARROW_SIZE,
  position: 'absolute',
  right: DEFAULT_PADDING,
  top: `calc(50% - ${ARROW_SIZE / 2}px)`,
  transform: 'rotate(45deg) translateY(-50%)',
  width: ARROW_SIZE,
};

const dropdown: Interpolation = {
  flex: 1,
  backgroundColor: 'inherit',
  fontWeight: FontWeight.SEMI_BOLD,
  position: 'relative',

  select: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: `${DEFAULT_BORDER}px solid ${ShadeColor.SHADE_2}`,
    borderRadius: BorderRadius.SMALL,
    color: CommonColor.TEXT,
    fontFamily: 'inherit',
    fontSize: FontSize.BODY,
    fontWeight: FontWeight.SEMI_BOLD,
    height: Spacing.XX_LARGE,
    outline: 'none',
    paddingLeft: DEFAULT_PADDING,
    paddingRight: DEFAULT_PADDING,
    paddingTop: Spacing.MEDIUM,
    width: '100%',

    ':hover, :focus': {
      borderWidth: FOCUS_BORDER,
      paddingLeft: FOCUS_PADDING,
      paddingRight: FOCUS_PADDING,
    },

    ':hover': {
      borderColor: PrimaryColor.GREEN,
    },

    ':focus': {
      borderColor: PrimaryColor.GREEN,
    },

    ':disabled': {
      borderColor: ShadeColor.SHADE_4,
    },

    option: {
      color: CommonColor.BLACK,
    },
  },

  label: {
    color: CommonColor.TEXT,
    fontSize: FontSize.META,
    left: 0,
    paddingLeft: Spacing.SMALL,
    position: 'absolute',
    top: Spacing.X_SMALL,
    transitionDuration: TRANSITION_DURATION,
  },

  '&.error': {
    select: {
      borderColor: CommonColor.RED,
      color: ShadeColor.SHADE_2,
    },

    label: {
      color: CommonColor.RED,
    },
  },
};

const ForwardedDropdown = forwardRef<HTMLSelectElement, Props>(Dropdown);
export { ForwardedDropdown as Dropdown };
