import iconPhoneHandHoldSvg from 'assets/icons/phone-hand-hold.svg';
import imgDownloadAppBenefitsPng from 'assets/images/download-app-benefits.png';
import imgGooglePlaySvg from 'assets/images/google-play-dark.svg';
import imgIOSAppStoreSvg from 'assets/images/ios-app-store-dark.svg';
import imgQrCodeLoginOnboarding from 'assets/images/qr-code-login-onboarding.svg';
import imgQrCodeLogin from 'assets/images/qr-code-login.svg';
import imgQrCodeSignup from 'assets/images/qr-code-signup.svg';
import { css, cx, Interpolation } from 'emotion';
import { ExternalRoutes } from 'pages/routes';
import React from 'react';
import {
  CommonColor,
  FontWeight,
  hex2rgba,
  MEDIA_QUERY_DESKTOP_MIN,
  MEDIA_QUERY_MOBILE_MAX,
  MEDIA_QUERY_TABLET_MAX,
  MEDIA_QUERY_TABLET_MIN,
  Spacing,
} from 'styles';

interface SpaceshipAppPromotionProps {
  variant: 'login' | 'signup' | 'onboarding';
}

export const SpaceshipAppPromotionBanner: React.FC<SpaceshipAppPromotionProps> = ({
  variant,
}) => {
  return (
    <div className={css(bannerContainer)}>
      <div className={css(appStoreColumn)}>
        <img src={iconPhoneHandHoldSvg} className={css(iconPhoneHandHold)} />

        <p className={css(copy)}>
          Download the Spaceship app today for the best experience.
        </p>

        <AppStoreButtons />
      </div>

      <div className={css(qrCodeContainer)}>
        <QrCodeInstruction arrowDirection="right" />

        <img
          src={variant === 'login' ? imgQrCodeLogin : imgQrCodeSignup}
          className={css(qrCode)}
        />
      </div>
    </div>
  );
};

export const SpaceshipAppPromotionWithBenefits: React.FC<SpaceshipAppPromotionProps> = ({
  variant,
}) => {
  let qrCodeImg;

  switch (variant) {
    case 'login':
      qrCodeImg = imgQrCodeLogin;
      break;
    case 'onboarding':
      qrCodeImg = imgQrCodeLoginOnboarding;
      break;
    case 'signup':
    default:
      qrCodeImg = imgQrCodeSignup;
      break;
  }

  return (
    <>
      <div className={css(benefitsContainer)}>
        <div className={css(benefitsColumn)}>
          <img
            src={imgDownloadAppBenefitsPng}
            className={css(imgDownloadAppBenefits)}
          />
        </div>

        <div className={cx(css(benefitsColumn), css(mobileHidden))}>
          <div
            className={cx(css(qrCodeContainer), css(benefitsQrCodeContainer))}
          >
            <img src={qrCodeImg} className={css(qrCode)} />

            <QrCodeInstruction arrowDirection="left" />
          </div>

          <div className={css(desktopVisible)}>
            <BenefitsCopyAndStoreButtons />
          </div>
        </div>
      </div>

      <div className={css(desktopHidden)}>
        <BenefitsCopyAndStoreButtons />
      </div>
    </>
  );
};

const QrCodeInstruction: React.FC<{ arrowDirection: 'left' | 'right' }> = ({
  arrowDirection,
}) => (
  <div
    className={css(
      arrowDirection === 'left'
        ? qrCodeInstructionLeft
        : qrCodeInstructionRight,
    )}
  >
    <svg
      width="63"
      height="19"
      viewBox="0 0 63 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={css(
        arrowDirection === 'left' ? scanCodeArrowLeft : scanCodeArrowRight,
      )}
    >
      <path
        d="M62.9423 9.56912C63.1272 10.0895 62.8553 10.6613 62.3349 10.8463L53.8545 13.8601C53.3341 14.045 52.7623 13.7731 52.5773 13.2527C52.3924 12.7323 52.6643 12.1605 53.1847 11.9755L60.7229 9.29659L58.0439 1.75847C57.859 1.23807 58.1309 0.666279 58.6513 0.481336C59.1717 0.296394 59.7435 0.568336 59.9285 1.08873L62.9423 9.56912ZM61.5705 10.8071C41.329 1.18031 26.3863 3.31469 16.5358 7.7534C11.591 9.98157 7.89791 12.8051 5.44361 15.0688C4.21701 16.2001 3.30198 17.1897 2.69698 17.8917C2.39455 18.2427 2.16984 18.5215 2.02264 18.7099C1.94905 18.8041 1.89486 18.8757 1.86004 18.9223C1.84264 18.9456 1.83007 18.9627 1.82235 18.9733C1.8185 18.9786 1.81585 18.9822 1.81441 18.9842C1.81369 18.9852 1.81327 18.9858 1.81316 18.986C1.8131 18.986 1.81324 18.9859 1.81322 18.9859C1.81343 18.9856 1.81373 18.9852 1 18.4039C0.186272 17.8227 0.186718 17.8221 0.187241 17.8213C0.187519 17.8209 0.188118 17.8201 0.188671 17.8193C0.189777 17.8178 0.191193 17.8158 0.192917 17.8134C0.196362 17.8087 0.201038 17.8022 0.206951 17.7941C0.218769 17.7779 0.235519 17.7552 0.257206 17.7261C0.300571 17.668 0.363693 17.5847 0.446594 17.4786C0.612385 17.2664 0.857399 16.9627 1.18193 16.5861C1.83083 15.8331 2.79861 14.7876 4.08764 13.5986C6.66459 11.2218 10.534 8.26421 15.7142 5.92997C26.1137 1.24387 41.671 -0.871726 62.4295 9.00092L61.5705 10.8071Z"
        fill="#C5E2FF"
      />
    </svg>

    <p className={css(scanCodeCopy)}>
      Scan code
      <br />
      to start
    </p>
  </div>
);

const AppStoreButtons: React.FC = () => (
  <div className={css(storeLinksContainer)}>
    <a
      href={ExternalRoutes.GOOGLE_PLAY}
      target="_blank"
      rel="noopener noreferrer"
      className={css(storeLink)}
    >
      <img src={imgGooglePlaySvg} className={css(storeSvg)} alt="Google Play" />
    </a>

    <a
      href={ExternalRoutes.IOS_APP_STORE}
      target="_blank"
      rel="noopener noreferrer"
      className={css(storeLink)}
    >
      <img
        src={imgIOSAppStoreSvg}
        className={css(storeSvg)}
        alt="Apple Store"
      />
    </a>
  </div>
);

const BenefitsCopyAndStoreButtons: React.FC = () => (
  <div className={css(benefitsCopyAndStoreButtonsContainer)}>
    <p className={cx(css(copy), css(benefitsCopy))}>
      Download the Spaceship app today for 24/7 access to your investments.
    </p>
    <AppStoreButtons />
  </div>
);

const bannerContainer: Interpolation = {
  backgroundColor: hex2rgba(CommonColor.WHITE, 0.03),
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  marginTop: Spacing.X_LARGE,
  padding: `${Spacing.X_LARGE}px ${Spacing.SMALL}px ${Spacing.XX_LARGE}px`,
  width: '100%',

  [MEDIA_QUERY_TABLET_MIN]: {
    alignItems: 'center',
    padding: Spacing.XX_LARGE,
  },
};

const benefitsContainer: Interpolation = {
  display: 'flex',
  width: '100%',
};

const benefitsColumn: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',

  [MEDIA_QUERY_TABLET_MIN]: {
    marginLeft: Spacing.MEDIUM,
    marginRight: Spacing.MEDIUM,
  },

  [MEDIA_QUERY_DESKTOP_MIN]: {
    marginLeft: Spacing.X_LARGE,
    marginRight: Spacing.X_LARGE,
  },
};

const imgDownloadAppBenefits: Interpolation = {
  maxWidth: 300,
  width: '100%',

  [MEDIA_QUERY_DESKTOP_MIN]: {
    maxWidth: 480,
  },
};

const appStoreColumn: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',

  [MEDIA_QUERY_TABLET_MIN]: {
    alignItems: 'flex-start',
  },
};

const iconPhoneHandHold: Interpolation = {
  height: '48px',
  width: '48px',

  [MEDIA_QUERY_TABLET_MIN]: {
    display: 'none',
  },
};

const copy: Interpolation = {
  color: CommonColor.WHITE,
  fontSize: '24px',
  lineHeight: '36px',
  marginBottom: Spacing.MEDIUM,
  marginTop: Spacing.SMALL,
  textAlign: 'center',

  [MEDIA_QUERY_TABLET_MIN]: {
    fontSize: '28px',
    lineHeight: '40px',
    marginTop: 0,
    textAlign: 'left',
  },
};

const benefitsCopy: Interpolation = {
  marginBottom: Spacing.LARGE,

  [MEDIA_QUERY_TABLET_MIN]: {
    textAlign: 'center',
  },
};

const benefitsCopyAndStoreButtonsContainer: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: Spacing.MEDIUM,

  [MEDIA_QUERY_TABLET_MIN]: {
    marginTop: Spacing.SMALL,
  },

  [MEDIA_QUERY_DESKTOP_MIN]: {
    marginTop: Spacing.LARGE,
  },
};

const STORE_LINKS_ROW_BREAKPOINT = '@media(min-width: 370px)';

const storeLinksContainer: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [STORE_LINKS_ROW_BREAKPOINT]: {
    flexDirection: 'row',
  },
};

const storeLink: Interpolation = {
  display: 'inline-block',

  [':not(:first-child)']: {
    marginTop: Spacing.XX_SMALL,

    [STORE_LINKS_ROW_BREAKPOINT]: {
      marginLeft: Spacing.SMALL,
      marginTop: 0,
    },

    [MEDIA_QUERY_DESKTOP_MIN]: {
      marginLeft: Spacing.MEDIUM,
    },
  },
};

const storeSvg: Interpolation = {
  height: '48px',
};

const qrCodeContainer: Interpolation = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: Spacing.MEDIUM,

  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },

  [MEDIA_QUERY_DESKTOP_MIN]: {
    marginLeft: Spacing.X_LARGE,
  },
};

const benefitsQrCodeContainer: Interpolation = {
  justifyContent: 'center',

  [MEDIA_QUERY_DESKTOP_MIN]: {
    marginLeft: 130,
  },
};

const QR_CODE_SIZE_TABLET = 160;
const QR_CODE_SIZE_DESKTOP = 180;

const qrCode: Interpolation = {
  height: QR_CODE_SIZE_TABLET,
  width: QR_CODE_SIZE_TABLET,

  [MEDIA_QUERY_DESKTOP_MIN]: {
    height: QR_CODE_SIZE_DESKTOP,
    width: QR_CODE_SIZE_DESKTOP,
  },
};

const scanCodeCopy: Interpolation = {
  fontWeight: FontWeight.BOLD,
  marginTop: Spacing.XX_SMALL,
  marginBottom: 0,
  textAlign: 'center',
};

const scanCodeArrowRight: Interpolation = {
  marginLeft: Spacing.XX_LARGE,
};

const scanCodeArrowLeft: Interpolation = {
  marginRight: Spacing.XX_LARGE,
  transform: 'scale(-1,1)',
};

const qrCodeInstructionRight: Interpolation = {
  marginRight: Spacing.SMALL,
};

const qrCodeInstructionLeft: Interpolation = {
  marginLeft: Spacing.SMALL,
};

const desktopHidden: Interpolation = {
  [MEDIA_QUERY_DESKTOP_MIN]: {
    display: 'none',
  },
};

const desktopVisible: Interpolation = {
  [MEDIA_QUERY_TABLET_MAX]: {
    display: 'none',
  },
};

const mobileHidden: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    display: 'none',
  },
};
