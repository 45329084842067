import { MemberCreate } from '@sargon/api-client';
import { PersistKey, remove, retrieve, store } from 'helpers/persist';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

const SignupContext = createContext<
  [MemberCreate | undefined, (data: MemberCreate) => void]
>([undefined, (): void => console.error('member create context not provided')]);

export const SignupContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [data, setData] = useState<MemberCreate>();

  const clearPersistData = (): void => remove(PersistKey.SIGN_UP);

  useEffect(() => {
    const member = retrieve(PersistKey.SIGN_UP);
    setData(member ? JSON.parse(member) : {});
    return clearPersistData;
  }, []);

  const setPersistData = (data: MemberCreate): void => {
    setData(data);
    store(PersistKey.SIGN_UP, JSON.stringify(data));
  };

  return (
    <SignupContext.Provider value={[data, setPersistData]}>
      {children}
    </SignupContext.Provider>
  );
};

export const useSignupContext = (): [
  MemberCreate | undefined,
  (data: MemberCreate) => void,
] => useContext(SignupContext);
