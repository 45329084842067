import { createContext, useContext } from 'react';

import { SargonStore } from './sargon';

class RootStore {
  public sargonStore: SargonStore;

  public constructor() {
    this.sargonStore = new SargonStore();
  }
}

const singletonRootStore = new RootStore();
export const RootStoreContext = createContext(singletonRootStore);
export const useStores = (): RootStore => useContext(RootStoreContext);
