import {
  BeneficiaryBase,
  BeneficiaryIntent,
  DocumentDetails,
  DocumentSummary,
  FundSearchOperation,
  FundSearchResult,
  InlineResponse20010Data,
  InlineResponse20023Data,
  InvestmentAllocation,
  InvestmentBalance,
  InvestmentBalanceAllocation,
  InvestmentOption,
  Member,
  MemberBalance,
  MemberCreate,
  MemberUpdate,
  OtpResource,
  RequestAccepted,
  RolloverRequest,
  SargonApiClient,
  Transaction,
  UnitPrice,
} from '@sargon/api-client';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import { config } from 'helpers/config';
import {
  clear,
  PersistKey,
  removeCognitoKeys,
  removeUserKeys,
  retrieve,
  store,
} from 'helpers/persist';
import { HttpStatusCode } from 'helpers/status-codes';
import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import moment from 'moment';

import { clearIdentifiedUser, identifyUser } from './helpers';

interface SargonApiErrorMessage {
  status: number;
  detail: string;
}

class SargonApiError extends Error {
  public httpStatusCode: HttpStatusCode;
  public errors?: SargonApiErrorMessage[];

  constructor(statusCode: HttpStatusCode, errors?: SargonApiErrorMessage[]) {
    const message = errors && errors[0] ? errors[0].detail : undefined;
    super(message);

    this.httpStatusCode = statusCode;
    this.errors = errors;
  }
}

enum LoginErrorCodes {
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
}

class AuthApiError extends Error {
  public code: string;

  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }

  public get passwordResetRequired(): boolean {
    return this.code === LoginErrorCodes.PASSWORD_RESET_REQUIRED;
  }
}

const getApiErrors = async (
  resp: Response,
): Promise<SargonApiErrorMessage[]> => {
  try {
    const errJson = await resp.clone().json();
    return errJson.errors || [];
  } catch (e) {
    return [];
  }
};

const isOtpError = async (resp: Response): Promise<boolean> => {
  const errors = await getApiErrors(resp);
  return !!errors.find(
    (e: { detail?: string }) => e.detail === 'OTP Session Required',
  );
};

export const amplifyConfig = {
  Auth: {
    region: 'ap-southeast-2',
    identityPoolRegion: 'ap-southeast-2',
    userPoolId: config.amplify.userPoolId,
    userPoolWebClientId: config.sargon.clientId,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  storage: sessionStorage,
};

Amplify.configure(amplifyConfig);

const client = new SargonApiClient({
  basePath: config.sargon.basePath,
  clientId: config.sargon.clientId,
  clientSecret: '',
  version: 'v1',
});

const sleep = (milliseconds: number): Promise<unknown> => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

type LoginState = 'logged-out' | 'logged-in' | 'indeterminate';

type Scope = 'read' | 'write';

export class SargonStore {
  public constructor() {
    this.loadPersistedData();

    reaction(
      () => this.member,
      member => {
        if (member.id) {
          identifyUser(member);
        }
      },
    );
    reaction(
      () => this.token,
      token => {
        if (!token) {
          clearIdentifiedUser();
        }
      },
    );
  }

  @observable
  private token?: string;

  @computed
  public get loginState(): LoginState {
    switch (this.token) {
      case '':
        return 'logged-out';
      case undefined:
        return 'indeterminate';
      default:
        return 'logged-in';
    }
  }

  @observable
  public pendingOTPAuthorisation = false;

  @observable
  public otpExpiry?: Date;

  @observable
  public member: Member = {};

  @observable
  public investmentOptions: InvestmentOption[] = [];

  @observable
  public futureCashAllocations: InvestmentAllocation[] = [];

  @observable
  public documentSummaries: DocumentSummary[] = [];

  @observable
  public memberBalance: MemberBalance = {};

  @observable
  public transactions: Transaction[] = [];

  @observable
  public fundSearchResults?: FundSearchResult;

  @observable
  public rolloverRequests?: InlineResponse20023Data;

  @observable
  public beneficiaries?: BeneficiaryBase[];

  @observable
  public lastScopeRequest: Scope = 'read';

  @observable
  public latestUnitPrice?: UnitPrice;

  @computed
  public get hasOTP(): boolean {
    return !!this.otpExpiry && this.otpExpiry >= new Date();
  }

  @computed
  public get balance(): string | number {
    return this.memberBalance &&
      this.memberBalance.closingBalance &&
      this.memberBalance.closingBalance.amount
      ? this.memberBalance.closingBalance.amount / 100
      : '-';
  }

  @computed
  public get portfolio(): InvestmentAllocation | undefined {
    return this.futureCashAllocations.find(
      portfolio => portfolio.percent === 100,
    );
  }

  @computed
  public get portfolioDisplayName(): string {
    return this.portfolio && this.portfolio.displayName
      ? this.portfolio.displayName
      : '-';
  }

  @computed
  public get hasSubmittedTFN(): boolean {
    const submittedTaxIdStatuses = [
      Member.TaxIdStatusEnum.Supplied,
      Member.TaxIdStatusEnum.Verified,
    ];
    return (
      !!this.member.taxIdStatus &&
      submittedTaxIdStatuses.includes(this.member.taxIdStatus)
    );
  }

  public loadPersistedData = async (): Promise<void> => {
    try {
      const session = await Auth.currentSession();
      runInAction(() => {
        if (session) {
          this.token = session.getAccessToken().getJwtToken();
        }
      });
    } catch {
      this.token = '';
      removeCognitoKeys();
      removeUserKeys();
      return;
    }

    runInAction(() => {
      const otpExpiry = retrieve(PersistKey.OTP_EXPIRY);
      if (otpExpiry) {
        this.otpExpiry = new Date(otpExpiry);
      }

      const member = retrieve(PersistKey.MEMBER);
      if (member) {
        this.member = JSON.parse(member);
      }

      const memberBalance = retrieve(PersistKey.MEMBER_BALANCE);
      if (memberBalance) {
        this.memberBalance = JSON.parse(memberBalance);
      }

      const investmentOptions = retrieve(PersistKey.INVESTMENT_OPTIONS);
      if (investmentOptions) {
        this.investmentOptions = JSON.parse(investmentOptions);
      }

      const futureCashAllocations = retrieve(
        PersistKey.FUTURE_CASH_ALLOCATIONS,
      );
      if (futureCashAllocations) {
        this.futureCashAllocations = JSON.parse(futureCashAllocations);
      }

      const documentSummaries = retrieve(PersistKey.DOCUMENT_SUMMARIES);
      if (documentSummaries) {
        this.documentSummaries = JSON.parse(documentSummaries);
      }

      const beneficiaries = retrieve(PersistKey.BENEFICIARIES);
      if (beneficiaries) {
        this.beneficiaries = JSON.parse(beneficiaries);
      }

      const transactions = retrieve(PersistKey.TRANSACTIONS);
      if (transactions) {
        this.transactions = JSON.parse(transactions);
      }
    });
  };

  @action
  public clearPersistedData = (): void => {
    clear();

    this.member = {};
    this.memberBalance = {};
    this.investmentOptions = [];
    this.futureCashAllocations = [];
    this.documentSummaries = [];
    this.beneficiaries = undefined;
    this.transactions = [];
  };

  @action
  public setPendingOTPAuthorisation = (
    pendingOTPAuthorisation: boolean,
  ): void => {
    this.pendingOTPAuthorisation = pendingOTPAuthorisation;
  };

  public login = async (email: string, password: string): Promise<boolean> => {
    try {
      // IMPORTANT: The aws-amplify library will persist successful login
      // Failing to login will not clear the last successful login creds
      await this.logout();

      const user: CognitoUser = await Auth.signIn(
        email.toLowerCase(),
        password,
      );
      const session = user.getSignInUserSession();
      if (session) {
        runInAction(() => {
          this.token = session.getAccessToken().getJwtToken();
        });
      }
      return true;
    } catch (error) {
      switch (error.code) {
        case LoginErrorCodes.USER_NOT_CONFIRMED:
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          throw new AuthApiError(error.code, error.message);

        case LoginErrorCodes.PASSWORD_RESET_REQUIRED:
          // Mandatory password reset is required, this may be because they are
          // a legacy user or their account has been flagged for reset in
          // Cognito.
          throw new AuthApiError(error.code, error.message);

        case LoginErrorCodes.NOT_AUTHORIZED:
        case LoginErrorCodes.USER_NOT_FOUND:
          throw new AuthApiError(error.code, 'Invalid email or password.');

        default:
          throw new AuthApiError(error.code, error.message);
      }
    }
  };

  public logout = async (): Promise<void> => {
    await Auth.signOut();
    runInAction(() => {
      this.token = '';
    });
    this.clearPersistedData();
  };

  public forgotPassword = async (email: string): Promise<boolean> => {
    await Auth.forgotPassword(email.toLowerCase());
    return true;
  };

  public forgotPasswordSubmit = async (
    email: string,
    otp: string,
    newPassword: string,
  ): Promise<boolean> => {
    await Auth.forgotPasswordSubmit(email.toLowerCase(), otp, newPassword);
    return true;
  };

  @observable
  public otpLastRequested = moment(new Date(0));

  public requestOTP = async (scope: Scope): Promise<void> => {
    const secondsPassed = moment
      .duration(moment(new Date()).diff(this.otpLastRequested))
      .asSeconds();

    if (secondsPassed >= 5) {
      runInAction(() => (this.otpLastRequested = moment(new Date())));
      await client
        .oTP({ accessToken: this.token })
        .requestMemberOtp('me', { scope });
    }
  };

  public verifyOTP = async (
    xSuperOTP: string,
  ): Promise<OtpResource | undefined> => {
    try {
      const { data } = await client
        .oTP({ accessToken: this.token })
        .verifyMemberOtp('me', { xSuperOTP });
      if (data && data.expires) {
        runInAction(() => {
          this.pendingOTPAuthorisation = false;
          this.otpExpiry = new Date(String(data.expires));
          store(PersistKey.OTP_EXPIRY, String(data.expires));
        });
      }
      return data;
    } catch (err) {
      const errors = await getApiErrors(err);
      throw new SargonApiError(err.status, errors);
    }
  };

  public createMember = async (
    member: MemberCreate,
  ): Promise<Member | undefined> => {
    // Make sure we dont have any trailing user data
    await this.logout();

    try {
      member.email = member.email?.toLowerCase();
      member.phoneMobile = member.phoneMobile
        ? member.phoneMobile.replace(/\s/g, '')
        : '';
      // @ts-ignore Date object on MemberCreate is a bad idea and problematic
      member.birthDate = member.birthDate
        ? moment(member.birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : undefined;
      member.group = 'superweb';

      const { data } = await client.members().createMember(member);

      runInAction(() => {
        this.member = data || {};
        if (data) {
          store(PersistKey.MEMBER, JSON.stringify(data));
        }
      });

      if (member.email && member.password) {
        await this.login(member.email, member.password);
      }
      return data;
    } catch (err) {
      const errors = await getApiErrors(err);
      throw new SargonApiError(err.status, errors);
    }
  };

  public privileged = (scope: Scope = 'read') => <A extends {}[], R>(
    req: (...args: A) => Promise<R>,
  ): ((...args: A) => Promise<R>) => async (...args: A): Promise<R> => {
    runInAction(() => {
      this.lastScopeRequest = scope;
    });

    try {
      // Pause this request if there's already an in-flight authentication
      await when(() => !this.pendingOTPAuthorisation);
      return await req(...args);
    } catch (err) {
      if (await isOtpError(err)) {
        runInAction(() => (this.pendingOTPAuthorisation = true));
        // Ask for token so we can try again
        await this.requestOTP(scope);
        // Wait for customer to enter token and try again
        await when(() => !this.pendingOTPAuthorisation);
        return await req(...args);
      }
      const errors = await getApiErrors(err);
      if (errors.length) {
        throw new SargonApiError(err.status, errors);
      }
      throw err;
    }
  };

  public getMember = this.privileged()(
    async (): Promise<Member | undefined> => {
      const { data } = await client
        .members({ accessToken: this.token })
        .getMember('me');

      if (data) {
        runInAction(() => {
          this.member = data;
        });
        store(PersistKey.MEMBER, JSON.stringify(data));
      }
      return data;
    },
  );

  public updateMember = this.privileged('write')(
    async (memberUpdate: MemberUpdate): Promise<Member | undefined> => {
      const { data } = await client
        .members({ accessToken: this.token })
        .updateMember('me', memberUpdate);
      if (data) {
        runInAction(() => {
          this.member = data;
        });
        store(PersistKey.MEMBER, JSON.stringify(data));
      }
      return data;
    },
  );

  public getMemberBalance = this.privileged()(
    async (): Promise<MemberBalance | undefined> => {
      const { data } = await client
        .balance({ accessToken: this.token })
        .getMemberBalance('me');

      if (data) {
        this.memberBalance = data;
        store(PersistKey.MEMBER_BALANCE, JSON.stringify(data));
      }

      return data;
    },
  );

  public getDocuments = this.privileged()(
    async (): Promise<Array<DocumentSummary> | undefined> => {
      const { data } = await client
        .documents({ accessToken: this.token })
        .listMemberDocuments('me');

      if (data) {
        runInAction(() => {
          this.documentSummaries = data;
          store(PersistKey.DOCUMENT_SUMMARIES, JSON.stringify(data));
        });
      }

      return data;
    },
  );

  public getDocumentDetails = this.privileged()(
    async (documentId: string): Promise<DocumentDetails | undefined> => {
      const { data } = await client
        .documents({ accessToken: this.token })
        .getMemberDocument('me', documentId);
      return data;
    },
  );

  public getBeneficiaries = this.privileged()(
    async (): Promise<Array<BeneficiaryBase> | undefined> => {
      try {
        const { data } = await client
          .beneficiaries({ accessToken: this.token })
          .listMemberBeneficiaries('me');

        if (data) {
          runInAction(() => {
            this.beneficiaries = data;
            store(PersistKey.BENEFICIARIES, JSON.stringify(data));
          });
        }
      } catch (err) {
        if (err.status === 404) {
          runInAction(() => {
            this.beneficiaries = [];
            store(PersistKey.BENEFICIARIES, JSON.stringify([]));
          });
        } else {
          throw err;
        }
      }
      return this.beneficiaries;
    },
  );

  public createBeneficiaries = this.privileged('write')(
    async (
      beneficiaries: BeneficiaryBase[],
    ): Promise<BeneficiaryIntent | undefined> => {
      const { data } = await client
        .beneficiaries({ accessToken: this.token })
        .createMemberBeneficiaryIntent('me', {
          newBeneficiaryIntent: { beneficiaries },
        });
      return data;
    },
  );

  public getTransactions = this.privileged()(
    async (): Promise<Array<Transaction> | undefined> => {
      const pageSize = 100;
      let transactionData: Array<Transaction> = [];
      let pageOffset = 0;
      let hasMore = true;
      while (hasMore) {
        try {
          const { data, meta } = await client
            .transactions({ accessToken: this.token })
            .listTransactions({
              pageSize,
              pageOffset,
            });
          if (data) {
            transactionData = transactionData.concat(data);
          }
          pageOffset += pageSize;
          hasMore = !!meta?.total && pageOffset < meta.total;
        } catch {
          hasMore = false;
        }
      }
      if (transactionData) {
        runInAction(() => {
          this.transactions = transactionData;
        });
        store(PersistKey.TRANSACTIONS, JSON.stringify(transactionData));
      }
      return transactionData;
    },
  );

  public listFutureCashAllocations = this.privileged()(
    async (): Promise<Array<InvestmentAllocation> | undefined> => {
      const { data } = await client
        .investments({ accessToken: this.token })
        .listMembersFutureCashAllocations('me');

      if (data) {
        this.futureCashAllocations = data;
        store(PersistKey.FUTURE_CASH_ALLOCATIONS, JSON.stringify(data));
      }

      return data;
    },
  );

  public updateFutureCashAllocations = this.privileged('write')(
    async (
      allocation: InvestmentAllocation,
    ): Promise<Array<InvestmentAllocation> | undefined> => {
      const { data } = await client
        .investments({ accessToken: this.token })
        .updateFutureCashAllocations('me', [allocation]);
      await this.listFutureCashAllocations();
      return data;
    },
  );

  public searchMemberFunds = this.privileged('write')(
    async (): Promise<number> => {
      runInAction(() => {
        this.fundSearchResults = undefined;
      });

      try {
        const results = await this.getLatestFundSearchResult();

        if (
          results?.status === FundSearchOperation.StatusEnum.Success &&
          results?.created &&
          moment(results.created)
            .add(1, 'days')
            .isSameOrAfter(moment())
        ) {
          runInAction(() => {
            this.fundSearchResults = results;
          });
        }
      } catch (error) {
        // do nothing, we'll do a fresh search
      }

      if (this.fundSearchResults === undefined) {
        // Initiate fund search, get id to poll for results
        const searchResponse = await this.performFundSearch();
        const fundSearchId = searchResponse && searchResponse.id;

        if (!fundSearchId) {
          throw new Error('Error while performing search');
        }
        // Poll search results until we get a response
        const maxRetries = 40;
        let searching = true;
        let tries = 0;

        while (searching) {
          tries++;

          const results = await this.listFundSearchResult(fundSearchId);
          if (
            results &&
            results.status === FundSearchOperation.StatusEnum.Success
          ) {
            runInAction(() => {
              this.fundSearchResults = results;
            });
            searching = false;
            break;
          }
          if (tries >= maxRetries) {
            throw new Error('Fund search timed out. Please try again.');
          }
          await sleep(500);
        }
      }

      try {
        const rollovers = await this.listRolloverRequests();

        runInAction(() => {
          this.rolloverRequests = rollovers;
        });
      } catch (error) {
        runInAction(() => {
          this.rolloverRequests = undefined;
        });
      }

      const { funds, unclaimedMoneys } = this.fundSearchResults || {};
      const fundsCount = (funds && funds.length) || 0;
      const unclaimedMoneyCount =
        (unclaimedMoneys && unclaimedMoneys.length) || 0;

      return fundsCount + unclaimedMoneyCount;
    },
  );

  public getLatestFundSearchResult = this.privileged('write')(
    async (): Promise<FundSearchResult | undefined> => {
      const results = await client
        .fundSearchRollover({ accessToken: this.token })
        .getLatestFundSearchResult('me');

      if (results && results.data) {
        return results.data;
      }
      return undefined;
    },
  );

  public performFundSearch = this.privileged('write')(
    async (): Promise<FundSearchOperation | undefined> => {
      const { data } = await client
        .fundSearchRollover({ accessToken: this.token })
        .performFundSearch('me');
      return data;
    },
  );

  public listFundSearchResult = this.privileged()(
    async (fundSearchId: string): Promise<FundSearchResult | undefined> => {
      const results = await client
        .fundSearchRollover({ accessToken: this.token })
        .listFundSearchResult('me', fundSearchId);
      if (results && results.data) {
        return results.data;
      }
      return undefined;
    },
  );

  public listRolloverRequests = this.privileged()(
    async (): Promise<InlineResponse20023Data | undefined> => {
      const { data } = await client
        .fundSearchRollover({ accessToken: this.token })
        .listRolloverRequests('me');
      return data;
    },
  );

  public createManualRolloverRequest = this.privileged('write')(
    async (
      rolloverRequest: RolloverRequest,
    ): Promise<InlineResponse20010Data | undefined> => {
      const { data } = await client
        .fundSearchRollover({ accessToken: this.token })
        .createManualRolloverRequest('me', rolloverRequest);
      return data;
    },
  );

  public listInvestmentOptions = this.privileged('write')(
    async (): Promise<Array<InvestmentOption> | undefined> => {
      const { data } = await client
        .investments({ accessToken: this.token })
        .listInvestments();

      if (data) {
        this.investmentOptions = data;
        store(PersistKey.INVESTMENT_OPTIONS, JSON.stringify(data));
      }

      return data;
    },
  );

  public listInvestmentsBalance = this.privileged()(
    async (): Promise<Array<InvestmentBalance> | undefined> => {
      const { data } = await client
        .investments({ accessToken: this.token })
        .listInvestmentsBalance('me');
      return data;
    },
  );

  public requestInvestmentRebalanceTransfer = this.privileged()(
    async (
      allocation: InvestmentBalanceAllocation,
    ): Promise<RequestAccepted | undefined> => {
      const { data } = await client
        .investments({ accessToken: this.token })
        .requestInvestmentRebalanceTransfer('me', [allocation]);
      return data;
    },
  );

  public getInvestmentPerformance = async (
    investmentId: string,
  ): Promise<Array<UnitPrice> | undefined> => {
    const { data } = await client
      .investments({ accessToken: this.token })
      .getInvestmentPerformance(investmentId, { sort: 'desc' });

    runInAction(() => {
      this.latestUnitPrice = data?.find(unitPrice => unitPrice.weekly);
    });

    return data;
  };
}
