import { Link, navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { Input } from 'components/input';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import { useResetPasswordEmailContext } from 'helpers/contexts/password-reset';
import { Source } from 'helpers/source';
import { observer } from 'mobx-react';
import React, { FC, Fragment, useState } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import { BODY, BODY_SMALL, HEADER_HEIGHT, INPUT_MARGIN, Spacing } from 'styles';

import { Routes } from './routes';

const validation = {
  email: {
    required: 'Email is required',
  },
};

export const ResetPassword: FC<RouteComponentProps> = observer(
  (): JSX.Element => {
    const notifications = useNotifications();
    const [, setEmail] = useResetPasswordEmailContext();
    const [loading, setLoading] = useState(false);

    const {
      sargonStore: { forgotPassword },
    } = useStores();

    const params = new URL(window.location.href).searchParams;
    const isRequired = params.get('required') === '1';
    const email = params.get('email') || '';
    const source = params.get('source') || '';

    let title;
    let body;
    let showSubtext;

    switch (source) {
      case Source.EMPLOYMENT_HERO:
        title = 'Set your password';
        body =
          'Welcome to Spaceship! To set up access to your member dashboard, click continue below and we’ll send you a password code.';
        showSubtext = false;
        break;
      default:
        title = 'Reset your password';
        body = isRequired
          ? 'Due to a system upgrade, we need you to reset your password in order to access your Spaceship Super account.'
          : 'Forgot your password? Let’s set a new one.';
        showSubtext = true;
        break;
    }

    const { register, errors, handleSubmit } = useForm<{
      email: string;
    }>({
      submitFocusError: true,
      defaultValues: { email },
    });

    return (
      <ColumnLayout>
        <Column verticalCenter={true}>
          <div className={css(container)}>
            <h1 className={css(center)}>{title}</h1>
            <p className={css(info)}>{body}</p>

            <form
              className={css(loginForm)}
              onSubmit={handleSubmit(async ({ email }) => {
                setLoading(true);
                setEmail(email);
                try {
                  await forgotPassword(email);
                  await navigate(Routes.RESET_PASSWORD_UPDATE, {
                    state: { source },
                  });
                } catch (error) {
                  notifications.popToast({
                    level: 'error',
                    message:
                      'Something went wrong, please check your email and try again.',
                  });
                }
                setLoading(false);
              })}
            >
              <Input
                type="email"
                name="email"
                placeholder="Email address"
                ref={register(validation.email)}
                margin={INPUT_MARGIN}
                errorMessage={errors.email && errors.email.message}
              />
              <div className={css(buttonContainer)}>
                <Button
                  type="submit"
                  loading={loading}
                  trackingProperties={{
                    name: 'reset_password_submit',
                  }}
                >
                  Continue
                </Button>
              </div>
            </form>
            {showSubtext && (
              <Fragment>
                {!isRequired && (
                  <div className={css(subtext)}>
                    Just remembered your password?{' '}
                    <Link to={Routes.LOGIN}>Log in</Link>
                  </div>
                )}
                <div className={css(subtext)}>
                  Don&apos;t have an account?{' '}
                  <Link to={Routes.SIGNUP}>Sign up</Link>
                </div>
              </Fragment>
            )}
          </div>
        </Column>
        <Column verticalCenter={true} display="desktop-only">
          <Illustration type="form" />
        </Column>
      </ColumnLayout>
    );
  },
);

const container: Interpolation = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  justifyContent: 'center',
  width: '100%',
  maxWidth: 450,
};

const loginForm: Interpolation = {
  width: '100%',
};

const buttonContainer: Interpolation = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: Spacing.MEDIUM,
};

const subtext: Interpolation = {
  ...BODY_SMALL,
  marginBottom: Spacing.XX_SMALL,
  textAlign: 'center',
};

const info: Interpolation = {
  ...BODY,
  marginBottom: Spacing.MEDIUM,
  textAlign: 'center',
};

const center: Interpolation = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};
