import { navigate, RouteComponentProps } from '@reach/router';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { Column, ColumnLayout } from 'components/column-layout';
import { Illustration } from 'components/illustration';
import { css, Interpolation } from 'emotion';
import { useNotifications } from 'helpers/contexts/notifications';
import React, { FC, useState } from 'react';
import useForm from 'react-hook-form';
import { useStores } from 'stores';
import {
  CONSENT_BOX,
  HEADING_2,
  MEDIA_QUERY_MOBILE_MAX,
  Spacing,
} from 'styles';

import { Routes } from './routes';

const validation = {
  consent: {
    required: 'Consent is required',
  },
};

export const TransferSearch: FC<RouteComponentProps> = (): JSX.Element => {
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);

  const {
    sargonStore: { searchMemberFunds },
  } = useStores();

  const { register, errors, handleSubmit } = useForm<{
    consent: boolean;
  }>({
    submitFocusError: true,
  });

  return (
    <ColumnLayout>
      <Column display="desktop-only" verticalCenter={true}>
        <Illustration type="vault" />
      </Column>

      <Column verticalCenter={true}>
        <div className={css(container)}>
          <h1 className={css(header)}>Find your superannuation monies</h1>
          <form
            onSubmit={handleSubmit(async () => {
              setLoading(true);
              try {
                const fundsFound = await searchMemberFunds();

                if (fundsFound > 0) {
                  await navigate(Routes.TRANSFER_FUNDS);
                } else {
                  await navigate(Routes.TRANSFER_NO_FUNDS);
                }
              } catch (error) {
                notifications.popBanner({
                  message:
                    error.message ||
                    'Something went wrong while searching for your superannuation monies.',
                });
              }
              setLoading(false);
            })}
          >
            <div className={css(CONSENT_BOX)}>
              <p>
                Spaceship can use your TFN to help you find other super accounts
                you might have, including any ATO-held super. Spaceship does
                this by running a SuperMatch search.
              </p>
              <p>
                You can then review your SuperMatch search results and decide
                whether you want to consolidate your other super accounts into
                your Spaceship Super account.
              </p>
              <p>
                By asking us to find your other super monies, you are consenting
                to us using your TFN to search the ATO&apos;s SuperMatch service
                to search and claim any other super monies you may have that are
                held by the ATO or by other superannuation providers and
                consolidating it into your Spaceship account.
              </p>
            </div>

            <Checkbox
              name="consent"
              ref={register(validation.consent)}
              errorMessage={errors.consent && errors.consent.message}
            >
              I consent to Spaceship using my TFN to run a search on the
              ATO&apos;s SuperMatch service to find all of my other super.
            </Checkbox>

            <div className={css(row)}>
              <Button
                margin={{ marginBottom: Spacing.SMALL }}
                type="submit"
                loading={loading}
                trackingProperties={{
                  name: 'transfer_search_submit',
                }}
              >
                Find my super
              </Button>
              <Button
                variant="secondary"
                onClick={(): void => {
                  navigate(Routes.DASHBOARD);
                }}
                trackingProperties={{
                  name: 'transfer_search_skip',
                }}
              >
                Skip
              </Button>
            </div>
          </form>
        </div>
      </Column>
    </ColumnLayout>
  );
};

const container: Interpolation = {
  maxWidth: 550,
};

const row: Interpolation = {
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  marginTop: Spacing.MEDIUM,
};

const header: Interpolation = {
  [MEDIA_QUERY_MOBILE_MAX]: {
    ...HEADING_2,
    paddingRight: Spacing.XX_LARGE,
  },
};
